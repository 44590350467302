import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CryptoService } from 'src/app/services/crypto/crypto.service';
import { PATH_NAMES } from 'src/app/shared/constants';
import { SeoService } from 'src/app/services/seo/seo.service';
import { toUp } from '../../shared/utils/shared-utils';

@Component({
	selector: 'app-politicas',
	templateUrl: './politicas.component.html',
	styleUrls: ['./politicas.component.scss']
})
export class PoliticasComponent implements OnInit {
	isBrowser: boolean = false;
	isMobile: boolean = false;

	constructor(
		@Inject(PLATFORM_ID) private platformId: Object,
		private _cryptoService: CryptoService,
		private _seoService: SeoService,
		private _deviceDetector: DeviceDetectorService
	) {
		if (isPlatformBrowser(this.platformId)) this.isBrowser = true;
		this.isMobile = this._deviceDetector.isMobile();
	}

	ngOnInit(): void {
		const path = PATH_NAMES.POLICIES;
		this._seoService.setSEOByPath(path);

		if (this.isBrowser) {
			let userID: string = '';
			let user_existingCustomer: boolean = false;
			const credentials = localStorage.getItem('usuario');
			const bookings = localStorage.getItem('bookings');

			if (credentials) {
				const credentialsJson = JSON.parse(credentials);
				userID = this._cryptoService.encrypt(credentialsJson.email);

				if (bookings) user_existingCustomer = JSON.parse(bookings).length > 0;
			}

			(window as any).dataLayer = (window as any).dataLayer || [];
			(window as any).dataLayer.push({
				event: 'user_info',
				userID: userID,
				user_existingCustomer: user_existingCustomer
			});

			(window as any).dataLayer.push({
				event: 'virtualPageView',
				virtualPagePath: '/politicas',
				virtualPageTitle: 'NMV: Politica de proteccion y tratamiento de datos personales'
			});

			toUp();
		}
	}
}
