import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CitaComponent } from './cita.component';
import { RouterModule, Routes} from '@angular/router';

const routes: Routes = [{ path: '', component: CitaComponent }];

@NgModule({
  declarations: [ CitaComponent ], 
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ], 
  exports: [CitaComponent],
})
export class CitaModule { }
