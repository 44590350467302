import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GlobalComponent } from 'src/app/shared/global';
import { dataInitBooking } from 'src/app/shared/constant-init';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { REmail } from '../models/rq-checkout-email';
import { RPurchase } from '../models/rq-checkout-save-booking';
import { RDiscount, RDiscountCupon, Result } from '../models/rq-checkout-discount';
import { RContact } from '../models/rq-checkout-contact';
import { IValidateBooking, RValidateBooking } from '../models/rq-checkout-validate-booking';
import {
  Billing,
  Contact,
  Passenger,
  passengerInfoInit,
  PassengersInfo,
} from '../models/rq-checkout-passengers';
import { Payment, paymentInit } from '../models/rq-checkout-payment';
import { BehaviorSubject } from 'rxjs';
import { IHotSale } from '../../../public/pages/home/models';
import { DateUtils } from '../../../shared/utils/date-utils';
import { CheckoutOrigin, listTypeDocument } from '../../../public/pages/checkout-page/pay/utils';
import { UpsellService } from '../../../shared/services/upsell/upsell.service';
import { WebLink } from '../../api-nmviajes/models/rq-token-ce-request';
import { PlatformService } from '../../../shared/services';
import { DEVICES, INSURANCE_PRICE } from '../../../shared/constants';
import { newGuid } from '../../../shared/utils/guid.utils';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class CheckoutService {
  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private _httpClient: HttpClient,
    private _upsellService: UpsellService,
    private _platformService: PlatformService
  ) {}

  insuranceEventEmitter = new EventEmitter();
  changeStep = new EventEmitter();
  isFinishedPay = new EventEmitter();
  applyCupon = new EventEmitter();
  applyBinDiscount = new EventEmitter<Result | null>();
  nextPassengerMobile = new EventEmitter();
  nextPaymentMobile = new EventEmitter();
  updateDataKayak = new EventEmitter();

  dataInfoPassengers: PassengersInfo = { ...passengerInfoInit };
  dataInfoPayment: Payment = { ...paymentInit };

  isSaveDataPassenger = false;
  isSaveDataPayment = true;
  isChangesPayment = false;
  isFinishPayment = false;

  itsIncludeInsurance = false;
  currentIndexStep = 0;
  API_KAYAK = 'https://motorvuelos.expertiatravel.com';
  routeHistory: string[] = [];

  private _isFromOffers = new BehaviorSubject(false);
  isFromOffers$ = this._isFromOffers.asObservable();

  offerDetail?: IHotSale;
  offerDiscount?: Result;
  offerRoomType?: number;

  checkoutOrigin: CheckoutOrigin;

  setIsFromOffers(value: boolean) {
    this._isFromOffers.next(value);
  }

  getIsFromOffers() {
    return this._isFromOffers.value;
  }

  resetValuesForms() {
    this.isSaveDataPassenger = false;
    this.dataInfoPassengers = { ...passengerInfoInit };
    this.dataInfoPassengers.passengers = [];
    this.dataInfoPayment = { ...paymentInit };
    this.isSaveDataPayment = true;
    this.isChangesPayment = false;
    GlobalComponent.paramsSearch = {};
    GlobalComponent.tokenMotorVuelo = '';
    delete dataInitBooking.secure;
    GlobalComponent.appBooking = { ...dataInitBooking };
    this.currentIndexStep = 0;
    this.isFinishPayment = false;
    GlobalComponent.dataSteps = [];
    this.offerDetail = undefined;
    this.offerDiscount = undefined;
    this.offerRoomType = undefined;
  }

  updateDataPassenger(passenger: Passenger) {
    const indexPassenger = this.dataInfoPassengers.passengers.findIndex(
      (object) => object.index === passenger.index
    );
    if (indexPassenger == -1) {
      this.dataInfoPassengers.passengers.push({ ...passenger });
      this.dataInfoPassengers.passengers.sort((a, b) => a.index - b.index);
    } else {
      this.dataInfoPassengers.passengers[indexPassenger] = { ...passenger };
    }
  }

  updateDataContact(
    contact: Contact,
    billing: Billing,
    acceptPolitics: boolean,
    acceptBilling = true
  ) {
    if (acceptBilling)
      this.dataInfoPassengers = { ...this.dataInfoPassengers, contact, billing, acceptPolitics };
    else this.dataInfoPassengers = { ...this.dataInfoPassengers, contact, acceptPolitics };
  }

  totalDaysTravel() {
    const departure = GlobalComponent.appGroupSeleted.departure;
    const returnFlight = GlobalComponent.appGroupSeleted.returns || null;
    let totalDaysTravel: number;
    let dateDeparture: Date;
    let dateArrival: Date;

    if (departure.length == 1 && returnFlight == null) {
      dateArrival = new Date(
        departure[0].segments[0].flightSegments[
          departure[0].segments[0].flightSegments.length - 1
        ].arrivalDateTime
      );
    } else if (returnFlight !== null) {
      dateArrival = new Date(
        returnFlight.segments[0].flightSegments[
          returnFlight.segments[0].flightSegments.length - 1
        ].arrivalDateTime
      );
    } else {
      dateArrival = new Date(
        departure[departure.length - 1].segments[0].flightSegments[
          departure[departure.length - 1].segments[0].flightSegments.length - 1
        ].arrivalDateTime
      );
    }

    dateDeparture = new Date(departure[0].segments[0].flightSegments[0].departureDateTime);
    totalDaysTravel = DateUtils.getDatesDiff(dateDeparture, dateArrival) + 1;
    GlobalComponent.totalDaysTravel = totalDaysTravel;
  }

  updateTotalInsurance(status: boolean) {
    this.itsIncludeInsurance = status;
    if (status) this.setSecurePrice();
    if (!status) delete GlobalComponent.appBooking.secure;
    this.insuranceEventEmitter.emit();
  }

  setIsDomestic(): boolean {
    const departureFlight = GlobalComponent.appGroupSeleted.departure[0];
    const returnFlight = GlobalComponent.appGroupSeleted.returns;
    let country: string[] = [];
    let isDomestic: boolean;
    country.push(departureFlight.originCity.country);
    country.push(departureFlight.destinationCity.country);

    if (returnFlight != null) {
      country.push(returnFlight.originCity.country);
      country.push(returnFlight.destinationCity.country);
    }

    isDomestic = country.filter((x) => x != 'PE').length <= 0;
    GlobalComponent.isDomestic = isDomestic;
    return isDomestic;
  }

  setSecurePrice() {
    const unitPrice = INSURANCE_PRICE;
    const totalPrice =
      GlobalComponent.detailPricing.passengersCount * GlobalComponent.totalDaysTravel * unitPrice;
    const moneyExchange = GlobalComponent.appExchangeRate.amount;
    GlobalComponent.appBooking.secure = {
      unitPrice,
      totalPrice,
      moneyExchange,
      isDomestic: GlobalComponent.isDomestic,
    };
  }

  sendAndSavePay() {
    const url = `${environment.urlApiMotorVuelos}/mv/save-booking`;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${GlobalComponent.tokenMotorVuelo}`);
    return this._httpClient.post<RPurchase>(url, GlobalComponent.appBooking, { headers });
  }

  sendPaymentTransaction(deviceSessionId: string) {
    const url = `${environment.nmConfigurations}/v1/api/Booking/TransactionAndPayment`;

    const authLinks = this.getWebLinks();
    const bookingConditions = authLinks.find((link) => link.tipoEnlace === 'CR')?.link ?? '';
    const termsConditions = authLinks.find((link) => link.tipoEnlace === 'TC')?.link ?? '';
    const travelDocuments = authLinks.find((link) => link.tipoEnlace === 'DV')?.link ?? '';

    const body = {
      trackingCode: newGuid(),
      muteExceptions: environment.muteExceptions,
      caller: {
        company: GlobalComponent.appCompany,
        application: GlobalComponent.appApplication,
        deviceId: `${this._platformService.getDevice() ?? DEVICES.WEB}`,
        fromIP: '',
        fromBrowser: '',
      },
      parameter: {
        identifier: 'Pagos',
        typeOfOperation: 'Vuelos',
        typeOfSale: GlobalComponent.isDomestic ? 'Nacional' : 'Internacional',
        signIn: { username: GlobalComponent.appBooking.contact.email },
        customer: {
          city: GlobalComponent.appBooking.card.city ?? '',
          documentType: this.getDocTypeById(GlobalComponent.appBooking.card.documentType ?? 0),
          documentNumber: GlobalComponent.appBooking.card.documentNumber ?? '',
          phone: GlobalComponent.appBooking.contact.phones[0].phoneNumber,
          ...GlobalComponent.appBooking.customer,
        },
        holder: {
          firstname: GlobalComponent.appBooking.card.cardOwner,
          lastname: '',
          documentType: `${GlobalComponent.appBooking.card.documentType ?? 0}`,
          documentNumber: GlobalComponent.appBooking.card.documentNumber ?? '',
          address: GlobalComponent.appBooking.card.address ?? '',
          city: GlobalComponent.appBooking.card.city ?? '',
          email:
            GlobalComponent.appBooking.card.email ?? GlobalComponent.appBooking.passengers[0].email,
        },
        flight: {
          sourceId: GlobalComponent.appGroupSeleted.departure[0].originCity.code,
          destinationId: GlobalComponent.appGroupSeleted.departure[0].destinationCity.code,
          airlineId: GlobalComponent.appGroupSeleted.airline.code,
        },
        card: {
          number: GlobalComponent.appBooking.card.cardNumber,
          securityCode: GlobalComponent.appBooking.card.cvv,
          expirationDate: GlobalComponent.appBooking.card.expiration,
        },
        paymentPlan: GlobalComponent.appBooking.card.numberQuotes || {
          payments: 0,
          payments_type: 'WITHOUT_INTEREST',
        },
        promotionalCode: GlobalComponent.appBooking.CuponPromoWeb ?? '',
        application: GlobalComponent.appApplication,
        deviceSessionId,
        groupId: GlobalComponent.appGroupSeleted.id,
        mToken: GlobalComponent.tokenMotorVuelo,
        transactionId: GlobalComponent.transactionId,
        upSell:
          GlobalComponent.upSellGroup && GlobalComponent.upSellGroup.length
            ? GlobalComponent.appBooking.brandedFareName
            : undefined,
        hasInsurance: !!GlobalComponent.appBooking.secure?.totalPrice,
        segmentSelected: GlobalComponent.segmentSelected,
        passengers: GlobalComponent.appBooking.passengers.map((item) => {
          return { ...item, email: item.email ?? '' };
        }),
        phones: GlobalComponent.appBooking.contact.phones,
        exchangeRate: { date: undefined, ...GlobalComponent.appExchangeRate },
        urlRedirect: environment.productionEnv
          ? 'https://www.nmviajes.com/booking/pago/procesando'
          : 'https://qa-nm.nmviajes-it.com/booking/pago/procesando',
        webLink: { termsConditions, bookingConditions, travelDocuments },
      },
    };
    return this._httpClient.post<any>(url, body);
  }

  sendFinalizeTransaction(id: number, redirectId: string) {
    const url = `${environment.nmConfigurations}/v1/api/Booking/FinalizeTransaction`;
    const body = {
      trackingCode: newGuid(),
      muteExceptions: environment.muteExceptions,
      caller: {
        company: GlobalComponent.appCompany,
        application: GlobalComponent.appApplication,
        deviceId: `${this._platformService.getDevice() ?? DEVICES.WEB}`,
        fromIP: '',
        fromBrowser: '',
      },
      parameter: { id, redirectId },
    };
    return this._httpClient.post<any>(url, body, {
      headers: { 'not-loading': 'true' },
    });
  }

  private getDocTypeById(id: number): string {
    switch (id) {
      case 0:
        return 'DNI';
      case 1:
        return 'CE';
      default:
        return 'PAS';
    }
  }

  sendOfferTransaction(
    offerDetail: IHotSale,
    roomTypeId: number,
    cardData: any,
    deviceSessionId: string
  ) {
    const contact = GlobalComponent.appBooking.contact;
    const roomType = offerDetail.roomType.find((item) => item.value === roomTypeId)!;
    const data = {
      trackingCode: GlobalComponent.transactionId || newGuid(),
      muteExceptions: environment.muteExceptions,
      caller: {
        company: 'Expertia',
        application: 'NMViajes',
        deviceId: `${this._platformService.getDevice() ?? DEVICES.WEB}`,
        fromIP: '',
        fromBrowser: '',
      },
      parameter: {
        identifier: 'Pagos',
        typeOfOperation: 'Paquete',
        typeOfSale: 'Nacional',
        signIn: {
          username: contact.email,
        },
        customer: {
          firstname: contact.name,
          lastname: contact.lastName,
          address: contact.address,
          documentType:
            listTypeDocument.find((item) => item.value === cardData.documentType)?.name ?? 'DNI',
          documentNumber: cardData.documentNumber,
          city: '',
          email: contact.email,
          phone: `+${contact.phones[0].countryCode}${contact.phones[0].phoneNumber}`,
        },
        amount: {
          currency: 'USD',
          value: offerDetail.discount ? roomType.priceWithDiscount : roomType.price,
          ofFees: 0,
        },
        external: {
          redirectSuccess: '',
          redirectError: '',
          notificationUrl: '',
        },
        holder: {
          firstname: cardData.cardOwner,
          lastname: '',
          documentType: `${cardData.documentType}`,
          documentNumber: cardData.documentNumber,
          address: cardData.address,
          city: cardData.city,
          email: contact.email,
        },
        booking: {
          codeSrv: 0,
          codeInsurance: 0,
          bookingCode: 0,
          departureDate: DateUtils.fromDateStringToDateTimeFormat(offerDetail.departureDate) ?? '',
          arrivalDate: DateUtils.fromDateStringToDateTimeFormat(offerDetail.returnDate) ?? '',
          numberOfAdult: offerDetail.maxPassengers,
          numberOfChildren: 0,
          origin: offerDetail.origin,
          destination: offerDetail.destination,
          hasCancellationFee: true,
          idGDS: 0,
        },
        documents: '',
        setting: {
          hasAutomaticPayment: false,
          hasAQuoteCode: false,
          expirationTimeMinutes: 0,
        },
        discount: offerDetail.discount?.percent ?? '',
        fee: '',
        flight: {
          destinationId: '',
          sourceId: '',
          airlineId: offerDetail.airline,
          flightClass: '',
          fareBasis: '',
        },
        card: {
          number: cardData.cardNumber,
          securityCode: cardData.cvv,
          expirationDate: cardData.expiration,
        },
        promotionalCode: '',
        application: 'NMBloqueos',
        deviceSessionId: deviceSessionId,
        title: offerDetail.title,
        roomType: roomType.description,
      },
    };
    const url = `${environment.nmConfigurations}/v1/api/Transaction/Transaction`;
    return this._httpClient.post<any>(url, data);
  }

  getPromocionalCode(code: string) {
    const credentials = localStorage.getItem('usuario');
    const url = `${
      GlobalComponent.isKayak ? this.API_KAYAK : environment.urlApiMotorVuelos
    }/mv/payment/get-promotional-code-by-code?Code=${code}&GroupId=${
      GlobalComponent.appGroupSeleted.id
    }&Email=${credentials ? JSON.parse(credentials).email : ''}&BrandedFareName=${
      this._upsellService.getSelectedUpsell()?.name ?? ''
    }`;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('not-loading', 'true')
      .set('Authorization', `Bearer ${GlobalComponent.tokenMotorVuelo}`);
    return this._httpClient.get<RDiscountCupon>(url, { headers });
  }

  getDiscountByCampaign(bin = '') {
    const endpoint = `${GlobalComponent.isKayak ? this.API_KAYAK : environment.urlApiMotorVuelos}`;
    const groupId = GlobalComponent.appGroupSeleted.id;
    const includesInsurance = !!GlobalComponent.appBooking.secure;
    const upSellName = this._upsellService.getSelectedUpsell()?.name ?? '';
    const url = `${endpoint}/mv/payment/get-discounts?Bin=${bin}&GroupId=${groupId}&IncludeSecure=${includesInsurance}&PromotionalCode=&BrandedFareName=${upSellName}`;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${GlobalComponent.tokenMotorVuelo}`);
    return this._httpClient.get<RDiscount>(url, { headers });
  }

  sendEmailBooking(data: REmail) {
    const url = `${GlobalComponent.isKayak ? this.API_KAYAK : environment.urlApiMotorVuelos}/mv/send-booking`;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('not-loading', 'true')
      .set('Authorization', `Bearer ${GlobalComponent.tokenMotorVuelo}`);

    const authLinks = this.getWebLinks();
    if (authLinks && authLinks.length) {
      const bookingConditions = authLinks.find((link) => link.tipoEnlace === 'CR')?.link ?? '';
      const privacyPolicies = authLinks.find((link) => link.tipoEnlace === 'PP')?.link ?? '';
      const termsConditions = authLinks.find((link) => link.tipoEnlace === 'TC')?.link ?? '';
      const travelDocuments = authLinks.find((link) => link.tipoEnlace === 'DV')?.link ?? '';

      data.parameter = {
        ...data.parameter,
        webLink: { bookingConditions, privacyPolicies, termsConditions, travelDocuments },
      };
    }

    return this._httpClient.post<any>(url, data, { headers });
  }

  private getWebLinks(): WebLink[] {
    if (isPlatformBrowser(this.platformId)) {
      const storedAuthLinks = sessionStorage.getItem('AUTH_LINKS');
      if (storedAuthLinks) return JSON.parse(storedAuthLinks) as WebLink[];
    }
    return [];
  }

  getDataContactByLogin(email: string) {
    const url = `${
      GlobalComponent.isKayak ? this.API_KAYAK : environment.urlApiMotorVuelos
    }/mv/account/get-by-email?email=${email}`;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${GlobalComponent.tokenMotorVuelo}`);
    return this._httpClient.get<RContact>(url, { headers });
  }

  validateBooking(data: IValidateBooking) {
    const url = `${GlobalComponent.isKayak ? this.API_KAYAK : environment.urlApiMotorVuelos}/mv/validate-booking`;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${GlobalComponent.tokenMotorVuelo}`);
    return this._httpClient.post<RValidateBooking>(url, data, { headers });
  }

  cancelBooking(cancelBookingRQ: any) {
    let url = `${GlobalComponent.isKayak ? this.API_KAYAK : environment.urlApiMotorVuelos}/cancel-booking`;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${GlobalComponent.tokenMotorVuelo}`);
    return this._httpClient.post<any>(url, cancelBookingRQ, { headers });
  }

  isFromMetaSearchEngine(source: string): boolean {
    const engines = environment.metaSearchEngines
      .filter((str) => str.toLowerCase() !== 'wsspia')
      .map((str) => str.toLowerCase());
    const isFromMeta = engines.includes(source.toLowerCase());
    this.checkoutOrigin = isFromMeta ? CheckoutOrigin.META : CheckoutOrigin.WHATSAPP_IA;
    return isFromMeta;
  }
}
