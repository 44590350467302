import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterLink, RouterLinkWithHref } from '@angular/router';
import { ButtonModule } from '../../moleculas/button/button.module';
import {
  ChangePasswordComponent,
  CheckEmailComponent,
  ForgotPasswordComponent,
  LoginComponent,
  NewAccountComponent,
  MobileComponent,
  NotificationsComponent,
  TopBannerComponent,
} from './components';
import { ToolbarComponent } from './toolbar.component';
import { CustomButtonsModule } from '../../moleculas/custom-buttons/custom-buttons.module';

@NgModule({
  declarations: [
    ToolbarComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    NotificationsComponent,
    MobileComponent,
    LoginComponent,
    CheckEmailComponent,
    NewAccountComponent,
    TopBannerComponent,
  ],
  exports: [ToolbarComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterLink,
    ButtonModule,
    MatBottomSheetModule,
    MatCheckboxModule,
    MatTabsModule,
    CustomButtonsModule,
    RouterLinkWithHref,
  ],
})
export class ToolbarModule {}
