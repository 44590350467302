import { Component, Input } from '@angular/core';
import { Observable, of, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { CustomTypeaheadItem } from '../../../../models/custom-typeahead-item.model';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-autocomplete-field',
  templateUrl: './autocomplete-field.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class AutocompleteFieldComponent {
  @Input() inputId!: string;
  @Input() controlName!: string;
  @Input() autocompleteOptions: CustomTypeaheadItem[] = [];
  @Input() editable = false;
  @Input() labelText = '';
  @Input() inputPlaceholder = '';
  @Input() required = false;
  @Input() showValidState = false;
  @Input() showInvalidState = false;
  @Input() invalidMessage: string;
  @Input() htmlAutocomplete: string;

  search: OperatorFunction<string, readonly CustomTypeaheadItem[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      filter((term) => term.length > 1),
      distinctUntilChanged(),
      switchMap((term) => this.filterAndSortOptions(term))
    );

  formatter = (item: CustomTypeaheadItem) => item.name;

  constructor() {}

  private filterAndSortOptions(term: string) {
    const loweredTerm = term.toLowerCase();

    return of(
      this.autocompleteOptions
        .filter((item) => this.searchFilterHandler(loweredTerm, item))
        .sort((a, b) => this.sortResults(loweredTerm, a, b))
        .slice(0, 10)
    );
  }

  private searchFilterHandler(term: string, option: CustomTypeaheadItem): boolean {
    return (
      option.name.toLowerCase().includes(term.toLowerCase()) ||
      option.aux?.toLowerCase().includes(term.toLowerCase()) ||
      false
    );
  }

  private sortResults(term: string, a: CustomTypeaheadItem, b: CustomTypeaheadItem): number {
    const aStartsWith = a.name.toLowerCase().startsWith(term) || a.aux?.startsWith(term);
    const bStartsWith = b.name.toLowerCase().startsWith(term) || b.aux?.startsWith(term);

    if (aStartsWith === bStartsWith)
      return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });

    return aStartsWith ? -1 : 1;
  }
}
