import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PoliticasComponent } from './politicas.component';


@NgModule({
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	declarations: [PoliticasComponent],
	exports: [PoliticasComponent],
	imports: [CommonModule, RouterModule]
})
export class PoliticasModule {}
