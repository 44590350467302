import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-change-pass-modal',
	templateUrl: './change-password.component.html',
	styleUrls: [ './change-password.component.scss' ]
})
export class ChangePasswordComponent implements OnInit {
	@Input() message: string;

	constructor(public activeModal: NgbActiveModal) {
	}

	ngOnInit(): void {
	}

}
