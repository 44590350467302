import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { CryptoService } from 'src/app/services/crypto/crypto.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

interface Agency {
  id: number;
  name: string;
  address: string;
  city: string;
  openingHours: string;
  openingHours2: string;
  email: string;
  phone: string;
  phone2: string;
  image: string;
}

@Component({
  selector: 'app-nuestras-agencias',
  templateUrl: './nuestras-agencias.component.html',
  styleUrls: ['./nuestras-agencias.component.scss'],
})
export class NuestrasAgenciasComponent implements OnInit {
  protected isBrowser: Boolean = false;

  readonly agencies: Agency[] = [
    {
      id: 1,
      name: 'NM 28 de julio',
      address: 'Av. 28 de Julio 1136 -',
      city: 'Miraflores',
      openingHours: 'Lun - Vie 10:00 am a 07:00 pm',
      openingHours2: '',
      phone: '',
      phone2: '',
      email: 'vacaciones@nmviajes.com',
      image: './assets/agencias/agencia_.png',
    },
    {
      id: 2,
      name: 'NM Pardo',
      address: 'Av. José Pardo 801 -',
      city: 'Miraflores',
      openingHours: 'Lun - Vie 10:00 am a 07:00 pm',
      openingHours2: 'Sab 10:00 am - 6:00 pm',
      phone: '',
      phone2: '',
      email: 'vacaciones@nmviajes.com',
      image: './assets/agencias/Pardo.png',
    },
    {
      id: 3,
      name: 'NM Primavera',
      address: 'Av. Primavera 1352 -',
      city: 'Monterrico - Surco',
      openingHours: 'Lun - Vie 09:00 am a 07:00 pm',
      openingHours2: 'Sab 10:00 am - 1:00 pm',
      email: 'primavera@nmviajes.com',
      phone: '745-7638',
      phone2: '745-7639',
      image: './assets/agencias/Primavera.png',
    },
    {
      id: 4,
      name: 'NM CC. Caminos del Inca',
      address: 'Av. Caminos del inca 257, tienda 209 -',
      city: 'Chacarilla, Surco',
      openingHours: 'Lun - Vie 10:00 am a 07:00 pm',
      openingHours2: 'Sab 10:00 am - 1:00 pm',
      email: 'caminosdelinca@nmviajes.com',
      phone: '01 405 0906',
      phone2: '',
      image: './assets/agencias/Caminos-del-inca.webp',
    },
    {
      id: 5,
      name: 'NM La Fontana',
      address: 'Av. Javier Prado Este 5268 tienda 213 –',
      city: 'La Molina',
      openingHours: 'Lun - Vie 10:00 am a 07:00 pm',
      openingHours2: 'Sab 10:00 am - 1:00 pm',
      email: 'fontana@nmviajes.com',
      phone: '782-4812',
      phone2: '',
      image: './assets/agencias/LaFontana.png',
    },
    {
      id: 6,
      name: 'NM Chorrillos',
      address: 'Av. Prolongación Paseo de la República 768 -',
      city: 'Chorrillos',
      openingHours: 'Lun - Dom de 10:00 a.m. a 09:00 p.m.',
      openingHours2: '',
      email: 'yhuacchillo@nmviajes.com',
      phone: '962 641 238',
      phone2: '',
      image: './assets/agencias/Chorrillos.webp',
    },
    {
      id: 7,
      name: 'NM CC. Mega Plaza',
      address: 'Av. Alfredo Mendiola 25017 –',
      city: 'Independencia',
      openingHours: 'Lun - Dom 10:00 am a 10:00 pm',
      openingHours2: '',
      email: 'vacaciones@nmviajes.com',
      phone: '',
      phone2: '',
      image: './assets/agencias/MegaPlaza.png',
    },
    {
      id: 8,
      name: 'NM San Isidro',
      address: 'Av. Rivera Navarrete 723 –',
      city: 'San Isidro',
      openingHours: 'Lun - Vie 10:00 am a 07:00 pm',
      openingHours2: '',
      email: 'vacaciones@nmviajes.com',
      phone: '',
      phone2: '',
      image: './assets/agencias/SanIsidro.png',
    },
    {
      id: 9,
      name: 'NM Plaza San Miguel',
      address: 'Av. La Marina 2000 tienda 236-A 2do piso –',
      city: 'San Miguel',
      openingHours: 'Lun - Dom 10:00 am a 10:00 pm',
      openingHours2: '',
      email: 'vacaciones@nmviajes.com',
      phone: '',
      phone2: '',
      image: './assets/agencias/SanMiguel.png',
    },
    {
      id: 10,
      name: 'NM Jesús María',
      address: 'Av. Faustino Sánchez Carrión 1029 (Ex Av. Pershing) –',
      city: 'Jesús María',
      openingHours: 'Lun - Vi de 10:00 a.m. - 7:00 p.m.',
      openingHours2: '',
      email: 'vacaciones@nmviajes.com',
      phone: '',
      phone2: '',
      image: './assets/agencias/JM.webp',
    },
    {
      id: 11,
      name: 'NM Arequipa',
      address: 'Portal San Agustin 111, Plaza de Armas –',
      city: 'Arequipa',
      openingHours: 'Lun - Sab 08:30 a.m. - 08:00 p.m. / Dom 09:00 a.m. - 01:00 p.m.',
      openingHours2: '',
      email: 'vacaciones@nmviajes.com',
      phone: '',
      phone2: '',
      image: './assets/agencias/AQP.webp',
    },
    {
      id: 12,
      name: 'NM Chiclayo',
      address: 'Mall Aventura Chiclayo –',
      city: 'Chiclayo',
      openingHours: 'Lun - Dom de 10:00 a.m. a 10:00 p.m.',
      openingHours2: '',
      email: 'vacaciones@nmviajes.com',
      phone: '',
      phone2: '',
      image: './assets/agencias/CIX.webp',
    },
    {
      id: 13,
      name: 'NM Parque La Molina',
      address: 'Av. La Universidad 2448 Piso -2 (sótano 2)',
      city: 'La Molina',
      openingHours: 'Lun - Dom de 10:00 a.m. a 10:00 p.m.',
      openingHours2: '',
      email: 'vacaciones@nmviajes.com',
      phone: '',
      phone2: '',
      image: './assets/agencias/ParqueLaMolina.webp',
    },
  ];

  currentAgency?: Agency;
  mapSrc: SafeResourceUrl;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private _cryptoService: CryptoService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {
    if (isPlatformBrowser(this.platformId)) this.isBrowser = true;
  }

  ngOnInit(): void {
    this.agenciaView(1);

    if (this.isBrowser) {
      let userID: string = '';
      let user_existingCustomer: boolean = false;
      const credentials = localStorage.getItem('usuario');
      const bookings = localStorage.getItem('bookings');

      if (credentials) {
        const credentialsJson = JSON.parse(credentials);
        userID = this._cryptoService.encrypt(credentialsJson.email);

        if (bookings) user_existingCustomer = JSON.parse(bookings).length > 0;
      }

      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push({
        event: 'user_info',
        userID: userID,
        user_existingCustomer: user_existingCustomer,
      });

      (window as any).dataLayer.push({
        event: 'virtualPageView',
        virtualPagePath: '/nuestras-agencias',
        virtualPageTitle: 'NMV: Nuestras agencias',
      });
    }
  }

  agenciaView(id: number) {
    this.currentAgency = this.agencies.find((agency: any) => agency.id === id);
    if (this.currentAgency) this.getMapSrc();
  }

  getMapSrc() {
    let src: string;
    switch (this.currentAgency!.id) {
      case 1:
        src =
          'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3900.7341024818293!2d-77.02351018578644!3d-12.130336746762024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105b7fd50f9f5db%3A0x129c4b7ccf8c2dc2!2sAv.%2028%20de%20Julio%201136%2C%20Miraflores%2015047!5e0!3m2!1ses!2spe!4v1642009579784!5m2!1ses!2spe';
        break;
      case 2:
        src =
          'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3900.8965805966145!2d-77.03940318578668!3d-12.119228146550975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c83ccc55db55%3A0xee1a21d484b9cd6f!2sAv.%20Jos%C3%A9%20Pardo%20801%2C%20Lima%2015074!5e0!3m2!1ses!2spe!4v1642007934192!5m2!1ses!2spe';
        break;
      case 3:
        src =
          'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.030625753682!2d-76.9767242491876!3d-12.110055946337173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c799ed7225b7%3A0xdae3aa58637fb50c!2sAv.%20Primavera%201352%2C%20Santiago%20de%20Surco%2015023!5e0!3m2!1ses!2spe!4v1642002369582!5m2!1ses!2spe';
        break;
      case 4:
        src =
          'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d820.0786018912304!2d-76.99195635771399!3d-12.113983956959538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c7ec37a6d22d%3A0x12293e9ad3f61963!2sAv.%20Caminos%20del%20Inca%20257%2C%20Lima%2015038!5e0!3m2!1ses-419!2spe!4v1657559525637!5m2!1ses-419!2spe';
        break;
      case 5:
        src =
          'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.5172698460233!2d-76.96848947655046!3d-12.076699064091688!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c655fc0115e3%3A0x5a3c98acd3428e16!2sAv.%20Javier%20Prado%20Este%205268%2C%20Cercado%20de%20Lima%2015023!5e0!3m2!1ses!2spe!4v1657558547148!5m2!1ses!2spe';
        break;
      case 6:
        src =
          'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d975.6333152105344!2d-77.06189117082158!3d-12.006763999468358!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105cefc90e60c19%3A0x708ef4f6cd5e8d07!2sAv.%20Alfredo%20Mendiola%203698%2C%20Cercado%20de%20Lima%2015304!5e0!3m2!1ses!2spe!4v1661794291960!5m2!1ses!2spe';
        break;
      case 7:
        src =
          'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.26192879064!2d-77.02864994909662!3d-12.094212646037027!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c95327fb3be7%3A0xf3b0d469702f0133!2sNMViajes%20San%20Isidro!5e0!3m2!1ses-419!2spe!4v1662510042435!5m2!1ses-419!2spe';
        break;
      case 8:
        src =
          'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.506232941802!2d-77.08584614916185!3d-12.077456591404935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c972729a55f3%3A0xad3605a965daafaf!2sPlaza%20San%20Miguel%2C%20Av.%20la%20Marina%202000%2C%20San%20Miguel%2015088!5e0!3m2!1ses-419!2spe!4v1676357353481!5m2!1ses-419!2spe';
        break;
      case 9:
        src =
          'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d975.3228931630202!2d-77.0553516243427!3d-12.092180685999914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c854828409d7%3A0x2effa5f9008c7027!2sAv.%20Faustino%20S%C3%A1nchez%20Carri%C3%B3n%201029%2C%20Jes%C3%BAs%20Mar%C3%ADa%2015076!5e0!3m2!1ses!2spe!4v1718829244971!5m2!1ses!2spe';
        break;
      case 10:
        src =
          'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3827.4999531174167!2d-71.53844781196263!3d-16.39941596320567!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91424a5a0293e5ed%3A0x11c6fb1453f246e4!2sPortal%20de%20San%20Agust%C3%ADn%20111%2C%20Arequipa%2004001!5e0!3m2!1ses!2spe!4v1718829054639!5m2!1ses!2spe';
        break;
      case 11:
        src =
          'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3962.054971195375!2d-79.86617111459698!3d-6.7631521486720825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x904cef86d621ff73%3A0xf73b86b4c59e2a9f!2sMall%20Aventura%20Chiclayo!5e0!3m2!1ses!2spe!4v1718829160398!5m2!1ses!2spe';
        break;
      case 12:
        src =
          'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3900.6697521264623!2d-77.0176367!3d-12.134733599999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105b7fc724a45ed%3A0xdd3e2f303446f4ce!2sAv.%20Prolongacion%20Paseo%20de%20la%20Rep%C3%BAblica%2C%20Lima!5e0!3m2!1ses-419!2spe!4v1733179534486!5m2!1ses-419!2spe';
        break;
      case 13:
        src =
          'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.559543566841!2d-76.94056337576703!3d-12.073797138165117!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c76690732ef7%3A0x44d34d8fd84bc084!2sParque%20La%20Molina!5e0!3m2!1ses-419!2spe!4v1736812048506!5m2!1ses-419!2spe';
        break;
      default:
        src = '';
        break;
    }
    this.mapSrc = this.sanitizer.bypassSecurityTrustResourceUrl(src);
  }

  onClick() {
    if (this.currentAgency?.id === 1) this.router.navigate(['/28-de-julio']);
  }

  portalAyuda() {
    if (this.isBrowser) window.open('https://ayuda.nmviajes.com/support/home', '_blank');
  }
}
