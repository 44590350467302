import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class InputFieldComponent {
  @Input() controlName!: string;
  @Input() id = '';
  @Input() labelText = '';
  @Input() inputType = 'text';
  @Input() inputPlaceholder = '';
  @Input() inputCssClass = '';
  @Input() required = false;
  @Input() maxInputLength = 1000;
  @Input() autocompleteType = 'off';
  @Input() inputMode = '';
  @Input() showValidState = false;
  @Input() validMessage: string;
  @Input() showInvalidState = false;
  @Input() invalidMessage: string;
  @Input() allowOnlyNumber = false;
  @Input() allowOnlyLetters = false;

  constructor() {}

  get inputId(): string {
    return this.id || this.controlName;
  }

  get isInvalid(): boolean {
    return this.showInvalidState && !!this.invalidMessage;
  }

  get isValid(): boolean {
    return this.showValidState && !!this.validMessage;
  }
}
