import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterOutlet } from '@angular/router';
import { NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NguCarouselModule } from '@ngu/carousel';
import { DestinyService } from 'src/app/services/destiny/destiny.service';
import { ButtonModule } from 'src/app/shared/components/moleculas/button/button.module';
import { FilterTabsModule } from 'src/app/shared/components/moleculas/filter-tabs/filter-tabs.module';
import { FiltersafeModule } from 'src/app/shared/components/moleculas/filtersafe/filtersafe.module';
import { NewCardOfferModule } from 'src/app/shared/components/moleculas/new-card-offer/new-card.module';
import { CardPlaceholderModule } from '../../shared/components/moleculas/card-placeholder/card-placeholder.module';
import { CustomButtonsModule } from '../../shared/components/moleculas/custom-buttons/custom-buttons.module';
import { InputModule } from '../../shared/components/moleculas/input/input.module';
import { LightboxModule } from '../../shared/components/moleculas/lightbox/lightbox.module';
import { SelectModule } from '../../shared/components/moleculas/select/select.module';
import {
  PackageSectionModule,
  FlightSearchModule,
  SectionWrapperModule,
} from '../../shared/components';
import {
  BulletinComponent,
  SearchTabsComponent,
  SectionBoletinComponent,
  SectionSuperOffersComponent,
  SectionWhyChooseUsComponent,
  VerifiedUserComponent,
} from './components';
import { HotSaleContactComponent } from './components/modals/hot-sale-contact/hot-sale-contact.component';
import { HotSaleCardComponent, HotSalePageComponent } from './pages/hot-sale-page';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { HomeSectionsComponent } from './pages/home-sections/home-sections.component';
import { HotSaleDetailComponent } from './pages/hot-sale-detail/hot-sale-detail.component';
import { SectionTilesComponent } from './components/sections/section-tiles/section-tiles.component';
import { DirectivesModule } from '../../shared/directives/directives.module';
import { PipesModule } from '../../shared/pipes/pipes.module';

@NgModule({
  declarations: [
    HomePageComponent,
    SectionWhyChooseUsComponent,
    SectionBoletinComponent,
    BulletinComponent,
    SectionSuperOffersComponent,
    VerifiedUserComponent,
    SearchTabsComponent,
    HomeSectionsComponent,
    HotSalePageComponent,
    HotSaleCardComponent,
    HotSaleDetailComponent,
    HotSaleContactComponent,
    SectionTilesComponent,
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    RouterOutlet,
    MatSidenavModule,
    FilterTabsModule,
    FiltersafeModule,
    NguCarouselModule,
    ButtonModule,
    ReactiveFormsModule,
    NewCardOfferModule,
    SelectModule,
    LightboxModule,
    InputModule,
    NgbTooltipModule,
    NewCardOfferModule,
    CustomButtonsModule,
    CardPlaceholderModule,
    NgbNavModule,
    PackageSectionModule,
    NguCarouselModule,
    PipesModule,
    SectionWrapperModule,
    FlightSearchModule,
  ],
  providers: [DestinyService, FormGroupDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class HomeModule {}
