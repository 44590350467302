import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NmvModel } from 'src/app/shared/utils/shared-utils';
import { ResponseModelT } from 'src/app/shared/models/shared-models';
import { map } from 'rxjs/operators';
import { IAereolineas } from 'src/app/shared/components/moleculas/aereolineas/aereolineas.interfaces';

@Injectable()
export class FlightService {
  constructor(private httpClient: HttpClient) {}

  getAereolineas() {
    const nmvModel = new NmvModel();

    const options = {
      params: nmvModel.params.set('Parameter.Status', true),
    };
    const url = environment.urlNmviajes + '/Airline';
    return this.httpClient
      .get<ResponseModelT<IAereolineas[]>>(url, options)
      .pipe(map((resp) => resp.Result));
  }
}
