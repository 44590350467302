import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BreakpointService {
  constructor(private readonly breakpointObserver: BreakpointObserver) {}

  /**
   * Checks if the current viewport matches a specified breakpoint.
   *
   * @param breakpoint A breakpoint value from Angular CDK's `Breakpoints`
   * @returns An observable that emits `true` if the breakpoint matches, otherwise `false`.
   */
  isBreakpoint(breakpoint: string | string[]): Observable<boolean> {
    return this.breakpointObserver.observe(breakpoint).pipe(
      map((state) => state.matches),
      shareReplay(1)
    );
  }
}
