import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-hot-sale-contact',
	templateUrl: './hot-sale-contact.component.html',
	styleUrls: [ './hot-sale-contact.component.scss' ]
})
export class HotSaleContactComponent implements OnInit {

	constructor(public activeModal: NgbActiveModal) {
	}

	ngOnInit(): void {
	}

}
