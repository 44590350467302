import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { MaterialModule } from '../../../shared/material.module';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '../../../shared/components/moleculas/button/button.module';
import { CustomButtonsModule } from '../../../shared/components/moleculas/custom-buttons/custom-buttons.module';
import { PipesModule } from '../../../shared/pipes/pipes.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [FooterComponent],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    ButtonModule,
    CustomButtonsModule,
    PipesModule,
  ],
  exports: [FooterComponent],
})
export class FooterModule {}
