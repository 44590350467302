export const SEO = {
	home: {
		url: 'https://www.nmviajes.com/',
		title: 'Viajes y Paquetes Turísticos en Perú | Nuevo Mundo Viajes',
		description: 'Descubre increíbles destinos con NM Viajes. Ofrecemos los mejores vuelos, hoteles y paquetes turísticos con experiencias personalizadas. ¡Reserva ahora y viaja al mejor precio!',
		image: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png',
		height: '450',
		width: '900',
		logo: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png'
	},
	flights: {
		url: 'https://www.nmviajes.com/vuelos',
		title: 'Vuelos baratos y pasajes aéreos | Nuevo Mundo Viajes',
		description: 'Encuentra vuelos baratos a destinos nacionales e internacionales. Descubre ofertas y promociones en pasajes baratos con NMViajes. Pasajes al mejor precio y con beneficios ',
		image: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png',
		height: '450',
		width: '900',
		logo: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png'
	},
	touristPackages: {
		url: 'https://www.nmviajes.com/paquetes',
		title: 'Paquetes Turísticos Baratos | Paquetes de viaje en Nuevo Mundo Viajes',
		description: 'Encuentra ofertas y promociones en paquetes turísticos baratos. Los mejores paquetes de viaje nacionales e internacionales los encuentras con precios exclusivos en NM Viajes. Tu viaje en cuotas sin intereses.',
		image: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png',
		height: '450',
		width: '900',
		logo: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png'
	},
	planTrip: {
		url: 'https://www.nmviajes.com/armapaquete',
		title: 'Paquetes Turísticos Baratos | Paquetes de viaje en Nuevo Mundo Viajes',
		description: 'Encuentra ofertas y promociones en paquetes turísticos baratos. Los mejores paquetes de viaje nacionales e internacionales los encuentras con precios exclusivos en NM Viajes. Tu viaje en cuotas sin intereses.',
		image: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png',
		height: '450',
		width: '900',
		logo: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png'
	},
	flightAndHotel: {
		url: 'https://www.nmviajes.com/vuelohotel',
		title: 'Paquetes Vuelos más Hoteles | Nuevo Mundo Viajes',
		description: 'Reserva tu vuelo y hotel en una sola compra. Encuentra pasajes aéreos con alojamiento a precios increíbles. Las mejores ofertas y promociones de vuelo más alojamiento en NM Viajes.',
		image: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png',
		height: '450',
		width: '900',
		logo: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png'
	},
	hotels: {
		url: 'https://www.nmviajes.com/hoteles',
		title: 'Hoteles baratos y alojamientos | Nuevo Mundo Viajes',
		description: 'Descubre y reserva los mejores hoteles con ofertas exclusivas. Encuentra alojamientos y hoteles baratos con promociones y descuentos en NM Viajes. Hospedaje con los mejores precios.',
		image: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png',
		height: '450',
		width: '900',
		logo: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png'
	},
	cars: {
		url: 'https://www.nmviajes.com/autos',
		title: 'Alquiler de autos – Las mejores ofertas y variedad de vehículos - NM Viajes',
		description: 'Encuentra las mejores ofertas en alquiler de autos para tus viajes. Amplia variedad de vehículos, precios competitivos y servicio de calidad en NM Viajes.',
		image: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png',
		height: '450',
		width: '900',
		logo: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png'
	},
	activities: {
		url: 'https://www.nmviajes.com/actividades',
		title: 'Actividades Turísticas - Descubre y Reserva los mejores tours',
		description: ' Explora y reserva actividades turísticas emocionantes en los mejores destinos. Variedad de tours, encuentra experiencias únicas al mejor precio. Planifica tus actividades con NM Viajes y vive momentos inolvidables.',
		image: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png',
		height: '450',
		width: '900',
		logo: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png'
	},
	travelInsurance: {
		url: 'https://www.nmviajes.com/seguro-viaje',
		title: 'Seguros de viaje | Viaja seguro con Assist Card y Nuevo Mundo Viajes',
		description: 'Encuentra ofertas y promociones en seguros de viaje y asistencia con Assist Card y NM Viajes. Conoce nuestros planes y coberturas de viaje a precios exclusivos. Asistencia médica, pérdida de equipaje y más lo encuentras en Nuevo Mundo Viajes.',
		image: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png',
		height: '450',
		width: '900',
		logo: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png'
	},
	insurance: {
		url: 'https://www.nmviajes.com/seguros/',
		title: 'Seguros de viaje | Viaja seguro con Assist Card y Nuevo Mundo Viajes',
		description: 'Encuentra ofertas y promociones en seguros de viaje y asistencia con Assist Card y NM Viajes. Conoce nuestros planes y coberturas de viaje a precios exclusivos. Asistencia médica, pérdida de equipaje y más lo encuentras en Nuevo Mundo Viajes.',
		image: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png',
		height: '450',
		width: '900',
		logo: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png'
	},
	scheduleYourAppointment: {
		title: 'Agenda un cita virtual con una asesor de NM Viajes',
		description: 'Recibe la asesoría de una especialista en viajes y haz que el viaje de tus sueños sea una realidad.'
	},
	careChannels: {
		title: 'Conoce todos los canales de atención de NM Viajes',
		description: 'Elige el canal de atención de tu preferencia y planifica tu viaje a los mejores precios.'
	},
	policies: {
		url: 'https://www.nmviajes.com/politicas',
		title: 'Política de Privacidad | NMViajes',
		description: 'Términos y condiciones de tu la protección de tus datos en Nuevo Mundo Viajes. Toda la seguridad con tus datos en NMViajes.',
		image: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png',
		height: '450',
		width: '900',
		logo: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png'
	},
	booking: {
		url: 'https://www.nmviajes.com/condiciones-de-reserva',
		title: 'Condiciones de tu viaje | NMViajes',
		description: 'Términos y condiciones de tu contrato en vuelos, hoteles y paquetes nacionales e internacionales. Conoce todos los detalles de tu viaje en Nuevo Mundo Viajes.',
		image: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png',
		height: '450',
		width: '900',
		logo: 'https://www.nmviajes.com/Images/seo/logo_nmviajes.png'
	},
	responsability: {
		url: 'https://www.nmviajes.com/responsabilidad-social',
		title: 'Responsabilidad Social | NMViajes',
		description: 'Encuentra el mejor precio en pasajes aéreos, paquetes turísticos, vuela a Miami, Cusco, Arequipa y más destinos con NMViajes.'
	},
	date: {
		url: 'https://www.nmviajes.com/tuscitas',
		title: 'Agenda tu cita en Nuevo Mundo Viajes | NMViajes',
		description: 'Agenda tu próxima cita en Nuevo Mundo Viajes. Te esperamos para ofrecerte increíbles descuentos y promociones en vuelos, hoteles y paquetes turísticos nacionales e internacionales. Tu próximo viaje en cuotas sin intereses.'
	}, 
	complaints: {
		url: 'https://www.nmviajes.com/libro-reclamaciones',
		title: 'Libro de Reclamaciones | NMViajes',
		description: 'Libro de reclamos en Nuevo Mundo Viajes. Queremos escucharte. Completa todos los datos del formulario para poder ingresar tu reclamo.'
	}, 
	benefits: {
		url: 'https://www.nmviajes.com/beneficios',
		title: 'Beneficios en Nuevo Mundo Viajes | NMViajes ',
		description: 'Conoce los mejores beneficios para viajar en Nuevo Mundo Viajes. Tu próximo viaje hasta en 12 cuotas sin intereses con BCP, BBVA, Scotiabank e Interbank. Ofertas y promociones en vuelos, hoteles y paquetes nacionales e internacionales.'
	}, 
	july: {
		url: 'https://www.nmviajes.com/28-de-julio',
		title: 'Nuevo Mundo Viajes 28 de Julio | NMViajes ',
		description: 'Conoce nuestra tienda de 28 de Julio, Miraflores de Nuevo Mundo Viajes. Encuentra los mejores precios y promociones en vuelos, hoteles y paquetes nacionales e internacionales en NMViajes. Ubícanos en 28 de Julio, Miraflores.'
	},
	company: {
		url: 'https://www.nmviajes.com/nuestra-empresa',
		title: '¿Quiénes somos en Nuevo Mundo Viajes? | NMViajes ',
		description: '¿Quiénes somos en Nuevo Mundo? Somos una Agencia de Viajes líderes en el Perú con más de 15 años en el mercado. Estás a un solo paso de armar tu próximo viaje con nosotros. Consigue los mejores precios y promociones en vuelos, hoteles y paquetes. '
	},
	documentTravel: {
		url: 'https://www.nmviajes.com/nuestra-empresa',
		title: 'Documentación para viajar | NMViajes',
		description: 'Toda la documentación que necesitas para tu próximo viaje. ¿Qué documentos llevo al viajar? En Nuevo Mundo Viajes te contamos todo lo que necesitas saber y llevar para tu próxima experiencia.'
	},
	termCond: {
		url: 'https://www.nmviajes.com/terminos-condiciones',
		title: 'Términos y condiciones en Nuevo Mundo Viajes | NMViajes ',
		description: 'Términos y condiciones de tu contrato en vuelos, hoteles y paquetes nacionales e internacionales. Conoce todos los detalles de tu viaje en Nuevo Mundo Viajes.'
	},
	promotions: {
		url: 'https://www.nmviajes.com/promociones',
		title: 'Promociones en Nuevo Mundo Viajes | NMViajes ',
		description: 'Conoce las mejores promociones en vuelos, hoteles, paquetes turísticos nacionales e internacionales y más. Reserva tu próximo viaje con ofertas increíbles en Nuevo Mundo Viajes.'
	},
	suscriptions: {
		url: 'https://www.nmviajes.com/suscribirse',
		title: 'Suscríbete en Nuevo Mundo Viajes | NMViajes ',
		description: '¿Quieres conocer primero las mejores ofertas y promociones en vuelos, hoteles y paquetes nacionales e internacionales? Estás a un solo click de conocer descuentos en miles de viajes.'
	},
	events: {
		url: 'https://www.nmviajes.com/eventos',
		title: 'Eventos en Nuevo Mundo Viajes | NMViajes',
		description: 'Los mejores eventos de viajes los encuentras en Nuevo Mundo Viajes. Ofertas y promociones en paquetes turísticos nacionales e internacionales a los mejores precios en NMViajes.'
	},
	reception: {
		url: 'https://www.nmviajes.com/recepcion',
		title: 'Recepción de Datos | NMViajes ',
		description: 'Registra tu asistencia en Nuevo Mundo Viajes'
	},
	traveloutlet: {
		url: 'https://www.nmviajes.com/recepcion',
		title: 'Travel Outlet en Nuevo Mundo Viajes | NMViajes',
		description: 'Travel Outlet en NMviajes - Europa Clásica con vuelo incluido. Nunca estuviste tan cerca de tu próxima aventura. Termina tu reserva y viaja con NM Viajes.'
	},
	canales: {
		url: 'https://www.nmviajes.com/canales-de-atencion',
		title: 'Tiendas de Nuevo Mundo Viajes | NMViajes ',
		description: 'Conoce las tiendas de Nuevo Mundo Viajes en Lima y Provincias. Visítanos para encontrar las mejores promociones y ofertas de viajes nacionales e internacionales.'
	}, 
	agencies: {
		url: 'https://www.nmviajes.com/nuestras-agencias',
		title: 'Tiendas de Nuevo Mundo Viajes | NMViajes',
		description: 'Conoce las tiendas de Nuevo Mundo Viajes en Lima y Provincias. Visítanos para encontrar las mejores promociones y ofertas de viajes nacionales e internacionales'
	}

} as const;