import { ScriptConfigModel } from '../../../shared/models';

export const CookiesConfigScripts: Record<string, ScriptConfigModel[]> = {
  TECHNICAL_COOKIES: [
    { async: true, defer: true, isNoscript: false, url: 'https://accounts.google.com/gsi/client' }, // Google SignIn
    {
      async: true,
      defer: true,
      isNoscript: false,
      url: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
    }, // Apple SignIn
  ],
};
