import { Component, Inject, Input, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DestinyService } from 'src/app/services/destiny/destiny.service';
import { PopUpPasajeroComponent } from '../../pop-up-pasajero/pop-up-pasajero.component';
import { ParamPaquete, URLPaquete } from '../../tabs/tabs.models';
import { SaveModelVuelos } from 'src/app/shared/components/moleculas/tabs/tabs.models';
import { InputValidationService } from '../../../../validators/inputValidation.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-tab-paquetes',
  templateUrl: './tab-paquetes.component.html',
  styleUrls: ['./tab-paquetes.component.scss'],
})
export class TabPaquetesComponent {
  @ViewChild('popUp') popUpElement: PopUpPasajeroComponent | undefined;
  @Input() set vuelosTab(value: SaveModelVuelos) {
    if (value) {
      this.form.setValue(value.form);
      this._vuelosTab = value;
    }
  }

  get vuelosTab() {
    return this._vuelosTab;
  }

  private _vuelosTab: SaveModelVuelos;

  form!: FormGroup;
  fromDate: NgbDate | null;
  origen: any;
  destino: any;
  toDate: NgbDate | null;

  distribution = '';
  hoveredDate: NgbDate | null = null;

  countries: Array<any> = [];
  countriesPackage: Array<any> = [];
  countriesPackageSearch: Array<any> = [];
  themes: Array<any> = [];
  months: Array<any> = [];
  noches: Array<any> = [
    { code: '3,4,5', name: 'de 1 a 5 noches' },
    {
      code: '6',
      name: 'de 6 a 10 noches',
    },
    { code: '11', name: 'de 11 a 15 noches' },
    { code: '15', name: 'Más de 15 noches' },
  ];

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private destineService: DestinyService,
    public formatter: NgbDateParserFormatter,
    private _snackBar: MatSnackBar,
    public inputValidator: InputValidationService
  ) {
    this.form = new FormGroup({
      destino: new FormControl(''),
      themes: new FormControl(''),
      months: new FormControl(''),
      noches: new FormControl(''),
    });
    this.getListCountries();
    this.getPackageCountries();
    this.getThemes();
    this.getMonths();
  }

  autoCompleteInit(): void {
    this.countriesPackageSearch = [];
    this.countriesPackageSearch = this.countriesPackage;
  }

  autoComplete(e: any) {
    this.countriesPackageSearch = [];
    let elemento = e.target;

    let value = elemento.value;

    if (value.length >= 1)
      this.countriesPackageSearch = this.countriesPackage.filter((item) =>
        item.label.toLowerCase().includes(value.toLowerCase())
      );
  }

  getThemes() {
    this.destineService.getThemes().subscribe(
      (data) => (this.themes = data),
      (err) => console.error(err)
    );
  }

  getMonths() {
    this.destineService.getFilters().subscribe(
      (data) => (this.months = data.months),
      (err) => console.error(err)
    );
  }

  getListCountries() {
    this.destineService.getDestinyCountriesPaqueteDinamico().subscribe((data) => {
      this.countries = data;
    });
  }

  getPackageCountries(): void {
    this.destineService.getPackageCountry().subscribe(
      (data) => {
        this.countriesPackage = data;
      },
      (err) => console.error(err)
    );
  }

  navigateToResponseUrl(url: string): void {
    if (isPlatformBrowser(this.platformId)) window.location.href = url;
  }

  public async searchPaquete() {
    const url = this.getUrlPaquete();
    this.navigateToResponseUrl(url);
  }

  public getUrlPaquete() {
    let url: string;
    let params = this.getParamsAlojamiento();
    url = new URLPaquete(params, params.idDestino).getUrl();
    return url;
  }

  getParamsAlojamiento() {
    return new ParamPaquete(
      this.fromDate,
      this.toDate,
      this.form,
      this.countriesPackageSearch,
      this.themes,
      this.months,
      this.noches
    ).getParams();
  }
}
