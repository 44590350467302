import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { Search } from 'src/app/api/api-nmviajes/models/ce-metasearch';
import { AccountsService } from 'src/app/services/user-account/accounts.service';
import { NotificationService } from 'src/app/services/notifications/notification.service';
import { GlobalComponent } from 'src/app/shared/global';
import { SearchHistory } from '../../../../models/search-history.model';
import { StorageService } from '../../../../../services/storage';
import { StorageKeysEnum } from '../../../../constants';
import { StringUtils } from '../../../../utils/string-utils';
import { InputClassComponent } from '../../../moleculas/input-class/input-class.component';
import { InputPassengersComponent } from '../../../moleculas/input-passengers/input-passengers.component';
import { InputRangeComponent } from '../../../moleculas/input-range/input-range.component';
import { FlightLocationsInputsComponent } from '../../../molecules';
import { StorageUtils } from '../../../../utils/storage.utils';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-flight-search',
  templateUrl: './flight-search.component.html',
  styleUrls: ['./flight-search.component.scss'],
})
export class FlightSearchComponent implements OnInit, OnDestroy {
  @ViewChild('childPassengers') childPassengers!: InputPassengersComponent;
  @ViewChild('childClass') childClass!: InputClassComponent;
  @ViewChild('childInputs') childInputs!: FlightLocationsInputsComponent;
  @ViewChild('childDates') childDates!: InputRangeComponent;
  @Output() reloadPageResult = new EventEmitter();

  private readonly isBrowser: boolean = isPlatformBrowser(this.platformId);
  private readonly destroy$ = new Subject<void>();
  private counterSearch = 0;

  flightType: number = 0;
  rangeStartDate: NgbDate | null = null;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly notificationService: NotificationService,
    private readonly accountsService: AccountsService,
    private readonly storageService: StorageService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.initFlightType();
  }

  private initFlightType() {
    if (this.router.url.includes('resultados'))
      this.flightType = GlobalComponent.searchData.flightType ?? 0;
  }

  onDateRangeChanged(event: any) {
    this.rangeStartDate = event.fromDate;
  }

  handleSearch() {
    this.counterSearch++;

    const passengersData = this.childPassengers.getValues();
    const classData = this.childClass.getValues();
    const inputData = this.childInputs.getSelectedLocations();
    const dateData = this.childDates.getValues();

    const validationErrors = this.validateSearchParams(inputData, dateData);

    if (validationErrors.length > 0) {
      this.showValidationErrors(validationErrors);
      return;
    }

    const searchParams = { ...classData, ...passengersData, ...inputData, ...dateData };
    this.storeSearchParams(searchParams);
    this.updateSearchHistory(inputData.arrivalLocation!);

    this.router.navigateByUrl(this.buildSearchRoute(searchParams)).then();
    this.reloadPageResult.emit();
  }

  // Checks search parameters and returns an error list
  private validateSearchParams(inputData: any, dateData: any): string[] {
    const errors: string[] = [];

    if (!inputData.arrivalLocation) errors.push('El destino es requerido');
    if (!inputData.departureLocation) errors.push('La salida es requerida');
    if (!dateData.departureDate) errors.push('La fecha de salida es requerida');
    if (!dateData.arrivalDate && this.flightType === 0)
      errors.push('La fecha de llegada es requerida');

    return errors;
  }

  // Displays validation errors and scrolls to top
  private showValidationErrors(errors: string[]) {
    if (this.isBrowser) {
      window.scroll({ top: 0, behavior: 'smooth' });
      this.notificationService.showNotificacion(
        'Datos obligatorios sin completar',
        errors.join(' - '),
        7
      );
    }
  }

  // Stores search parameters in localStorage
  private storeSearchParams(params: any) {
    const route = this.buildSearchRoute(params);
    this.storageService.setItem<string>(StorageKeysEnum.SEARCH_PARAMS, route);
  }

  // Updates search history
  private updateSearchHistory(arrivalLocation: string) {
    const arrivalDecoded = arrivalLocation
      .split('%20')
      .map((str) => StringUtils.capitalizeWords(str.replace(',', '')));

    const newHistoryItem: SearchHistory = {
      code: arrivalDecoded[0],
      name: arrivalDecoded[1],
      country: arrivalDecoded[2],
    };

    const storedHistory = this.storageService.getItem<SearchHistory[] | { value: SearchHistory[] }>(
      StorageKeysEnum.SEARCH_HISTORY
    );

    let searchHistory = StorageUtils.getValueOrDefault<SearchHistory[]>(storedHistory, []);
    const duplicateIndex = searchHistory.findIndex((item) => item.code === newHistoryItem.code);

    if (duplicateIndex !== -1) searchHistory.splice(duplicateIndex, 1);

    searchHistory.unshift(newHistoryItem);
    searchHistory = searchHistory.slice(0, 3);

    this.storageService.setItem<SearchHistory[]>(StorageKeysEnum.SEARCH_HISTORY, searchHistory);
  }

  // Generates a random query string aleatorio
  private generateRandomQuery(): string {
    return `?rand=${Math.round(Math.random() * 10000000000)}`;
  }

  // Build the search route for simple flights
  private buildSearchRoute(data: Search): string {
    const query = this.generateRandomQuery();
    const userEmail = this.accountsService.getUserStorage().email || '';

    return `/resultados${query}&departureLocation=${data.departureLocation}&arrivalLocation=${data.arrivalLocation}&departureDate=${data.departureDate}&arrivalDate=${data.arrivalDate}&adults=${data.adults}&children=${data.children}&infants=${data.infants}&flightType=${this.flightType}&flightClass=${data.flightClass}&lang=ES&email=${userEmail}`;
  }

  searchMultiCity(searchData: any) {
    this.counterSearch++;

    const errors: string[] = [];

    if (searchData.some((item: any) => !item.departureLocation))
      errors.push('La salidas son requeridos');
    if (searchData.some((item: any) => !item.arrivalLocation))
      errors.push('Los destinos son requeridos');
    if (searchData.some((item: any) => item.departureDate == ''))
      errors.push('Las fechas de salidas son requeridos');

    if (errors.length > 0) {
      if (this.isBrowser) window.scroll({ top: 0, behavior: 'smooth' });
      this.notificationService.showNotificacion(
        'Datos obligatorios sin completar',
        errors.join(' - '),
        7
      );
      return;
    }

    this.processMultiCitySearch(searchData);
  }

  // Build the search route for multiple destinations
  private processMultiCitySearch(searchData: Search[]) {
    const query = this.generateRandomQuery();
    const userEmail = this.accountsService.getUserStorage().email || '';

    const passengerData = {
      ...this.childPassengers.getValues(),
      ...this.childClass.getValues(),
    };

    const route = `/resultados${query}&adults=${passengerData.adults}&children=${passengerData.children}&infants=${passengerData.infants}&selected_cabins=&excludedAirlines=&multicity=&json=${encodeURIComponent(
      JSON.stringify(searchData)
    )}&email=${userEmail}&flightType=2&flightClass=${passengerData.flightClass}`;

    this.storageService.setItem<string>(StorageKeysEnum.SEARCH_PARAMS, route);
    this.router.navigateByUrl(route).then();
    this.reloadPageResult.emit();
  }

  changeFlightType(index: number) {
    this.flightType = index;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
