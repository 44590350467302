import { NgModule } from '@angular/core';
import { BurgerMenuComponent } from './burger-menu.component';

@NgModule({
    imports: [],
    exports: [BurgerMenuComponent],
    declarations: [
    BurgerMenuComponent
  ],
    providers: [],
})
export class BurgerMenuModule { }
