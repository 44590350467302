import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-top-banner',
  templateUrl: './top-banner.component.html',
  styleUrls: ['./top-banner.component.scss'],
})
export class TopBannerComponent {
  @Input() content: string = '';

  protected readonly isBrowser = isPlatformBrowser(this.platformId);

  constructor(@Inject(PLATFORM_ID) private readonly platformId: Object) {}
}
