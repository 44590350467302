import { IBooking } from "../../api/api-checkout/models/rq-checkout-booking"

export const detailPricingInit={
    passengersCount: 0,
    baseFareADT: 0,
    baseFareINF: 0,
    baseFareCNN: 0,
    taxes: 0,
    feeNMV: 0,
    feePTA: 0,
    totalPay: 0,
    totalADT: 0,
    totalCNN: 0,
    totalINF: 0
};

export const dataInitBooking:IBooking={
    groupId:'',
    segmentSelected: [0,0],
    deviceSessionId: '',
    paymentType:0,
    brandedFareName: '',
    CuponPromoWeb: '',
    generateTicket: false,
    customer: {
        username: '',
        firstName: '',
        lastName: '',
        address: 'Lima - Peru',
        email: ''
    },
    card: {
        documentType: null,
        numberQuotes: 0,
        counter: 1
    },
    contact: {
        name: '',
        lastName: '',
        email: '',
        address: '',
        isBilling: true,
        ruc: '',
        phones: [],
        subCode: 0,
        company: {
            companyName: '',
            companyAddress: ''
        },
        idLoggin: 0
    },
    passengers:[]
};
