import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PlatformService } from '../shared/services';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class PlatformInterceptor implements HttpInterceptor {

	constructor(private platformService: PlatformService, private _activatedRoute: ActivatedRoute) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const mobile = this._activatedRoute.snapshot.queryParamMap.get('mobile');
		const device = this._activatedRoute.snapshot.queryParamMap.get('device');

		if (mobile)
			this.platformService.setMobile(parseInt(mobile, 10));

		if (device)
			this.platformService.setDevice(parseInt(device, 10));

		return next.handle(request);
	}
}
