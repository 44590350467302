import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmailRequestModel } from '../../../../api/api-nmviajes/models/ce-email-request.model';
import { EmailResponse } from '../../../../api/api-nmviajes/models/rq-email-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReengagementService {
  constructor(private http: HttpClient) {}

  sendEmail(request: EmailRequestModel): Observable<EmailResponse> {
    let url = `${environment.urlApiCorreos}/v1/api/Message/SendReengancheViajes`;
    return this.http.post<EmailResponse>(url, request, {
      headers: { 'not-loading': 'true' },
    });
  }
}
