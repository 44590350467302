export const LIST_DOC_TYPES = Object.freeze([
  Object.freeze({ value: '0', name: 'DNI' }),
  Object.freeze({ value: '1', name: 'CE' }),
  Object.freeze({ value: '2', name: 'PAS' }),
]);

export const VALUE_DOC_TYPES = {
  0: 'DNI',
  1: 'CE',
  2: 'PAS',
};
