import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { ICarouselItem } from './carousel-home.model';

@Component({
	selector: 'app-carousel-home',
	templateUrl: './carousel-home.component.html',
	styleUrls: ['./carousel-home.component.scss']
})
export class CarouselHomeComponent {
	@Input() heightImages: string = '100px';
	@Input() borderRadius: string = '0px';
	@Input() carouselConfig: NguCarouselConfig;
	@Input() carouselItems: ICarouselItem[] = [];
	@Input() containsCards = false;
	@Input() visibleButtons = true;
	@Input() visiblePoints = false;

	constructor(@Inject(PLATFORM_ID) private _platformId: Object) {}

	redirectToUrl(link: string) {
		if (isPlatformBrowser(this._platformId)) {
			if (link !== '' && link.includes('http')) window.open(link, '_blank');
		}
	}
}
