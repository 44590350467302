import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { CryptoService } from 'src/app/Services/util/crypto.service';
import { isPlatformBrowser} from '@angular/common';
import { SeoService } from 'src/app/shared/services';
import { PATH_NAMES } from 'src/app/shared/constants';

@Component({
  selector: 'app-terminos-condiciones',
  templateUrl: './terminos-condiciones.component.html',
  styleUrls: ['./terminos-condiciones.component.scss']
})
export class TerminosCondicionesComponent implements OnInit {
  isBrowser: Boolean = false;
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private _cryptoService: CryptoService, 
  private _seoService: SeoService) { }

  ngOnInit(): void {
    const path  = PATH_NAMES.TERM_COND;
		this._seoService.setSEOByPath(path);

    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
    }
    if (this.isBrowser) {
      let userID: string = '';
      let user_existingCustomer: boolean = false;
      const credentials = localStorage.getItem('usuario');
      const bookings = localStorage.getItem('bookings');

      if (credentials) {
        const credentialsJson = JSON.parse(credentials);
        userID = this._cryptoService.encrypt(credentialsJson.email);

        if (bookings)
          user_existingCustomer = JSON.parse(bookings).length > 0;
      }

      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push({
        event: "user_info",
        userID: userID,
        user_existingCustomer: user_existingCustomer
      });

      (window as any).dataLayer.push({
        event: "virtualPageView",
        virtualPagePath: "/terminos-condiciones",
        virtualPageTitle: "NMV: Condiciones de compra"
      });
    }
  }

  scrollToSection(id: string) {
    if (this.isBrowser) {
      const el = document.getElementById(id);
      el?.scrollIntoView({ 'behavior': 'smooth' });
    }
  }

  onPrint() {
    if (this.isBrowser) window.print();
  }

}
