import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CierrapuertasOfertasComponent } from './cierrapuertas-ofertas.component';



@NgModule({
  declarations: [
    CierrapuertasOfertasComponent
  ],
  imports: [
    CommonModule
  ]
})
export class CierrapuertasOfertasModule { }
