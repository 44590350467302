import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-nm-airline-card',
  templateUrl: './airline-card.component.html',
  styleUrls: ['./airline-card.component.scss'],
})
export class AirlineCardComponent {
  @Input() imageUrl: string = '';
  @Input() type: string = '';
  @Input() destination: string = '';
  @Input() airlineLogo: string = '';
  @Input() linkUrl: string | null = null;
}

