import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { ModalFeeComponent } from './modal-fee.component';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'src/app/shared/components/moleculas/button/button.module';
import { NguCarouselModule } from '@ngu/carousel';

@NgModule({
	declarations: [
		ModalFeeComponent
	],
	imports: [
		CommonModule,
		ButtonModule,
		NguCarouselModule
	],
	exports: [ ModalFeeComponent ],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA,
		NO_ERRORS_SCHEMA
	]
})
export class ModalFeeModule {
}
