import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class CookiesService {
	private _isBrowser = false;

	constructor(@Inject(PLATFORM_ID) private _platformId: Object) {
		if (isPlatformBrowser(this._platformId)) this._isBrowser = true;
	}

	setCookie(name: string, value: string, expiresDays: number = 7, path: string = '/') {
		if (this._isBrowser) {
			const date = new Date();
			date.setTime(date.getTime() + (expiresDays * 24 * 60 * 60 * 1000));

			const expires = `expires=${date.toUTCString()}`;

			document.cookie = `${name}=${encodeURIComponent(value)};${expires};path=${path}`;
		}
	}

	getCookie(name: string, parse: boolean = false): any {
		if (this._isBrowser) {
			const cookies = document.cookie.split(';');

			for (let cookie of cookies) {
				const [ cookieName, cookieValue ] = cookie.trim().split('=');

				if (cookieName === name) {
					const decodedValue = decodeURIComponent(cookieValue);

					if (parse) return JSON.parse(decodedValue);

					return decodedValue;
				}
			}

			return null;
		}
	}

	deleteCookie(name: string, path: string = '/') {
		if (this._isBrowser)
			document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path};`;
	}
}
