import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConformidadComponent } from './conformidad.component';
import { CoverageModule } from 'src/app/shared/components/moleculas/coverage/coverage.module';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CountdownModule } from 'ngx-countdown';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [ConformidadComponent],
  imports: [CommonModule, CoverageModule, RouterModule, HttpClientModule, CountdownModule],
  exports: [ConformidadComponent],
})
export class ConformidadModule {}
