import { StringUtils } from './string-utils';

export class DateUtils {
  /**
   * Converts a date string in ISO 8601 format (YYYY-MM-DD) to a date-time format.
   * @param {string} isoDate - The date string in ISO 8601 format.
   * @returns {string | null} Returns the date-time string in ISO 8601 format, or null if the input is invalid.
   * @example
   * const dateTime = DateUtils.toDateTime('2024-03-22');
   * console.log(dateTime); // Expected output: '2024-03-22T00:00:00.000Z'
   */
  static toDateTime(isoDate: string): string | null {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(isoDate)) return null;

    const date = new Date(isoDate);

    if (isNaN(date.getTime())) return null;

    return date.toISOString();
  }

  static fromDateStringToDateTimeFormat(dateStr: string): string | null {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    if (!regex.test(dateStr)) return null;

    const dateStrArr = dateStr.split('/');
    const date = Number.parseInt(dateStrArr[0]);
    const month = Number.parseInt(dateStrArr[1]) - 1;
    const year = Number.parseInt(dateStrArr[2]);

    const assembledDate = new Date(year, month, date);

    if (isNaN(assembledDate.getTime())) return null;

    return assembledDate.toISOString();
  }

  static formatDateStringToShortDateFormat(dateStr: string): string {
    const date = new Date(dateStr);

    const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const months = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];

    const dayName = days[date.getDay()];
    const dayNumber = date.getDate();
    const monthName = months[date.getMonth()];

    return `${dayName} ${dayNumber} ${monthName}`;
  }

  static formatDateStringToHoursFormat(dateStr: string): string {
    const date = new Date(dateStr);

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'pm' : 'am';
    const formattedHours = (parseInt(hours) % 12 || 12).toString().padStart(2, '0');

    return `${formattedHours}:${minutes} ${ampm}`;
  }

  static formatToHumanDate(dateStr: string): string | null {
    const parts = dateStr.split('-');
    if (parts.length !== 3) return null;

    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);

    const date = new Date(year, month, day);
    if (isNaN(date.getTime())) return null;

    const options: Intl.DateTimeFormatOptions = { weekday: 'long', day: '2-digit', month: 'long' };
    return date.toLocaleDateString('es-ES', options);
  }

  /**
   * Converts a date string in ISO 8601 format (YYYY-MM-DD)
   * to short international date format (DD/MM/YYYY).
   * @param {string} isoDate - The date string in ISO 8601 format.
   * @returns {string | null} Returns the date string in short international date format, or null if the input is invalid.
   * @example
   * const dateTime = DateUtils.toShortDate('2024-03-22');
   * console.log(dateTime); // Expected output: '22/03/2024'
   */
  static toShortDate(isoDate: string): string | null {
    if (!/^\d{4}-\d{2}-\d{2}$/.test(isoDate)) return null;

    const [year, month, day] = isoDate.split('-').map(Number);

    if (
      Number.isNaN(year) ||
      Number.isNaN(month) ||
      Number.isNaN(day) ||
      month < 1 ||
      month > 12 ||
      day < 1 ||
      day > 31
    )
      return null;

    const date = new Date(year, month - 1, day);

    if (isNaN(date.getTime())) return null;

    const dayStr = `${date.getDate()}`.padStart(2, '0');
    const monthStr = `${date.getMonth() + 1}`.padStart(2, '0');

    return `${dayStr}/${monthStr}/${date.getFullYear()}`;
  }

  static formatToHumanShortenDate(dateStr: string): string | null {
    const dateParts = dateStr.split('-');
    if (dateParts.length !== 3) return null;

    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    const year = parseInt(dateParts[2], 10);

    const months = [
      'ene',
      'feb',
      'mar',
      'abr',
      'may',
      'jun',
      'jul',
      'ago',
      'sep',
      'oct',
      'nov',
      'dic',
    ];

    const date = new Date(year, month, day);
    if (isNaN(date.getTime())) return null;

    const monthName = months[month];
    return `${day} de ${StringUtils.capitalizeWords(monthName)} ${year}`;
  }

  static getDatesDiff(startDate: Date, endDate: Date): number {
    const time =
      this.getDateWithoutTime(endDate).getTime() - this.getDateWithoutTime(startDate).getTime();
    return time / (1000 * 3600 * 24);
  }

  static getDateWithoutTime(date: Date): Date {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return new Date(year, month, day);
  }
}
