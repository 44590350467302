import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { AutoCompleteItem } from '../../../models';

@Component({
  selector: 'app-card-autocomplete',
  templateUrl: './card-autocomplete.component.html',
  styleUrls: ['./card-autocomplete.component.scss'],
})
export class CardAutocompleteComponent implements OnChanges {
  @Input() cardAutocomplete: Array<AutoCompleteItem> ;
  @Input() boxOrigen: boolean = false;
  @Input() inputText?: string;
  @Output() itmSelected = new EventEmitter<AutoCompleteItem>();

  str: any;
  lista: any[] = [];

  ngOnChanges(){
    this.cardAutocomplete.map((pnt: AutoCompleteItem) => {
      let title = pnt.title.toLowerCase();
      this.inputText = this.inputText?.toLowerCase();
      if(this.inputText && title.includes(this.inputText)) {
          this.str = title.replace(this.inputText, `<span style="color:red">${this.inputText}</span>`);
          pnt.texto = this.str;
      } else {
        pnt.texto = pnt.title;
      }
      return pnt;
    });
  }
  selectItm(itm: AutoCompleteItem) {
    this.itmSelected.emit(itm);
  }

}