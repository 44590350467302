import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Blog } from 'src/app/Models/blog/blog.interface';
import { BlogService } from 'src/app/Services/blog/blog.service';
import { NguCarouselConfig } from '@ngu/carousel';

@Component({
	selector: 'app-section-blog',
	templateUrl: './section-blog.component.html',
	styleUrls: [ './section-blog.component.scss' ],
	encapsulation: ViewEncapsulation.None
})
export class SectionBlogComponent implements OnInit {
	dataBlog: Blog[] = [];

	carouselConfig: NguCarouselConfig = {
		grid: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1, all: 0 },
		speed: 500,
		point: { visible: true, hideOnSingleSlide: true },
		button: { visibility: 'hide' },
		load: 1,
		touch: true,
		loop: false,
		easing: 'cubic-bezier(0, 0, 0.2, 1)',
		velocity: 0.2,
		animation: 'lazy'
	};

	constructor(private _blogService: BlogService) {
	}

	ngOnInit(): void {
		this._blogService.getDataBlog().subscribe({
			next: (res) => {
				res.forEach(blog => {
					const prevCategories = blog.categories.replace(/\\/g, '');
					blog.categories = JSON.parse(prevCategories);
				});
				this.dataBlog = res.slice(0, 3);
			}
		});
	}
}
