import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DEVICES } from '../../../shared/constants';
import { PlatformService } from '../../../services/platform/platform.service';
import { newGuid } from '../../../shared/utils/guid.utils';
import { ExchangeRate } from '../../api-checkout/models/rq-checkout-search';
import { RToken, WebLink } from '../models/rq-token-ce-request';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class TokenService {
  private readonly _exchangeRate = new BehaviorSubject<ExchangeRate | null>(null);

  public webLinks: WebLink[] = [];

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly _httpClient: HttpClient,
    private readonly _platformService: PlatformService
  ) {}

  get exchangeRate$(): Observable<ExchangeRate | null> {
    return this._exchangeRate.asObservable();
  }

  get exchangeRate(): ExchangeRate | null {
    return this._exchangeRate.value;
  }

  setExchangeRate(value: ExchangeRate | null) {
    this._exchangeRate.next(value);
  }

  getAndSaveToken(browser: string): Observable<RToken> {
    const credentials = localStorage.getItem('usuario');
    let req = {
      trackingCode: newGuid(),
      muteExceptions: true,
      caller: {
        company: 'Expertia',
        application: 'NMViajes',
        fromIP: '',
        fromBrowser: browser,
      },
      webId: 7,
      device: this._platformService.getDevice() ?? DEVICES.WEB,
      userCode: Number(credentials ? JSON.parse(credentials).id : '0'),
    };

    let url = `${environment.urlApiMotorVuelos}/auth/api/auth/token`;
    return this._httpClient
      .post<RToken>(url, req, {
        headers: {
          ignoreInterceptor: 'true',
          'not-loading': 'true',
        },
      })
      .pipe(
        tap((res) => {
          this.setExchangeRate(res.exchangeRate);
          this.webLinks = res.enlacesWeb;
          if (isPlatformBrowser(this.platformId))
            sessionStorage.setItem('AUTH_LINKS', JSON.stringify(res.enlacesWeb));
        }),
        catchError((err) => {
          this.setExchangeRate(null);
          return throwError(err);
        })
      );
  }

  getTokenByTransactionId(transactionId: string, ipAddress: string) {
    let url = `${environment.urlApiMotorVuelos}/auth/api/auth/meta-token/${transactionId}/${ipAddress}`;
    return this._httpClient.post<RToken>(url, {});
  }

  public getIPAddress() {
    return this._httpClient.get<any>('https://api.ipify.org/?format=json');
  }
}
