import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, filter, finalize, map, take, takeUntil, tap } from 'rxjs/operators';
import { DestinyService } from 'src/app/services/destiny/destiny.service';
import { AutoCompleteItem } from '../../../models';
import { IGeoTree } from '../../../models';
import { GlobalComponent } from '../../../global';
import { CommonModule } from '@angular/common';
import { StorageService } from '../../../../services/storage';
import { StorageKeysEnum } from '../../../constants';
import { SearchHistory } from '../../../models/search-history.model';
import { StorageUtils } from '../../../utils/storage.utils';
import { AutocompleteDropdownComponent } from '../../atoms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-flight-locations-inputs',
  templateUrl: './flight-locations-inputs.component.html',
  styleUrls: ['./flight-locations-inputs.component.scss'],
  standalone: true,
  imports: [CommonModule, AutocompleteDropdownComponent],
})
export class FlightLocationsInputsComponent implements OnInit, OnDestroy {
  @ViewChild('originAutocomplete') originAutocomplete: AutocompleteDropdownComponent;
  @ViewChild('destinationAutocomplete') destinationAutocomplete: AutocompleteDropdownComponent;

  @Input() flightType: number = 0;
  @Input() multiCityRowIndex: number = 0;

  private readonly destroy$ = new Subject<void>();
  private isReverse = false;

  private isOriginSelected: boolean = false;
  private isDestinationSelected: boolean = false;

  private origin: string | null;
  private destination: string | null;

  rotateArrows: boolean = false;
  originControl = new FormControl('');
  destinationControl = new FormControl('');

  originSuggestions: AutoCompleteItem[] = [];
  destinationSuggestions: AutoCompleteItem[] = [];

  isOriginPreloaded = false;
  isDestinationPreloaded = false;

  showOriginLoader: boolean = false;
  showDestinationLoader: boolean = false;

  noOriginResults: boolean = false;
  noDestinationResults: boolean = false;

  constructor(
    private readonly destinationService: DestinyService,
    private readonly storageService: StorageService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.listenToInputChanges(this.originControl, 'origin');
    this.listenToInputChanges(this.destinationControl, 'destination');
    this.watchLocationChanges();

    if (this.router.url.includes('resultados')) this.setInitialValuesFromSearchQuery();
  }

  // Listen to origin and destination inputs changes
  private listenToInputChanges(control: FormControl, type: 'origin' | 'destination') {
    control.valueChanges
      .pipe(
        map((search: string | null) => search?.toLowerCase().trim()),
        tap((search) => {
          if (!search || search.length < 3) {
            if (type === 'origin') {
              this.originSuggestions = [];
              this.noOriginResults = false;
            } else {
              this.destinationSuggestions = [];
              this.noDestinationResults = false;
            }
          }
        }),
        filter((search): search is string => !!search && search.length > 2),
        debounceTime(400),
        tap((search) => this.loadSuggestions(search, type === 'origin')),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  // Initializes form values based on stored search data
  private setInitialValuesFromSearchQuery() {
    const dataSearch = GlobalComponent.searchData;

    if (dataSearch.flightType !== 2 && this.multiCityRowIndex == 0)
      this.setPreloadedValues(dataSearch.fullDepartureLocation, dataSearch.fullArrivalLocation);

    if (dataSearch.multicity?.length) {
      this.setPreloadedValues(
        dataSearch.multicity[this.multiCityRowIndex].fullDepartureLocation,
        dataSearch.multicity[this.multiCityRowIndex].fullArrivalLocation
      );

      this.originControl.setValue(
        dataSearch.multicity[this.multiCityRowIndex].departureLocation.replace(/,\s*$/, '')
      );
      this.destinationControl.setValue(
        dataSearch.multicity[this.multiCityRowIndex].arrivalLocation.replace(/,\s*$/, '')
      );
    }
  }

  // Sets preloaded values for origin and destination
  private setPreloadedValues(origin?: string | null, destination?: string | null) {
    this.isOriginPreloaded = true;
    this.isDestinationPreloaded = true;

    if (origin && destination) {
      this.originControl.setValue(this.getCityName(origin));
      this.destinationControl.setValue(this.getCityName(destination));
      this.origin = origin.replace(/ /g, '%20');
      this.destination = destination.replace(/ /g, '%20');
    } else {
      this.originControl.setValue(origin ?? '');
      this.destinationControl.setValue(destination ?? '');
    }
  }

  private watchLocationChanges() {
    this.destinationService.origin$
      .pipe(takeUntil(this.destroy$))
      .subscribe((origin) =>
        this.setPreloadedLocation(true, origin.iata, origin.cityName, origin.countryName)
      );

    this.destinationService.destination$
      .pipe(takeUntil(this.destroy$))
      .subscribe((destination) =>
        this.setPreloadedLocation(
          false,
          destination.iata,
          destination.cityName,
          destination.countryName
        )
      );
  }

  private setPreloadedLocation(isOrigin: boolean, iata: string, city: string, country: string) {
    const formattedLocation = `${iata}%20${city},%20${country}`;

    if (isOrigin) {
      this.isOriginPreloaded = true;
      this.origin = formattedLocation;
      this.originControl.setValue(city);
    } else {
      this.isDestinationPreloaded = true;
      this.destination = formattedLocation;
      this.destinationControl.setValue(city);
    }
  }

  // Fetches and updates location suggestions (origin/destination)
  private loadSuggestions(term: string, isOrigin: boolean) {
    if (
      !this.shouldLoadSuggestions(
        isOrigin ? this.isOriginSelected : this.isDestinationSelected,
        this.isReverse
      )
    ) {
      isOrigin ? (this.isOriginSelected = false) : (this.isDestinationSelected = false);
      return;
    }

    // Determines if a value is preloaded
    const isPreloaded = isOrigin ? this.isOriginPreloaded : this.isDestinationPreloaded;

    // If there is no preloaded value or the term is too short, show the loader
    if (!isPreloaded || (isPreloaded && term.length <= 3))
      isOrigin ? (this.showOriginLoader = true) : (this.showDestinationLoader = true);

    if (isOrigin ? this.showOriginLoader : this.showDestinationLoader) {
      this.resetCurrentSelection(isOrigin);

      // API call to fetch location suggestions
      this.destinationService
        .getGeoTree(term)
        .pipe(
          take(1),
          finalize(() =>
            isOrigin ? (this.showOriginLoader = false) : (this.showDestinationLoader = false)
          )
        )
        .subscribe({
          next: (res) => {
            const suggestions = this.mapGeoTreeToSuggestions(res);

            if (!isPreloaded) {
              this.updateUIWithSuggestions(suggestions, isOrigin);

              // If a new suggestion matches the previous selection, auto-select it
              if (isOrigin && suggestions.some((item) => item.title === this.origin))
                this.selectOrigin(suggestions[0]);
            } else if (suggestions.length > 0) {
              // Auto-select the first option only if there are valid suggestions
              isOrigin ? this.selectOrigin(suggestions[0]) : this.selectDestination(suggestions[0]);
            }
          },
        });
    }

    // Mark as not preloaded after processing the selection
    isOrigin ? (this.isOriginPreloaded = false) : (this.isDestinationPreloaded = false);
  }

  // Determines if suggestions should be loaded based on the input state
  private shouldLoadSuggestions(isSelected: boolean, isReverse: boolean): boolean {
    return !isSelected && !isReverse;
  }

  // Resets the current selection when loading new suggestions
  private resetCurrentSelection(isOrigin: boolean) {
    isOrigin ? (this.origin = null) : (this.destination = null);
  }

  // Updates the UI with the retrieved suggestions
  private updateUIWithSuggestions(suggestions: AutoCompleteItem[], isOrigin: boolean): void {
    if (isOrigin) {
      this.originSuggestions = suggestions;
      this.noOriginResults = suggestions.length === 0;
      this.originAutocomplete.showSuggestions();
    } else {
      this.destinationSuggestions = suggestions;
      this.noDestinationResults = suggestions.length === 0;
      this.destinationAutocomplete.showSuggestions();
    }
  }

  // Opens destination history dropdown if there's no current selection
  openDestinationHistory() {
    if (this.destination) return;

    const storedHistory = this.storageService.getItem<SearchHistory[]>(
      StorageKeysEnum.SEARCH_HISTORY
    );
    const searchHistory = StorageUtils.getValueOrDefault<SearchHistory[]>(storedHistory, []);

    if (!searchHistory.length) return;

    this.destinationSuggestions = this.mapSearchHistoryToSuggestions(searchHistory);
    this.noDestinationResults = this.destinationSuggestions.length === 0;

    this.destinationAutocomplete.showSuggestions();
  }

  // Maps search history data to autocomplete suggestions
  private mapSearchHistoryToSuggestions(history: SearchHistory[]): AutoCompleteItem[] {
    return history.map((item) => ({
      children: [],
      id: item.code,
      codigo: item.code,
      country: item.country,
      title: item.name,
    }));
  }

  // Formats API response into structured autocomplete suggestions
  private mapGeoTreeToSuggestions(response: IGeoTree[]): AutoCompleteItem[] {
    const suggestions: AutoCompleteItem[] = [];

    response.forEach((location: IGeoTree) => {
      const existingItem = suggestions.find((item) => item.id == location.aerocodiata);

      if (!existingItem) {
        if (location.tn_iata_padre_fn == '0')
          suggestions.push(this.createPrimarySuggestion(location));
        else if (location.tn_iata_padre_fn == '2')
          suggestions.push(this.createParentWithChild(location));
      } else if (location.tn_iata_padre_fn == '2')
        existingItem.children.push(this.createChildSuggestion(location));
    });

    return suggestions;
  }

  // Creates a primary suggestion entry
  private createPrimarySuggestion(location: IGeoTree): AutoCompleteItem {
    return {
      id: location.aerocodiata,
      codigo: location.city_code,
      title: location.city,
      country: location.country,
      children: [],
    };
  }

  // Creates a suggestion entry with an initial child
  private createParentWithChild(location: IGeoTree): AutoCompleteItem {
    return {
      id: location.aerocodiata,
      country: '',
      codigo: '',
      title: '',
      children: [this.createChildSuggestion(location)],
    };
  }

  // Creates a child suggestion entry
  private createChildSuggestion(location: IGeoTree): AutoCompleteItem {
    return {
      id: location.aerocodiata,
      codigo: location.city_code,
      title: location.city,
      country: location.country,
      children: [],
    };
  }

  // Swaps the values of origin and destination inputs
  swapOriginAndDestination() {
    if (!(this.origin && this.destination)) return;

    this.isReverse = true;
    this.rotateArrows = !this.rotateArrows;

    const previousOrigin = this.origin;
    const previousDestination = this.destination;

    this.originControl.setValue(this.destinationControl.value);
    this.destinationControl.setValue(this.originControl.value);

    this.origin = previousDestination;
    this.destination = previousOrigin;
  }

  // Resets the origin input field
  clearOriginInput() {
    this.originControl.setValue('');
    this.origin = null;
    this.noOriginResults = false;
  }

  // Handles the selection of an origin option
  selectOrigin(option: AutoCompleteItem) {
    this.isOriginSelected = true;
    this.originControl.setValue(option.title);
    this.originSuggestions = [];
    this.origin = `${option.codigo}%20${option.title},%20${option.country}`;
  }

  // Resets the destination input field
  clearDestinationInput() {
    this.destinationControl.setValue('');
    this.destination = null;
    this.noDestinationResults = false;
  }

  // Handles the selection of a destination option
  selectDestination(option: AutoCompleteItem) {
    this.isDestinationSelected = true;
    this.destinationControl.setValue(option.title);
    this.destinationSuggestions = [];
    this.destination = `${option.codigo}%20${option.title},%20${option.country}`;
  }

  // Returns the selected origin and destination values
  getSelectedLocations() {
    return {
      departureLocation: this.origin,
      arrivalLocation: this.destination,
    };
  }

  // Handles dropdown open state for origin selection
  handleOriginDropdownState(opened: boolean) {
    if (!opened && !this.isOriginSelected) this.clearOriginInput();
  }

  // Handles dropdown open state for destination selection
  handleDestinationDropdownState(opened: boolean) {
    if (!opened && !this.isDestinationSelected) this.clearDestinationInput();
  }

  // Extracts and formats the city name from a given location string
  private getCityName(location: string) {
    const parts = location.split(',')[0].split(' ').slice(1); // Remove IATA code (e.g., "LIM, LBP")
    return parts
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .trim();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
