import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputFieldComponent } from './input-field/input-field.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectFieldComponent } from './select-field/select-field.component';
import { AutocompleteFieldComponent } from './autocomplete-field/autocomplete-field.component';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ToggleFieldComponent } from './toggle-field/toggle-field.component';
import { RadioButtonFieldComponent } from './radio-button-field/radio-button-field.component';
import { DirectivesModule } from '../../../directives/directives.module';
import { GroupedInputFieldComponent } from './grouped-input-field/grouped-input-field.component';

@NgModule({
  declarations: [
    InputFieldComponent,
    SelectFieldComponent,
    AutocompleteFieldComponent,
    ToggleFieldComponent,
    RadioButtonFieldComponent,
    GroupedInputFieldComponent,
  ],
  exports: [
    InputFieldComponent,
    SelectFieldComponent,
    AutocompleteFieldComponent,
    ToggleFieldComponent,
    RadioButtonFieldComponent,
    GroupedInputFieldComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbTypeaheadModule,
    FormsModule,
    NgxIntlTelInputModule,
    DirectivesModule,
  ],
})
export class FormFieldsModule {}
