import { GlobalComponent } from '../../../shared/global';

export class CheckoutUtils {

	static itineraryUrlBuilder(includeOrigin: boolean, email: string = ''): string {
		const global = GlobalComponent;
		const group = global.appGroupSeleted;
		const params = global.paramsSearch;

		const { transactionId, segmentSelected } = global;
		const { id: groupId } = group;
		const {
			flightType,
			departureLocation,
			arrivalLocation,
			departureDate,
			arrivalDate,
			adults,
			children,
			infants,
			flightClass
		} = params;
		return `${includeOrigin ? location.origin
				: ''}/booking/itinerary/${transactionId}/${groupId}/${segmentSelected.join('-')}/${flightType}/${departureLocation}/${arrivalLocation}/${departureDate}/${arrivalDate}/${adults}/${children}/${infants}/${flightClass}?email=${email}`;
	}
}