import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AerolineasComponent } from './Component/home-page/aerolineas/aerolineas.component';
import { CierrapuertasOfertasComponent } from './Component/home-page/cierrapuertas-ofertas/cierrapuertas-ofertas.component';
import { CierrapuertasComponent } from './Component/home-page/cierrapuertas/cierrapuertas.component';
import { CitaComponent } from './Component/home-page/cita/cita.component';
import { CondicionesDeReservaComponent } from './Component/home-page/condiciones-de-reserva/condiciones-de-reserva.component';
import { ConformidadComponent } from './Component/home-page/conformidad/conformidad.component';
import { DocumentacionViajeComponent } from './Component/home-page/documentacion-viaje/documentacion-viaje.component';
import { LibroReclamacionesComponent } from './Component/home-page/libro-reclamaciones/libro-reclamaciones.component';
import { NuestraEmpresaComponent } from './Component/home-page/nuestra-empresa/nuestra-empresa.component';
import { NuestrasAgenciasComponent } from './Component/home-page/nuestras-agencias/nuestras-agencias.component';
import { OffersComponent } from './Component/home-page/offers/offers.component';
import { PoliticasComponent } from './Component/home-page/politicas/politicas.component';
import { ResponsabilidadSocialComponent } from './Component/home-page/resonsabilidad-social/responsabilidad-social.component';
import { ResultadosComponent } from './Component/home-page/resultados/resultados.component';
import { BenefitsComponent } from './Component/home-page/retail/benefits/benefits.component';
import { RetailComponent } from './Component/home-page/retail/retail.component';
import { TerminosCondicionesComponent } from './Component/home-page/terminos-condiciones/terminos-condiciones.component';
import { TusDatosComponent } from './Component/home-page/tus-datos/tus-datos.component';
import { DestinosComponent } from './Component/home-page/vuelos/commons/components/destinos/destinos.component';
import {
	HomePageComponent,
	HomeSectionsComponent,
	HotSaleDetailComponent,
	HotSalePageComponent
} from './features/home/pages';
import { CanActivateCheckoutGuard } from './Guards/checkout.guard';
import { redirectRoutes } from './redirect-routes';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';

const routes: Routes = [
	{
		path: '',
		component: HomePageComponent,
		children: [
			{ path: '', component: HomeSectionsComponent },
			{ path: 'vuelos', component: HomeSectionsComponent },
			{ path: 'hoteles', component: HomeSectionsComponent },
			{ path: 'vuelohotel', component: HomeSectionsComponent },
			{ path: 'paquetes', component: HomeSectionsComponent },
			{ path: 'seguro-viaje', component: HomeSectionsComponent },
			{ path: 'ofertas', component: HotSalePageComponent },
			{ path: 'ofertas/:sectionId/:id', component: HotSaleDetailComponent }
		]
	},
	{
		path: 'booking/v2',
		loadChildren: () => import('./features/booking/booking.module').then((m) => m.BookingModule)
	},
	{
		path: 'seguros',
		loadChildren: () => import('./features/insurance/insurance.module').then((m) => m.InsuranceModule)
	},
	{
		path: 'perfil',
		loadChildren: () => import('./Component/home-page/perfil/perfil.module').then((m) => m.PerfilModule)
	},
	{
		path: 'traveloutlet',
		loadChildren: () =>
			import('./Component/home-page/travel-outlet/travel-outlet.module').then((m) => m.TravelOutletModule)
	},
	{
		path: 'recepcion',
		loadChildren: () =>
			import('./Component/home-page/tottem-digital/tottem-digital.module').then((m) => m.TottemDigitalModule)
	},
	{
		path: 'eventos',
		loadChildren: () => import('./Component/home-page/eventos/eventos.module').then((m) => m.EventosModule)
	},
	{
		path: 'promociones',
		loadChildren: () => import('./Component/promociones/promociones.module').then((m) => m.PromocionesModule)
	},
	{
		path: 'suscribirse',
		loadChildren: () =>
			import('./Component/home-page/subscription/subscription.module').then((m) => m.SubscriptionModule)
	},
	{
		path: 'canales-de-atencion',
		loadChildren: () =>
			import('./Component/home-page/canales-atencion/canales-atencion.module').then((m) => m.CanalesAtencionModule)
	},
	{
		path: 'resultados',
		loadChildren: () => import('./features/flights-results').then((m) => m.FlightsResultsModule)
	},
	{ path: 'agenda-tu-cita', component: CitaComponent },
	{ path: 'vuelos/resultados', component: ResultadosComponent },
	{ path: 'conformidad', component: ConformidadComponent },
	{ path: 'aerolineas/:code', component: AerolineasComponent },
	{ path: 'terminos-condiciones', component: TerminosCondicionesComponent },
	{ path: 'documentacion-viaje', component: DocumentacionViajeComponent },
	{ path: 'documentacion_de_viaje.html', component: DocumentacionViajeComponent },
	{ path: 'confirmacion/:id', component: HomePageComponent },
	{ path: 'vuelos/destino/:origin/:destination', component: DestinosComponent },
	{ path: 'vuelos/:slug', component: DestinosComponent },
	{ path: 'armapaquete', component: HomePageComponent },
	{ path: 'autos', component: HomePageComponent },
	{ path: 'actividades', component: HomePageComponent },
	{ path: 'nuestra-empresa', component: NuestraEmpresaComponent },
	{ path: 'nuestras-agencias', component: NuestrasAgenciasComponent },
	{ path: '28-de-julio', component: RetailComponent },
	{ path: 'beneficios', component: BenefitsComponent },
	{ path: 'libro-reclamaciones', component: LibroReclamacionesComponent },
	{ path: 'actividades', component: HomePageComponent },
	{ path: 'cierrapuertas', component: CierrapuertasComponent },
	{ path: 'cierrapuertas/ofertas', component: CierrapuertasOfertasComponent },
	{ path: 'tusdatos', component: TusDatosComponent },
	{ path: 'politicas', component: PoliticasComponent },
	{ path: 'condiciones-de-reserva', component: CondicionesDeReservaComponent },
	{ path: 'responsabilidad-social', component: ResponsabilidadSocialComponent },
	{ path: 'beneficios', component: BenefitsComponent },
	{ path: 'ofertas', component: OffersComponent },
	{
		path: 'widgets',
		loadChildren: () => import('./widgets/widgets.module').then((m) => m.WidgetsModule)
	},
	{
		path: 'tuscitas',
		loadChildren: () => import('./Component/home-page/tuscitas/tuscitas.module').then((m) => m.TuscitasModule)
	},
	{
		path: 'booking',
		loadChildren: () => import('./Component/checkout-page/checkout.module').then((m) => m.CheckoutPageModule),
		canActivate: [CanActivateCheckoutGuard]
	},
	{
		path: 'booking/itinerary/:transactionId/:idGroup/:segments/:flightType/:departureLocation/:arrivalLocation/:departureDate/:arrivalDate/:adults/:children/:infants/:flightClass',
		loadChildren: () => import('./Component/checkout-page/checkout.module').then((m) => m.CheckoutPageModule)
	},
	{
		path: 'booking/itinerary/:transactionId/:idGroup/:segments/:flightType/:departureLocation/:arrivalLocation/:departureDate/:arrivalDate/:adults/:children/:infants/:flightClass/:upsellName',
		loadChildren: () => import('./Component/checkout-page/checkout.module').then((m) => m.CheckoutPageModule)
	},
	/*{
		path: 'booking/ofertas/:sectionId/:offerId/:passengersQty/:departureDate/:roomType',
		loadChildren: () => import('./Component/checkout-page/checkout.module').then((m) => m.CheckoutPageModule)
	},*/
	{ path: '404', component: PageNotFoundComponent },
	...redirectRoutes,
	{ path: '**', redirectTo: '404' }
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			useHash: false,
			scrollPositionRestoration: 'top',
			initialNavigation: 'enabledBlocking'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
