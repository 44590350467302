import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DestinyService } from '../../../../../services/destiny/destiny.service';
import {
  ENmWebElementTypes,
  ENmWebSectionTypes,
  ENmWebsTypes,
  INmWeb,
  NmWebSection,
} from '../../../../../shared/constants/nm-webs.model';
import { ConfigCatService } from '../../../../../services/labs/config-cat.service';
import { NmWebsService } from '../../../services/nm-webs.service';
import { IIataDetail, ISeoFaq } from '../../../models/destination.models';

@Component({
  selector: 'app-travel-package',
  templateUrl: './travel-package.component.html',
  styleUrls: ['./travel-package.component.scss'],
})
export class TravelPackageComponent implements OnInit {
  destinations: string = '';
  package: INmWeb;
  bannerSection: NmWebSection | undefined;
  bannerPromoSection: NmWebSection | undefined;
  descriptionSection: NmWebSection | undefined;
  descriptionSectionShow: Partial<IIataDetail> | undefined;
  faqSection: NmWebSection[] = [];
  faqSectionShow: ISeoFaq[] = [];
  popularRoutesSection: NmWebSection | undefined;
  isLoading = false;
  unsubscribe$ = new Subject<void>();
  isBrowser = false;
  slug: string = '';
  destinationAlt: string = '';

  seoTitle: string = '';
  seoDescription: string = '';

  constructor(
    private readonly titleService: Title,
    private readonly metaService: Meta,
    private readonly nmWebsService: NmWebsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly _configCatService: ConfigCatService,
    private readonly destinationService: DestinyService
  ) {
    if (isPlatformBrowser(this.platformId)) this.isBrowser = true;
  }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.slug = params.routename || '';
      if (this.slug) {
        this.loadData(ENmWebsTypes.PACKAGE, this.slug);
      } else {
        this.router.navigateByUrl('/').then(() => null);
      }
    });
  }

  clickBanner(link?: string) {
    if (isPlatformBrowser(this.platformId) && link) {
      if (link !== '' && link.includes('http')) window.open(link, '_blank');
      else this.router.navigate([link]);
    }
  }

  loadData(name: string, slug: string) {
    this.isLoading = true;
    this.nmWebsService
      .getWebByNameAndSlug(name, slug)
      .pipe(take(1))
      .subscribe({
        next: (web) => this.handleWebData(web),
        error: (err) => this.handleError(err),
      });
  }

  private handleWebData(web: INmWeb) {
    this.package = web;
    this.destinations = web?.destinationIata ?? '';
    if (isPlatformBrowser(this.platformId)) {
      if (web?.autoAction) {
        this.destinationService.vueloHotelValues$.next({
          destination: web?.destinationCity,
          origin: web?.originIata,
        });
      }
    }

    this.applyMetatags(web);

    if (web?.webId) {
      this.nmWebsService.getNmWebSectionByWebId(web.webId).subscribe({
        next: (sections) => this.handleSections(sections),
        error: (err) => this.handleError(err),
      });
    } else {
      this.router.navigate(['/']);
    }
  }

  applyMetatags(web: INmWeb) {
    this.seoTitle = web.metaTitle;
    this.seoDescription = web.metaDescription;
    if (isPlatformBrowser(this.platformId)) {
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      this.setTitleAndMeta(web.metaTitle, web.metaDescription);
      this.setCanonicalUrl(baseUrl);
      this.setCanonicalLinkUrl(baseUrl);
    }
  }

  private handleSections(sections: NmWebSection[]) {
    this._configCatService.setCardListLimitFlag(!!this.package);
    this.package.sections = sections;
    this.bannerSection = this.findAndSortSection(sections, ENmWebSectionTypes.SECTION_BANNER_TOP);
    this.bannerPromoSection = this.findAndSortSection(
      sections,
      ENmWebSectionTypes.SECTION_BANNER_PROM
    );
    this.descriptionSection = this.findSection(sections, ENmWebSectionTypes.SECTION_DESCRIPTION);
    this.descriptionSectionShow = this.extractDescriptionSection(this.descriptionSection);
    this.destinationAlt = this.descriptionSectionShow?.bannerTitulo ?? '';
    this.faqSection = this.findSections(sections, ENmWebSectionTypes.SECTION_FAQ);
    this.faqSectionShow = this.extractFaqSection(this.faqSection);
    this.popularRoutesSection = this.findSection(
      sections,
      ENmWebSectionTypes.SECTION_POPULAR_ROUTES
    );
    this.isLoading = false;
  }

  private findAndSortSection(sections: NmWebSection[], type: string) {
    const section = sections.find((section) => section.nmGenericType.name === type);
    if (section?.nmWebElements) {
      section.nmWebElements.sort((a, b) => (a?.elementOrder ?? 0) - (b?.elementOrder ?? 0));
    }
    return section;
  }

  private findSection(sections: NmWebSection[], type: string) {
    return sections.find((section) => section.nmGenericType.name === type);
  }

  private findSections(sections: NmWebSection[], type: string) {
    return sections.filter((section) => section.nmGenericType.name === type);
  }

  private extractDescriptionSection(section: NmWebSection | undefined) {
    if (!section) return undefined;
    return {
      bannerTitulo:
        section.nmWebElements.find(
          (element) => element.nmGenericType?.name === ENmWebElementTypes.ELEMENT_TITLE
        )?.content ?? '',
      bannerTexto:
        section.nmWebElements.find(
          (element) => element.nmGenericType?.name === ENmWebElementTypes.ELEMENT_BODY
        )?.content ?? '',
      bannerImagenUrl:
        section.nmWebElements.find(
          (element) => element.nmGenericType?.name === ENmWebElementTypes.ELEMENT_IMAGE
        )?.nmMedia?.url ?? '',
    };
  }

  private extractFaqSection(sections: NmWebSection[]) {
    return sections
      .map((section) => {
        if (section.nmWebElements.length) {
          return {
            pregunta:
              section.nmWebElements.find(
                (element) => element.nmGenericType?.name === ENmWebElementTypes.ELEMENT_TITLE
              )?.content ?? '',
            respuesta:
              section.nmWebElements.find(
                (element) => element.nmGenericType?.name === ENmWebElementTypes.ELEMENT_BODY
              )?.content ?? '',
          };
        }
        return undefined;
      })
      .filter((faq): faq is ISeoFaq => faq !== undefined);
  }

  private handleError(err: any) {
    this.isLoading = false;
    console.error('Error:', err);
  }

  setTitleAndMeta(title: string, description: string): void {
    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({ name: 'title', content: title });
  }

  setCanonicalUrl(baseUrl: string) {
    const canonicalUrl = `${baseUrl}${this.router.url}`;
    this.metaService.updateTag({ rel: 'canonical', href: canonicalUrl });
  }

  setCanonicalLinkUrl(baseUrl: string) {
    const canonicalUrl = `${baseUrl}${this.router.url}`;
    let link: HTMLLinkElement | null = document.querySelector('link[rel="canonical"]');

    if (link) {
      link.setAttribute('href', canonicalUrl);
    } else {
      link = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', canonicalUrl);
      document.head.appendChild(link);
    }
  }

  onRedirect(link?: string) {
    if (!!link && isPlatformBrowser(this.platformId)) {
      if (link.includes('http')) {
        window.open(link, '_blank');
      } else {
        this.router.navigate([link]);
      }
    }
  }
}
