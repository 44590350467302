import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GlobalComponent } from '../../shared/global';
import { IBrevoContactModel } from '../../shared/models';
import { BrevoInitialData } from '../../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  contact: IBrevoContactModel = BrevoInitialData;

  constructor(private _http: HttpClient) {}

  createContact(data: IBrevoContactModel): Observable<any> {
    const url = `/api/contacts`;
    return this._http.post<any>(url, data, {
      headers: { 'not-loading': 'true' },
    });
  }

  updateContact(data: IBrevoContactModel): Observable<void> {
    const emailEncoded = encodeURIComponent(data.email);
    const url = `/api/contacts/${emailEncoded}`;
    return this._http.put<void>(url, data, {
      headers: { 'not-loading': 'true' },
    });
  }

  identifyUser(email: string): Observable<any> {
    const url: string = `${environment.brevoTrackerBaseUrl}/identify`;
    const data = { email };
    return this._http.post<any>(url, data, {
      headers: { 'ma-key': environment.brevoMAKey },
    });
  }

  trackEvent(event: string, data?: any): Observable<void> {
    const url: string = `${environment.brevoTrackerBaseUrl}/trackEvent`;
    const body: any = {
      event,
      email: this.contact?.email ?? GlobalComponent.appBooking.contact.email,
    };
    if (body) body.eventdata = { data };
    return this._http.post<void>(url, body, {
      headers: { 'ma-key': environment.brevoMAKey },
    });
  }
}
