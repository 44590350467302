import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../../environments/environment';
import { AccountsService } from '../../../../../Services/accounts.service';
import { NotificationService } from '../../../../../Services/notification.service';
import { LoaderSubjectService } from '../../../loader/service/loader-subject.service';
import { GoogleSignInUtils } from '../../../../utils/google-sign-in-utils';
import { ValidatorsService } from '../../../../validators/validators.service';
import { PlatformService } from '../../../../services';
import { DEVICES } from '../../../../constants';
import { CheckEmailComponent } from '../check-email/check-email.component';

declare var google: any;

@Component({
	selector: 'app-new-account',
	templateUrl: './new-account.component.html',
	styleUrls: [ './new-account.component.scss' ]
})
export class NewAccountComponent implements OnInit {
	isPerson: boolean = true;

	personalAccountForm: FormGroup;
	businessAccountForm: FormGroup;

	socialMedia: Boolean = true;
	isBrowser: Boolean = false;

	isFromMobile = false;
	isFromIOS = false;

	constructor(
			public loaderSubjectService: LoaderSubjectService,
			public activeModal: NgbActiveModal,
			@Inject(PLATFORM_ID) private _platformId: Object,
			private _accountService: AccountsService,
			private _formBuilder: FormBuilder,
			private _validatorsService: ValidatorsService,
			private _notification: NotificationService,
			private _router: Router,
			private _platformService: PlatformService,
			private _modal: NgbModal
	) {
		if (isPlatformBrowser(this._platformId)) this.isBrowser = true;
	}

	ngOnInit(): void {
		if (this.isBrowser) {
			this.isFromMobile = this._platformService.getMobile();
			this.isFromIOS = this._platformService.getDevice() === DEVICES.IOS;

			if (!this.isFromIOS && !this.isFromMobile) this.initializeGoogleSignIn();
		}

		this.personalAccountForm = this.createPersonalAccountForm();
		this.businessAccountForm = this.createBusinessAccountForm();
	}

	private initializeGoogleSignIn() {
		if (this.isBrowser) {
			google.accounts.id.initialize({
				client_id: environment.googleAccountClientId,
				callback: (response: any) => this.handleGoogleSignIn(response)
			});
			google.accounts.id.renderButton(document.getElementsByClassName('socialMedia new').item(0), {
				type: 'standard',
				theme: 'filled_blue',
				text: 'signup_with',
				size: 'large',
				shape: 'pill'
			});
		}
	}

	private handleGoogleSignIn(response: any) {
		const decoded = GoogleSignInUtils.decodeIdToken(response);
		this.resetForms();
		this._accountService.saveGoogleAccount.emit(decoded);
		this.activeModal.close(true);
	}

	appleSignIn() {
		(window as any).AppleID.auth.signIn().then((response: any) => {
			const { id_token } = response.authorization;
			const decoded: any = GoogleSignInUtils.decodeIdToken({ credential: id_token });
			this.resetForms();
			this._accountService.saveAppleAccount.emit(decoded);
			this.activeModal.close(true);
		}).catch((error: any) => {
			console.error('Error en el inicio de sesión:', error);
			this.activeModal.close(true);
		});
	}

	private resetForms() {
		this.personalAccountForm.reset();
		this.businessAccountForm.reset();
	}

	initLoading() {
		const textSend = 'CARGANDO';
		this.loaderSubjectService.showText(textSend);
		this.loaderSubjectService.showLoader();
	}

	closeLoading() {
		this.loaderSubjectService.closeLoader();
	}

	createBusinessAccountForm(): FormGroup {
		return this._formBuilder.group({
			ruc: [ '', [ Validators.required, Validators.minLength(11), Validators.pattern(this._validatorsService.digitsPattern) ] ],
			businessName: [ '', [ Validators.required, Validators.minLength(3), Validators.pattern(this._validatorsService.lettersPattern) ] ],
			firstName: [ '', [ Validators.required, Validators.minLength(3), Validators.pattern(this._validatorsService.lettersPattern) ] ],
			fatherLastname: [ '', [ Validators.required, Validators.minLength(2), Validators.pattern(this._validatorsService.lettersPattern) ] ],
			motherLastname: [ '', [ Validators.required, Validators.minLength(2), Validators.pattern(this._validatorsService.lettersPattern) ] ],
			email: [ '', [ Validators.required, Validators.minLength(6), Validators.pattern(this._validatorsService.emailPattern) ] ],
			password: [ '', [ Validators.required, Validators.minLength(6), Validators.pattern(this._validatorsService.passwordPattern) ] ],
			repeatPassword: [ '', [ Validators.required, Validators.minLength(6), Validators.pattern(this._validatorsService.passwordPattern) ] ]
		}, {
			validators: [
				this._validatorsService.equalFields('password', 'repeatPassword'),
				this._validatorsService.validateRUC('', 'ruc')
			]
		});
	}

	createPersonalAccountForm(): FormGroup {
		return this._formBuilder.group(
				{
					firstName: [
						'',
						[ Validators.required, Validators.minLength(3), Validators.pattern(this._validatorsService.lettersPattern) ]
					],
					fatherLastname: [
						'',
						[ Validators.required, Validators.minLength(2), Validators.pattern(this._validatorsService.lettersPattern) ]
					],
					motherLastname: [
						'',
						[ Validators.required, Validators.minLength(2), Validators.pattern(this._validatorsService.lettersPattern) ]
					],
					email: [
						'',
						[ Validators.required, Validators.minLength(6), Validators.pattern(this._validatorsService.emailPattern) ]
					],
					password: [
						'',
						[ Validators.required, Validators.minLength(6), Validators.pattern(this._validatorsService.passwordPattern) ]
					],
					repeatPassword: [
						'',
						[ Validators.required, Validators.minLength(6), Validators.pattern(this._validatorsService.passwordPattern) ]
					]
				},
				{
					validators: [ this._validatorsService.equalFields('password', 'repeatPassword') ]
				}
		);
	}

	showSocialMedia($event: { index: string | number }) {
		this.socialMedia = $event.index == 0;
	}

	validatePersonalAccountForm(field: string) {
		return this.personalAccountForm.controls[field].errors && this.personalAccountForm.controls[field].touched;
	}

	validateBusinessAccountForm(field: string) {
		return this.businessAccountForm.controls[field].errors && this.businessAccountForm.controls[field].touched;
	}

	saveAccount(): void {
		this.isPerson ? this.savePersonalAccount() : this.saveBusinessAccount();
	}

	saveBusinessAccount(): void {
		if (this.businessAccountForm.invalid) {
			this.businessAccountForm.markAllAsTouched();
			return;
		}

		if (this.businessAccountForm.valid) {
			const parameter = {
				Firstname: this.businessAccountForm.get('firstName')?.value,
				FatherLastname: this.businessAccountForm.get('fatherLastname')?.value,
				MotherLastname: this.businessAccountForm.get('motherLastname')?.value,
				Email: this.businessAccountForm.get('email')?.value,
				Password: this.businessAccountForm.get('password')?.value,
				IsPerson: false,
				Ruc: this.businessAccountForm.get('ruc')?.value,
				BusinessName: this.businessAccountForm.get('businessName')?.value
			};

			this._accountService.saveAccount(parameter).subscribe({
				next: (response) => {
					if (response.Result.IsSuccess) this.activeModal.close(true);
				},
				error: () => this._notification.showNotificacion('Error', 'Error del servidor', 10)
			});
		}
	}

	savePersonalAccount(): void {
		this.initLoading();
		if (this.personalAccountForm.invalid) {
			this.closeLoading();
			this._notification.showNotificacion('Error', 'Error de validación');
			this.personalAccountForm.markAllAsTouched();
			return;
		}
		if (this.personalAccountForm.valid) {
			const parameter = {
				Firstname: this.personalAccountForm.get('firstName')?.value,
				FatherLastname: this.personalAccountForm.get('fatherLastname')?.value,
				MotherLastname: this.personalAccountForm.get('motherLastname')?.value,
				Email: this.personalAccountForm.get('email')?.value,
				Password: this.personalAccountForm.get('password')?.value,
				IsPerson: true,
				Ruc: '',
				BusinessName: ''
			};
			this._accountService.saveAccount(parameter).subscribe({
				next: (response) => {
					this.closeLoading();
					const isSuccess = response.Result.IsSuccess;
					if (isSuccess) {
						this.toggleModalVerificaCorreo();
						this.personalAccountForm.reset();
						this.activeModal.close(true);
					}
				},
				error: () => {
					this.closeLoading();
					this._notification.showNotificacion('Error', 'Error del servidor', 10);
				}
			});
		}
	}

	toggleModalVerificaCorreo() {
		if (this.isBrowser) {
			this._modal.open(CheckEmailComponent, {
				backdrop: false,
				windowClass: 'ModalVerificaCorreo'
			});
		}
	}

	openLoginModal() {
		this.activeModal.close({
			openLogin: true
		});
	}

	goToPolicy() {
		if (this.isBrowser) {
			const url = this._router.serializeUrl(this._router.createUrlTree([ '/politicas' ]));
			window.open(url, '_blank');
		}
	}

	get personalAccountEmailErrorMessage(): string {
		const errors = this.personalAccountForm.get('email')?.errors;
		if (errors?.required) return 'Ingresa tu email';
		else if (errors?.minlength)
			return `Un email válido tiene ${errors?.minlength.requiredLength} caracteres como mínimo.`;
		else if (errors?.pattern) return 'El valor ingresado no tiene formato de email.';
		return '';
	}

	get businessAccountRUCErrorMessage(): string {
		const errors = this.businessAccountForm.get('ruc')?.errors;
		if (errors?.required) return 'Ingresa el número de RUC';
		else if (errors?.minlength) return `Un RUC válido tiene ${errors?.minlength.requiredLength} dígitos.`;
		else if (errors?.notValid) return 'Ingresa un número de RUC válido.';
		return '';
	}

	get businessAccountEmailErrorMessage(): string {
		const errors = this.businessAccountForm.get('email')?.errors;
		if (errors?.required) return 'Ingresa tu email';
		else if (errors?.minlength)
			return `Un email válido tiene ${errors?.minlength.requiredLength} caracteres como mínimo.`;
		else if (errors?.pattern) return 'El valor ingresado no tiene formato de email.';
		return '';
	}
}
