import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { PipesModule } from '../../../../shared/pipes/pipes.module';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [PipesModule, NgIf],
})
export class InfoCardComponent implements OnInit {
  @Input() image = '';
  @Input() flightContent = '';
  @Input() altImage = '';
  constructor() {}

  ngOnInit(): void {}
}
