import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MisReservasService } from 'src/app/private/perfil/mis-reservas-vuelos/mis-reservas-vuelos.service';
import { AccountsService, AuthDTO } from 'src/app/services/user-account/accounts.service';
import { NotificationService } from 'src/app/services/notifications/notification.service';
import { LoaderSubjectService } from 'src/app/shared/components/moleculas/loader/service/loader-subject.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { GoogleSignInUtils } from '../../../../../../shared/utils/google-sign-in-utils';
import { environment } from '../../../../../../../environments/environment';
import { StringUtils } from '../../../../../../shared/utils/string-utils';
import { PlatformService } from '../../../../../../services/platform/platform.service';
import { DEVICES } from '../../../../../../shared/constants';
import { isPlatformBrowser } from '@angular/common';

declare var google: any;

export class LoginPerson {
	constructor(
			public email = '',
			public password = '',
			public recorder = false
	) {
	}
}

export class LoginBusiness {
	constructor(
			public email = '',
			public password = '',
			public recorder = false
	) {
	}
}

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: [ './login.component.scss' ]
})
export class LoginComponent implements OnInit, OnDestroy {
	private _isBrowser = false;

	private isPersonLogin: boolean = true;
	private authStateSubscription = new Subscription();

	private nonce: string;
	private state: string;

	submitBusiness = false;
	submitPerson = false;

	login = new LoginPerson();
	loginB = new LoginBusiness();

	isFromMobile = false;
	isFromIOS = false;

	constructor(
			@Inject(PLATFORM_ID) private _platformId: Object,
			private _accountService: AccountsService,
			private _matSnackBar: MatSnackBar,
			private _notification: NotificationService,
			private _misReservasService: MisReservasService,
			private _loaderSubjectService: LoaderSubjectService,
			private _platformService: PlatformService,
			public activeModal: NgbActiveModal
	) {
		if (isPlatformBrowser(this._platformId))
			this._isBrowser = true;
	}

	ngOnInit(): void {
		if (this._isBrowser) {
			this.isFromMobile = this._platformService.getMobile();
			this.isFromIOS = this._platformService.getDevice() === DEVICES.IOS;
			if (!this.isFromIOS && !this.isFromMobile) this.initializeGoogleSignIn();
			/*this.initializeAppleSignIn();*/
		}
	}

	private initializeGoogleSignIn() {
		google.accounts.id.initialize({
			client_id: environment.googleAccountClientId,
			callback: (response: any) => this.handleGoogleSignIn(response)
		});
		google.accounts.id.renderButton(document.getElementsByClassName('googleSignIn').item(0), {
			type: 'standard',
			theme: 'filled_blue',
			text: 'continue_with',
			size: 'large',
			shape: 'pill'
		});
	}

	private handleGoogleSignIn(response: any) {
		const decoded = GoogleSignInUtils.decodeIdToken(response);
		this.saveSocialAccount(decoded.given_name, decoded.family_name, decoded.email, 'G', decoded.sub, decoded.picture);
	}

	private initializeAppleSignIn() {
		this.nonce = StringUtils.randomString(16);
		this.state = StringUtils.randomString(16);

		const appleClientId: any = environment.appleClientId;
		appleClientId.nonce = this.nonce;
		appleClientId.state = this.state; // Opcional: Estado que quieras mantener en la redirección

		(window as any).AppleID.auth.init(appleClientId);
		(window as any).AppleID.auth.onCredentialRevoked = this.handleAppleCredentialRevoked;

		this.handleSocialSignIn();
	}

	appleSignIn() {
		(window as any).AppleID.auth.signIn().then((response: any) => {
			const { id_token } = response.authorization;
			const decoded: any = GoogleSignInUtils.decodeIdToken({ credential: id_token });
			this.saveSocialAccount(decoded.email.split('@')[0], '', decoded.email, 'A', decoded.sub, '');
		}).catch((error: any) => {
			console.error('Error en el inicio de sesión:', error);
		});
	}

	private handleAppleCredentialRevoked() {
		console.warn('Credenciales revocadas');
	}

	private handleSocialSignIn() {
		this._accountService.saveGoogleAccount.asObservable().subscribe((payload: any) => {
			this.saveSocialAccount(payload.given_name, payload.family_name, payload.email, payload.social, payload.sub, payload.picture);
		});
		this._accountService.saveAppleAccount.asObservable().subscribe((payload: any) => {
			this.saveSocialAccount(payload.email.split('@')[0], '', payload.email, 'A', payload.sub, '');
		});
	}

	private initLoading() {
		const textSend = 'CARGANDO';
		this._loaderSubjectService.showText(textSend);
		this._loaderSubjectService.showLoader();
	}

	private saveSocialAccount(
			Firstname: string,
			FatherLastname: string,
			Email: string,
			SocialNetwork: 'G' | 'A',
			IdSocialNetwork: string,
			image: string
	) {
		this.initLoading();
		const payload: any = {
			Firstname,
			FatherLastname,
			MotherLastname: '',
			Email,
			Password: '',
			IsPerson: true,
			Ruc: '',
			BusinessName: '',
			SocialNetwork,
			IdSocialNetwork
		};
		this._accountService.saveAccount(payload).subscribe({
			next: (response) => {
				this.closeLoading();
				if (response.Result.IsSuccess) {
					this._accountService.guardarStorage(response.Result, image, SocialNetwork);
					this.activeModal.close({
						loggedIn: true,
						redirect: null
					});
				}
			},
			error: () => {
				this.closeLoading();
				this._notification.showNotificacion('Error', 'Error del servidor', 10);
			}
		});
	}

	openNewAccount() {
		this.activeModal.close({
			isLoggedIn: false,
			redirect: 'NEW_ACCOUNT'
		});
	}

	openForgotPassword() {
		this.activeModal.close({
			isLoggedIn: false,
			redirect: 'FORGOT_PASSWORD'
		});
	}

	private getAllBookings(usuario: AuthDTO) {
		this._misReservasService.getAllBooking(usuario.Id.toString()).subscribe((data) => {
			if (this._isBrowser)
				localStorage.setItem('bookings', JSON.stringify(data));
		});
	}

	signIn(formPerson: NgForm, fromBusiness: NgForm) {
		const validPerson = this.validationFormLogin(formPerson);
		const validBusiness = this.validationFormLogin(fromBusiness);

		this.initLoading();

		if (this.isPersonLogin && validPerson) {
			this._accountService.signIn(this.login, this.isPersonLogin).subscribe(resp => {
				if (resp.IsSuccess) {
					this._accountService.guardarStorage(resp);
					this.getAllBookings(resp);
					this.activeModal.close({
						loggedIn: true,
						redirect: null
					});
				} else this._notification.showNotificacion('Error', resp.Message, 10);
			}, () => {
				this._notification.showNotificacion('Error', 'Error de autenticación', 10);
				this.closeLoading();
			}, () => this.closeLoading());
		} else if (!this.isPersonLogin && validBusiness)
			this._accountService.signIn(this.loginB, this.isPersonLogin).subscribe(resp => {
				if (resp.IsSuccess) {
					this._accountService.guardarStorage(resp);
					this.getAllBookings(resp);
					this.activeModal.close({
						loggedIn: true,
						redirect: null
					});
				}
			}, () => {
				this._notification.showNotificacion('Error', 'Error de validación', 5);
				this.closeLoading();
			}, () => this.closeLoading());
		else {
			if (!validPerson && this.isPersonLogin)
				this.submitPerson = true;
			else if (!validBusiness && !this.isPersonLogin)
				this.submitBusiness = true;
			else
				this.openSnackBar('Ocurrió un error');
			this._notification.showNotificacion('Error', 'Error de validación', 5);
			this.closeLoading();
		}
	}

	private validationFormLogin(form: NgForm) {
		return !form.invalid;
	}

	private closeLoading() {
		this._loaderSubjectService.closeLoader();
	}

	private openSnackBar(message: string) {
		this._matSnackBar.open(message, '', {
			duration: 2000,
			panelClass: [ 'mat-toolbar', 'mat-warn' ]
		});
	}

	togglePassword(pass: HTMLInputElement) {
		pass.type = pass.type == 'password' ? 'text' : 'password';
	}

	showSocialMediaLogin($event: { index: string | number; }) {
		this.isPersonLogin = $event.index == 0;
	}

	ngOnDestroy() {
		this.authStateSubscription.unsubscribe();
	}
}
