import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { interval, Subject, Subscription } from 'rxjs';
import { filter, takeUntil, takeWhile } from 'rxjs/operators';
import { INotificationModel } from '../../../../public/Models/notifications/notification.model';
import { AccountsService, UserStorage } from '../../../../services/accounts.service';
import { CookiesService } from '../../../../services/cookies/cookies.service';
import { CookiesNamesEnum } from '../../../../services/cookies/enums/cookies-names.enum';
import { FileService } from '../../../../services/file.service';
import { PlatformService, ToolbarService } from '../../../services';
import { DEVICES } from '../../../constants';
import { HomeService } from '../../../../public/pages/home/services';
import {
  MobileComponent,
  NotificationsComponent,
  ForgotPasswordComponent,
  LoginComponent,
  NewAccountComponent,
} from './components';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  @ViewChild('dropdown') dropdownEl: ElementRef;
  @ViewChild('notifications') notificationsEl: ElementRef;

  showAdvertisement = true;
  advertisementContent: string;
  isBookingPage = false;
  isResultsPage = false;
  showsNotifications = false;
  notificationsList: INotificationModel[] = [];
  isFromIOS = false;

  isLoggedIn = false;
  userStorage: UserStorage;
  showsProfileOptions = false;
  unsubscribe$ = new Subject<any>();

  protected readonly isBrowser = isPlatformBrowser(this.platformId);
  private readonly destroyModal$ = new Subject<void>();
  private _notificationsSubscription = new Subscription();
  private _showsLoginModalSubscription = new Subscription();
  private intervalSubscription = new Subscription();

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly router: Router,
    private readonly cookiesService: CookiesService,
    private readonly platformService: PlatformService,
    private readonly accountsService: AccountsService,
    private readonly fileService: FileService,
    private readonly modalService: NgbModal,
    private readonly bottomSheet: MatBottomSheet,
    private readonly toolbarService: ToolbarService,
    private readonly homeService: HomeService,
    private readonly offCanvasService: NgbOffcanvas
  ) {
    this.isBookingPage = this.router.url.includes('booking');
    if (this.isBrowser) this.isFromIOS = this.platformService.getDevice() === DEVICES.IOS;
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((event: any) => {
        this.isBookingPage = event.url.toString().includes('booking');
        this.isResultsPage = event.url.toString().includes('resultados');
      });

    this.getAdvertisement();
    this.validateShowAd();
    this.loginValidation();
    this.subscribeToLoginModal();
  }

  private getAdvertisement() {
    this.homeService
      .getAdvertisement()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (data) => {
          if (
            data?.result?.advertisementContent &&
            data?.result?.startDate &&
            data?.result?.endDate
          ) {
            const startDate = new Date(data.result.startDate);
            const endDate = new Date(data.result.endDate);
            const currentDate = new Date();

            if (currentDate >= startDate && currentDate <= endDate) {
              this.advertisementContent = data.result.advertisementContent;
              this.emitAdContent(this.advertisementContent);
              if (this.isBrowser) {
                this.intervalSubscription = interval(1000)
                  .pipe(
                    takeWhile(() => {
                      const now = new Date();
                      if (now > endDate) {
                        this.advertisementContent = '';
                        this.emitAdContent(this.advertisementContent);
                        return false;
                      }
                      return true;
                    })
                  )
                  .subscribe();
              }
              return;
            }
          }
          this.emitAdContent('');
        },
        error: () => this.emitAdContent(''),
      });
  }

  private validateShowAd() {
    this.toolbarService.showAdvertisement$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => (this.showAdvertisement = value));
  }

  private emitAdContent(content: string) {
    this.toolbarService.advertisementContent$.next(content);
  }

  private subscribeToLoginModal() {
    this._showsLoginModalSubscription = this.toolbarService.loginModal$.subscribe((opens) => {
      if (opens) this.openLoginModal();
    });
  }

  clickMobileMenu() {
    const offCanvasRef = this.offCanvasService.open(MobileComponent, {
      animation: true,
      panelClass: 'bg-white',
    });
    offCanvasRef.componentInstance.isLogged = this.isLoggedIn;
    offCanvasRef.closed.pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
      if (result?.signIn) this.logout();
      else this.openLoginModal();
    });
  }

  private loginValidation() {
    this.accountsService.isLogged().subscribe((logged) => {
      this.isLoggedIn = logged;
      if (this.isBrowser && this.isLoggedIn) {
        this.userStorage = this.accountsService.getUserStorage();
        if (!this.userStorage.image) this.downloadImage(this.userStorage);
        this.userStorage = this.accountsService.getUserStorage();
      }
    });
  }

  private downloadImage(user: UserStorage) {
    this.fileService.getImage(user.id).subscribe((img) => {
      if (this.isBrowser) {
        this.userStorage.image = img;
        this.accountsService.guardarImage(img);
      }
    });
  }

  @HostListener('document:click', ['$event'])
  blurRange(event: MouseEvent) {
    if (this.isBrowser) {
      if (this.dropdownEl && !this.dropdownEl.nativeElement.contains(event.target))
        this.showsProfileOptions = false;
      if (this.notificationsEl && !this.notificationsEl.nativeElement.contains(event.target))
        this.showsNotifications = false;
    }
  }

  openLoginModal() {
    if (this.isBrowser) {
      this.offCanvasService.dismiss();
      const loginModalRef = this.modalService.open(LoginComponent, {
        backdrop: 'static',
      });
      loginModalRef.closed.pipe(takeUntil(this.destroyModal$)).subscribe((result: any) => {
        if (this.isBrowser) {
          history.pushState('', document.title, window.location.pathname + location.search);
          if (result && !result.isLoggedIn && result.redirect)
            if (result.redirect === 'NEW_ACCOUNT') this.openNewAccountModal();
            else if (result.redirect === 'FORGOT_PASSWORD') this.openForgotPasswordModal();
        }
      });
    }
  }

  private openNewAccountModal() {
    if (this.isBrowser) {
      const newAccountModalRef = this.modalService.open(NewAccountComponent, {
        backdrop: 'static',
        windowClass: 'new-account-modal',
      });
      newAccountModalRef.closed.pipe(takeUntil(this.destroyModal$)).subscribe((result: any) => {
        if (result?.openLogin) this.openLoginModal();
      });
    }
  }

  openForgotPasswordModal() {
    if (this.isBrowser) {
      const forgotPasswordModalRef = this.modalService.open(ForgotPasswordComponent, {
        windowClass: 'forgot-password-modal',
        centered: true,
      });
      forgotPasswordModalRef.closed.pipe(takeUntil(this.destroyModal$)).subscribe((result: any) => {
        if (result?.openLogin) this.openLoginModal();
      });
    }
  }

  openNotificationsMobile() {
    if (this.isBrowser) {
      const bottomSheetRef = this.bottomSheet.open(NotificationsComponent, {
        data: { notificationList: this.notificationsList },
      });

      this._notificationsSubscription = bottomSheetRef
        .afterDismissed()
        .subscribe((reason: { notification: INotificationModel }) => {
          if (!reason?.notification) return;
          this.redirectFromNotification(
            reason.notification.url,
            this.notificationsList.findIndex((notification) => notification === reason.notification)
          );
        });
    }
  }

  redirectFromNotification(url: string, index: number) {
    this.removeNotificationByIndex(index);
    this.router.navigateByUrl(url).then(() => null);
  }

  private removeNotificationByIndex(index: number) {
    this.notificationsList.splice(index, 1);
    this.cookiesService.setCookie(
      CookiesNamesEnum.NOTIFICATIONS,
      JSON.stringify(this.notificationsList)
    );
  }

  logout() {
    this.accountsService.signOut();
    this.showsProfileOptions = false;
    this.offCanvasService.dismiss();
    this.router.navigateByUrl('/').then(() => null);
  }

  ngOnDestroy() {
    this.destroyModal$.next();
    this.destroyModal$.complete();
    this._notificationsSubscription.unsubscribe();
    this._showsLoginModalSubscription.unsubscribe();
    this.intervalSubscription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
