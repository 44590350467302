import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-link-button',
  templateUrl: './link-button.component.html',
  styleUrls: ['./link-button.component.scss']
})
export class LinkButtonComponent implements OnInit {
  @Input() btnClass = '';
  @Input() btnBlock = false;
  @Input() btnId = '';
  @Input() disabled = false;
  @Input() label = '';
  @Input() link: string;
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() hrefUrl = '';
  @Input() target: string;
  @Output() btnClick = new EventEmitter<MouseEvent>();

  constructor() { }

  ngOnInit(): void {
  }

}
