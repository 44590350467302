import {
  CommonModule,
  isPlatformBrowser,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { initializeAppCheck, provideAppCheck, ReCaptchaV3Provider } from '@angular/fire/app-check';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CryptoService } from './services/util/crypto.service';
import { NotificationModule } from './shared/components/moleculas/notification/notification.module';
import { PageNotFoundComponent } from './shared/components/moleculas/page-not-found/page-not-found.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToolbarModule } from './shared/components';
import { RouterOutlet } from '@angular/router';
import { HomeModule } from './public/pages/home';
import { LoadingModule } from './shared/components/moleculas/loading/loading.module';
import { AdvertisementModalModule } from './shared/components/moleculas/modals';
import { FooterModule } from './shared/components/layout/footer/footer.module';
import { PreFooterModule } from './shared/components/moleculas/pre-footer/pre-footer.module';
import { FooterCheckoutModule } from './shared/components/layout/footer-checkout/footer-checkout.module';
import { CitaModule } from './public/pages/cita/cita.module';
import { ResultadosModule } from './public/pages/resultados/resultados.module';
import { ConformidadModule } from './public/pages/conformidad/conformidad.module';
import { TerminosCondicionesModule } from './public/pages/terminos-condiciones/terminos-condiciones.module';
import { DocumentacionViajeModule } from './public/pages/documentacion-viaje/documentacion-viaje.module';
import { DestinosModule } from './public/pages/vuelos/commons/components/destinos/destinos.module';
import { TusDatosModule } from './public/pages/tus-datos/tus-datos.module';
import { AerolineasModule } from './public/pages/aerolineas/aerolineas.module';
import { CierrapuertasModule } from './public/pages/cierrapuertas/cierrapuertas.module';
import { CierrapuertasOfertasModule } from './public/pages/cierrapuertas-ofertas/cierrapuertas-ofertas.module';
import { LibroReclamacionesModule } from './public/pages/libro-reclamaciones/libro-reclamaciones.module';
import { RetailModule } from './public/pages/retail/retail.module';
import { NuestrasAgenciasModule } from './public/pages/nuestras-agencias/nuestras-agencias.module';
import { NuestraEmpresaModule } from './public/pages/nuestra-empresa/nuestra-empresa.module';
import { PoliticasModule } from './public/pages/politicas/politicas.module';
import { CondicionesDeReservaModule } from './public/pages/condiciones-de-reserva/condiciones-de-reserva.module';
import { ResponsabilidadSocialModule } from './public/pages/resonsabilidad-social/responsabilidad-social.module';
import { OffersModule } from './public/pages/offers/offers.module';
import { GlobalHttpInterceptorService } from './core/interceptors/globalHttpInterceptorService.service';
import { LoadingInterceptor } from './core/interceptors/loading.interceptor';
import { PlatformInterceptor } from './core/interceptors/platform.interceptor';
import { CanActivateCheckoutGuard } from './shared/guards/checkout.guard';
import { DeactivatePaymentGuard } from './shared/guards/payment.guard';
import { MotorVuelosInterceptor } from './core/interceptors/motor-vuelos.interceptor';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent, PageNotFoundComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideAppCheck(() =>
      initializeAppCheck(getApp(), {
        provider: new ReCaptchaV3Provider(environment.reCaptchaPublicKey),
        isTokenAutoRefreshEnabled: true,
      })
    ),
    NotificationModule,
    LoadingModule,
    HttpClientModule,
    NgbTooltipModule,
    AdvertisementModalModule,
    FooterModule,
    PreFooterModule,
    CommonModule,
    RouterOutlet,
    FooterCheckoutModule,
    ToolbarModule,
    CitaModule,
    HomeModule,
    ResultadosModule,
    ConformidadModule,
    TerminosCondicionesModule,
    DocumentacionViajeModule,
    DestinosModule,
    TusDatosModule,
    AerolineasModule,
    CierrapuertasModule,
    CierrapuertasOfertasModule,
    LibroReclamacionesModule,
    RetailModule,
    NuestrasAgenciasModule,
    NuestraEmpresaModule,
    PoliticasModule,
    CondicionesDeReservaModule,
    ResponsabilidadSocialModule,
    OffersModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: PlatformInterceptor, multi: true },
    CryptoService,
    CanActivateCheckoutGuard,
    DeactivatePaymentGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MotorVuelosInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(@Inject(PLATFORM_ID) private readonly platformId: Object) {
    if (isPlatformBrowser(this.platformId) && !environment.production)
      (window as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  }
}
