import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookiesConfigComponent } from './cookies-config.component';
import { CookiesModalComponent } from './modal/cookies-modal.component';
import {
  ButtonComponent,
  ActionButtonsComponent,
  ToggleCardComponent,
} from '../../../shared/components';

@NgModule({
  declarations: [CookiesConfigComponent, CookiesModalComponent],
  exports: [CookiesConfigComponent],
  imports: [
    CommonModule,
    ActionButtonsComponent,
    ToggleCardComponent,
    ButtonComponent,
    ToggleCardComponent,
  ],
})
export class CookiesConfigModule {}
