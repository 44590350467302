import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ToolbarService {
	private _loginModal = new BehaviorSubject<boolean>(false);
	loginModal$ = this._loginModal.asObservable();

	advertisementContent$ = new BehaviorSubject<string>('');
	statusShowAdvertisement$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor() {}

	openLoginModal() {
		this._loginModal.next(true);
	}
}
