import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewContainerRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { ExchangeRate } from '../../../../api/api-checkout/models/rq-checkout-search';
import { TokenService } from '../../../../api/api-nmviajes/services/token.service';
import { DynamicPackagesService } from './services/dynamic-packages.service';
import { BreakpointService } from '../../../../services/breakpoints';
import { IDynamicPackage } from './models/packages-response.model';
import { Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-package-section',
  templateUrl: './package-section.component.html',
  styleUrls: ['./package-section.component.scss'],
})
export class PackageSectionComponent implements OnInit, OnDestroy {
  @Input() showSortByPriceDropdown: boolean = false;
  @Input() destinations: string = '';
  @Input() mobileItems: number = 1;
  @Input() desktopItems: number = 3;
  @Input() maxPackages: number = 6;
  @Input() cardVersion: 1 | 2 = 2;
  @Input() showToggler: boolean = true;
  @Input() scrollTargetId?: string;

  protected readonly isBrowser = isPlatformBrowser(this.platformId);
  private readonly destroy$ = new Subject<void>();

  private exchangeRate: ExchangeRate | null = null;
  private isMobile: boolean = false;

  isLoading = false;
  visibleItems = this.mobileItems;
  packageList: IDynamicPackage[] = [];
  filterByPriceAmount?: boolean;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly dynamicPackagesService: DynamicPackagesService,
    private readonly tokenService: TokenService,
    private readonly breakpointService: BreakpointService,
    private readonly viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    if (!this.tokenService.exchangeRate) this.getToken();
    this.observeScreenSize();
    this.getExchangeRate();
    this.getDynamicPackages();
  }

  private getToken() {
    this.tokenService.getAndSaveToken('Chrome').pipe(take(1)).subscribe();
  }

  private observeScreenSize() {
    this.breakpointService
      .isBreakpoint(Breakpoints.Handset)
      .pipe(takeUntil(this.destroy$))
      .subscribe((matches) => {
        this.isMobile = matches;
        this.updateVisibleItems();
      });
  }

  private updateVisibleItems() {
    this.visibleItems = this.isMobile ? this.mobileItems : this.desktopItems;
  }

  private getExchangeRate() {
    this.tokenService.exchangeRate$.pipe(takeUntil(this.destroy$)).subscribe((rate) => {
      this.exchangeRate = rate;
      this.packageList.forEach((item) => (item.TotalPrice.CurrencyExchange = rate));
    });
  }

  onFilterChange(filter: string) {
    switch (filter) {
      case 'true':
        this.filterByPriceAmount = true;
        break;
      case 'false':
        this.filterByPriceAmount = false;
        break;
      default:
        this.filterByPriceAmount = undefined;
        break;
    }

    this.getDynamicPackages();
  }

  private getDynamicPackages() {
    this.isLoading = true;
    this.dynamicPackagesService
      .getPackages(0, this.maxPackages, this.destinations, this.filterByPriceAmount)
      .pipe(
        take(1),
        finalize(() => {
          if (this.isBrowser) setTimeout(() => (this.isLoading = false), 800);
        })
      )
      .subscribe({
        next: (response) => {
          this.packageList =
            response?.Result?.package?.map(this.addExchangeRateToPackage.bind(this)) || [];
        },
        error: (err) => console.error('Error retrieving packages:', err),
      });
  }

  toggleVisibility() {
    const allItemsVisible = this.visibleItems >= this.packageList.length;
    const defaultVisibleItems = this.isMobile ? this.mobileItems : this.desktopItems;

    if (allItemsVisible) {
      this.visibleItems = defaultVisibleItems;
      if (this.scrollTargetId) this.scrollToTarget();
      return;
    }

    this.visibleItems =
      this.isMobile && this.visibleItems < this.desktopItems
        ? this.desktopItems
        : this.packageList.length;
  }

  getTogglerLabel(): string {
    return this.visibleItems === this.packageList.length ? 'Ver menos' : 'Ver más paquetes';
  }

  getPlaceHolderItems(): number[] {
    return Array.from({ length: this.visibleItems });
  }

  private addExchangeRateToPackage(item: IDynamicPackage) {
    return {
      ...item,
      TotalPrice: {
        ...item.TotalPrice,
        CurrencyExchange: this.exchangeRate,
      },
    };
  }

  private scrollToTarget() {
    const parentElement = this.viewContainerRef.element.nativeElement.parentElement;
    const targetElement = parentElement?.querySelector(`#${this.scrollTargetId}`);

    if (targetElement) {
      const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY;
      const toolbarOffset = 70;

      window.scrollTo({ top: elementPosition - toolbarOffset, behavior: 'smooth' });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
