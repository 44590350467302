import { Component, HostListener, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PricingDetail } from 'src/app/api/api-checkout/models/rq-checkout-search';
import { FareBreakDown, InformationService, IUpSell } from 'src/app/api/api-checkout/models/rq-checkout-up-sell';
import { GlobalComponent } from 'src/app/shared/global';
import { isPlatformBrowser } from '@angular/common';
import { NguCarouselConfig } from '@ngu/carousel';
import { UpsellService } from '../../../../services/upsell/upsell.service';
import { ConfigCatService } from '../../../../services/labs/config-cat.service';

@Component({
	selector: 'modal-fee',
	templateUrl: './modal-fee.component.html',
	styleUrls: [ './modal-fee.component.scss' ],
	encapsulation: ViewEncapsulation.None
})
export class ModalFeeComponent implements OnInit {
	dataUpSell: IUpSell[] = GlobalComponent.upSellGroup;
	indexCardSelect = 0;

	isDomestic = GlobalComponent.isDomestic;
	isBrowser: boolean = false;

	pricingDetail: PricingDetail;
	getScreenWidth = window.innerWidth;

	carouselConfig: NguCarouselConfig = {
		grid: { xs: 1, sm: 1, md: 2, lg: 3, xl: 3, all: 0 },
		speed: 250,
		point: { visible: false },
		touch: true,
		animation: 'lazy'
	};

	constructor(public activeModal: NgbActiveModal,
							@Inject(PLATFORM_ID) private platformId: Object,
							private router: Router,
							private _upsellService: UpsellService,
							private _configCatService: ConfigCatService) {
	}

	ngOnInit(): void {
		if (isPlatformBrowser(this.platformId)) {
			this.isBrowser = true;
		}
		this.indexCardSelect = this._upsellService.getSelectedUpsell()?.index ?? 0;
		this.pricingDetail = GlobalComponent.detailPricing;
		const dataUpSell = GlobalComponent.upSellGroup.map((item, index) => {
			item.index = index;
			item.totalPay = this.getTotalByUpSell(item.fareBreakDowns);
			item.dataBags = [];
			item.dataExtras = [];
			item.informationServices?.forEach(service => {
				service.isBag = this.conditionIncludeBag(service);
				if (service.isBag && service.itsInclude && item.dataBags) item.dataBags.push(service);
				if (!service.isBag && service.itsInclude && item.dataExtras) item.dataExtras.push(service);
			});
			return item;
		});

		this.dataUpSell = [ ...dataUpSell ].sort((a, b) => {
			if (a.totalPay && b.totalPay) return a.totalPay - b.totalPay;
			if (!a.totalPay) return 1;
			if (!b.totalPay) return -1;
			return 0;
		});
	}

	getTotalByUpSell(fareBreakDowns: FareBreakDown[]) {
		let baseFareTotal = 0;
		let taxesTotal = 0;
		fareBreakDowns.forEach((item: FareBreakDown) => {
			baseFareTotal += item.baseFare * item.quantity;
			taxesTotal += item.taxes.reduce(function(acc, obj) {
				return acc + obj.fareAmount;
			}, 0) * item.quantity;
		});
		return baseFareTotal + taxesTotal + this.pricingDetail.feeNMV + this.pricingDetail.feePTA;
	}

	conditionIncludeBag(elemento: InformationService) {
		const word = elemento.description?.toLocaleLowerCase() || '';
		return word.includes('bag') || word.includes('baggage') || word.includes('equipaje') || word.includes('mano') || word.includes('carry') || word.includes('upto') || word.includes('bodega');
	}

	clickSelectUpSell(item: IUpSell) {
		const route = this.router.routerState.snapshot.url;
		this._upsellService.setSelectedUpsell(item);
		if (route.includes('resultados')) {
			GlobalComponent.appBooking.brandedFareName = item.name;
			this.router.navigateByUrl(this._configCatService.getBookingUrl()).then(() => null);
		}
		this.activeModal.close(item);
	}

	@HostListener('window:resize', [ '$event' ])
	onResize() {
		if (this.isBrowser) {
			if (this.getScreenWidth !== window.innerWidth)
				this.getScreenWidth = window.innerWidth;
		}
	}
}
