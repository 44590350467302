import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat-card',
  templateUrl: './chat-card.component.html',
  styleUrls: ['./chat-card.component.scss'],
})
export class ChatCardComponent implements OnInit {
  urlEncodedText: string;

  ngOnInit(): void {
    this.urlEncodedText = encodeURIComponent('Hola, ayúdame a cotizar un pasaje aéreo');
  }
}
