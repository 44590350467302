import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

interface IItemWithExpiration {
	value: any,
	expirationDate: number
}

@Injectable({
	providedIn: 'root'
})
export class StorageService {
	protected _browser = false;

	constructor(@Inject(PLATFORM_ID) private _platformId: Object) {
		if (isPlatformBrowser(this._platformId)) this._browser = true;
	}

	setLocalItemWithExpiration(key: string, value: any, seconds: number) {
		if (this._browser) {
			const expirationDate = new Date();
			expirationDate.setSeconds(expirationDate.getSeconds() + seconds);
			const item: IItemWithExpiration = {
				value: value,
				expirationDate: expirationDate.getTime()
			};
			localStorage.setItem(key, JSON.stringify(item));
		}
	}

	getLocalItemWithExpiration(key: string): any {
		if (this._browser) {
			const itemStr = localStorage.getItem(key);

			if (!itemStr) return null;

			const item = JSON.parse(itemStr) as IItemWithExpiration;
			const now = new Date();

			if (now.getTime() > item.expirationDate) {
				localStorage.removeItem(key);
				return null;
			}

			return item.value;
		}

		return null;
	}
}
