import { CommonModule, isPlatformBrowser, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { initializeAppCheck, provideAppCheck, ReCaptchaV3Provider } from '@angular/fire/app-check';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoadingModule } from './components/loading/loading.module';
import { CanActivateCheckoutGuard } from './Guards/checkout.guard';
import { DeactivatePaymentGuard } from './Guards/payment.guard';
import { GlobalHttpInterceptorService } from './interceptors/globalHttpInterceptorService.service';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { MotorVuelosInterceptor } from './interceptors/motor-vuelos.interceptor';
import { PlatformInterceptor } from './interceptors/platform.interceptor';
import { CryptoService } from './Services/util/crypto.service';
import { NotificationModule } from './shared/components/notification/notification.module';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AdvertisementModalModule } from './shared/components/modals';
import { ToolbarModule } from './shared/components';
import { FooterCheckoutModule } from './shared/components/footer-checkout/footer-checkout.module';
import { FooterModule } from './shared/components/footer/footer.module';
import { PreFooterModule } from './shared/components/pre-footer/pre-footer.module';
import { RouterOutlet } from '@angular/router';
import { CitaModule } from './Component/home-page/cita/cita.module';
import { HomeModule } from './features/home';
import { ResultadosModule } from './Component/home-page/resultados/resultados.module';
import { ConformidadModule } from './Component/home-page/conformidad/conformidad.module';
import { TerminosCondicionesModule } from './Component/home-page/terminos-condiciones/terminos-condiciones.module';
import { DocumentacionViajeModule } from './Component/home-page/documentacion-viaje/documentacion-viaje.module';
import { DestinosModule } from './Component/home-page/vuelos/commons/components/destinos/destinos.module';
import { TusDatosModule } from './Component/home-page/tus-datos/tus-datos.module';
import { AerolineasModule } from './Component/home-page/aerolineas/aerolineas.module';
import { CierrapuertasModule } from './Component/home-page/cierrapuertas/cierrapuertas.module';
import { CierrapuertasOfertasModule } from './Component/home-page/cierrapuertas-ofertas/cierrapuertas-ofertas.module';
import { LibroReclamacionesModule } from './Component/home-page/libro-reclamaciones/libro-reclamaciones.module';
import { RetailModule } from './Component/home-page/retail/retail.module';
import { NuestrasAgenciasModule } from './Component/home-page/nuestras-agencias/nuestras-agencias.module';
import { NuestraEmpresaModule } from './Component/home-page/nuestra-empresa/nuestra-empresa.module';
import { PoliticasModule } from './Component/home-page/politicas/politicas.module';
import { CondicionesDeReservaModule } from './Component/home-page/condiciones-de-reserva/condiciones-de-reserva.module';
import { ResponsabilidadSocialModule } from './Component/home-page/resonsabilidad-social/responsabilidad-social.module';
import { OffersModule } from './Component/home-page/offers/offers.module';

@NgModule({
	schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
	declarations: [ AppComponent, PageNotFoundComponent ],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		AppRoutingModule,
		BrowserAnimationsModule,
		provideFirebaseApp(() => initializeApp(environment.firebase)),
		provideFirestore(() => getFirestore()),
		provideAppCheck(() =>
				initializeAppCheck(getApp(), {
					provider: new ReCaptchaV3Provider(environment.reCaptchaPublicKey),
					isTokenAutoRefreshEnabled: true
				})
		),
		NotificationModule,
		LoadingModule,
		HttpClientModule,
		NgbTooltipModule,
		AdvertisementModalModule,
		FooterModule,
		PreFooterModule,
		CommonModule,
		RouterOutlet,
		FooterCheckoutModule,
		ToolbarModule,
		CitaModule,
		HomeModule,
		ResultadosModule,
		ConformidadModule,
		TerminosCondicionesModule,
		DocumentacionViajeModule,
		DestinosModule,
		TusDatosModule,
		AerolineasModule,
		CierrapuertasModule,
		CierrapuertasOfertasModule,
		LibroReclamacionesModule,
		RetailModule,
		NuestrasAgenciasModule,
		NuestraEmpresaModule,
		PoliticasModule,
		CondicionesDeReservaModule,
		ResponsabilidadSocialModule,
		OffersModule
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
		{ provide: HTTP_INTERCEPTORS, useClass: PlatformInterceptor, multi: true },
		CryptoService,
		CanActivateCheckoutGuard,
		DeactivatePaymentGuard,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MotorVuelosInterceptor,
			multi: true
		}
	],
	bootstrap: [ AppComponent ]
})
export class AppModule {

	constructor(@Inject(PLATFORM_ID) private platformId: Object) {
		if (isPlatformBrowser(this.platformId) && !environment.production)
			(window as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
	}
}
