import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatLauncherComponent } from './chat-launcher.component';
import { ChevronToggleComponent } from '../../../shared/components';
import { ChatCardComponent } from './components/chat-card.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [ChatLauncherComponent, ChatCardComponent],
  imports: [CommonModule, ChevronToggleComponent, NgbTooltipModule],
  exports: [ChatLauncherComponent],
})
export class ChatLauncherModule {}
