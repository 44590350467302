import { Injectable } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { CookiesConfigScripts } from './constants/cookies-config-scripts.constant';
import { ScriptLoaderService } from '../scripts';
import { StorageService } from '../storage';
import { StorageKeysEnum } from '../../shared/constants';
import { GoogleSignInService, GoogleTagManagerService } from '../google';
import { CookieConfig } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class CookiesService {
  constructor(
    private readonly ssrCookiesService: SsrCookieService,
    private readonly scriptLoaderService: ScriptLoaderService,
    private readonly googleSignInService: GoogleSignInService,
    private readonly gtmService: GoogleTagManagerService,
    private readonly storageService: StorageService
  ) {}

  set(name: string, value: string, expires?: number, path: string = '/') {
    this.ssrCookiesService.set(name, value, expires, path, undefined, true);
  }

  get(name: string, parse: boolean = false): any {
    const cookieValue = this.ssrCookiesService.get(name);
    if (parse) {
      try {
        return JSON.parse(cookieValue);
      } catch (error) {
        console.warn(`Cookie "${name}" no tiene un formato JSON válido.`);
        return null;
      }
    }
    return cookieValue;
  }

  check(name: string): boolean {
    return this.ssrCookiesService.check(name);
  }

  delete(name: string, path: string = '/') {
    this.ssrCookiesService.delete(name, path, undefined, true);
  }

  configCookies(config: CookieConfig) {
    if (config.all)
      config = {
        ...config,
        functional: true,
        technical: true,
        advertisement: true,
        analytics: true,
        necessary: true,
      };

    this.storageService.setItem<CookieConfig>(StorageKeysEnum.COOKIES_CONFIG, config);

    if (config.technical) this.setTechnicalCookies();

    this.gtmService.setConsent();
  }

  private setTechnicalCookies() {
    for (const script of CookiesConfigScripts.TECHNICAL_COOKIES) {
      this.scriptLoaderService.addScriptElement(script);
    }

    this.googleSignInService.checkGoogle();
  }
}
