import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLinkWithHref } from '@angular/router';

@Component({
  imports: [CommonModule, RouterLinkWithHref],
  selector: 'app-button',
  standalone: true,
  templateUrl: './button.component.html',
})
export class ButtonComponent implements OnInit {
  @Input() buttonClass = '';
  @Input() isFullWidth = false;
  @Input() buttonId = '';
  @Input() isDisabled = false;
  @Input() buttonHierarchy: 'primary' | 'secondary' | 'tertiary' = 'primary';
  @Input() navigationLink: string | null = null; // For routerLink
  @Input() externalHref: string | null = null; // For external links
  @Input() target: string | null = '_self';
  @Input() buttonType: 'button' | 'submit' = 'button';
  @Input() buttonSize: 'sm' | 'md' | 'lg' = 'md';
  @Input() buttonStyle: 'filled' | 'outline' | 'link' = 'filled';
  @Input() isLoading = false;
  @Output() buttonClick = new EventEmitter<MouseEvent>();

  buttonClassByType = '';

  ngOnInit() {
    this.getButtonClassByType();
  }

  // Gets button class by styleType and hierarchy
  private getButtonClassByType() {
    if (this.buttonStyle === 'link') {
      this.buttonClassByType = 'btn-link';
      return;
    }

    const baseClass = this.buttonStyle === 'filled' ? 'btn-' : 'btn-outline-';
    this.buttonClassByType = `${baseClass}${this.buttonHierarchy}`;
  }
}
