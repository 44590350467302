import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseModelT } from 'src/app/shared/models';
import { Guid, NmvModel } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
import { EnumRequestApplications, EnumRequestCompanies } from '../../../../../../../api/api-correos/models';
import { PlatformService } from '../../../../../../../shared/services';
import {
	IConfigurationsResponse,
	IDestinationDetails,
	IDestinos,
	IIataDetail,
	IPopularRoutes,
	ISeoFaq
} from '../destino.models';

@Injectable({
	providedIn: 'root'
})
export class DestinosService {
	private paramCiudad = new BehaviorSubject<string>('');

	constructor(
		private httpClient: HttpClient,
		private platformService: PlatformService
	) {}

	setParam(path: string) {
		const pathArray = path.split('/');

		const indiceDestino = pathArray.indexOf('destino');

		if (indiceDestino === -1) {
			this.paramCiudad.next('');
		} else {
			const codigoCiudad = pathArray[indiceDestino + 2];

			this.paramCiudad.next(codigoCiudad);
		}
	}

	getParam() {
		return this.paramCiudad.asObservable();
	}

	getVuelos(codeDestination: string, codeOrigin: string = 'LIM', type: string = 'A') {
		const nmvModel = new NmvModel();

		const options = {
			params: nmvModel.params
				.set('Parameter.CodeDestination', codeDestination)
				.set('Parameter.CodeOrigin', codeOrigin)
				.set('Parameter.Type', type)
		};

		const url = environment.urlNmviajes + '/Flight/GetLastSearchesByCity';

		return this.httpClient.get<ResponseModelT<IDestinos[]>>(url, options).pipe(map((resp) => resp.Result));
	}

	getDestinationBySlug(slug: string) {
		const url = environment.nmConfigurations + '/v1/api/Webseo/GetWebseoBySlug/' + slug;

		return this.httpClient.get<IConfigurationsResponse<IIataDetail>>(url).pipe(map((resp) => resp?.result ?? null));
	}

	getDestinationByIata(originIata: string, iata: string) {
		const url = environment.nmConfigurations + '/v1/api/Webseo/GetWebseoByIata';

		const body = {
			trackingCode: Guid(),
			muteExceptions: true,
			caller: {
				company: EnumRequestCompanies.EXPERTIA,
				application: EnumRequestApplications.NMVIAJES,
				deviceId: this.platformService.getDevice().toString(),
				fromIP: '10.10.10.10',
				fromBrowser: 'Chrome'
			},
			parameter: {
				iata,
				originIata
			}
		};

		return this.httpClient
			.post<IConfigurationsResponse<IIataDetail>>(url, body)
			.pipe(map((resp) => resp?.result ?? null));
	}

	getDestinationDetails(idWebSeo: number) {
		const url = environment.nmConfigurations + '/v1/api/Webseo/GetNotasByWs';
		const body = {
			trackingCode: Guid(),
			muteExceptions: true,
			caller: {
				company: EnumRequestCompanies.EXPERTIA,
				application: EnumRequestApplications.NMVIAJES,
				deviceId: this.platformService.getDevice().toString(),
				fromIP: '10.10.10.10',
				fromBrowser: 'Chrome'
			},
			parameter: {
				idWebSeo
			}
		};

		return this.httpClient
			.post<IConfigurationsResponse<IDestinationDetails[]>>(url, body)
			.pipe(map((resp) => resp?.result ?? null));
	}

	getDestinationQuestions(idWebSeo: number) {
		const url = environment.nmConfigurations + '/v1/api/Webseo/GetPreguntasByWs';
		const body = {
			trackingCode: Guid(),
			muteExceptions: true,
			caller: {
				company: EnumRequestCompanies.EXPERTIA,
				application: EnumRequestApplications.NMVIAJES,
				deviceId: this.platformService.getDevice().toString(),
				fromIP: '10.10.10.10',
				fromBrowser: 'Chrome'
			},
			parameter: {
				idWebSeo
			}
		};

		return this.httpClient
			.post<IConfigurationsResponse<ISeoFaq[]>>(url, body)
			.pipe(map((resp) => resp?.result ?? null));
	}

	getDestinationRoutes(idWebSeo: number) {
		const url = environment.nmConfigurations + '/v1/api/Webseo/GetRutasByWs';
		const body = {
			trackingCode: Guid(),
			muteExceptions: true,
			caller: {
				company: EnumRequestCompanies.EXPERTIA,
				application: EnumRequestApplications.NMVIAJES,
				deviceId: this.platformService.getDevice().toString(),
				fromIP: '10.10.10.10',
				fromBrowser: 'Chrome'
			},
			parameter: {
				idWebSeo
			}
		};

		return this.httpClient
			.post<IConfigurationsResponse<IPopularRoutes[]>>(url, body)
			.pipe(map((resp) => resp?.result ?? null));
	}
}
