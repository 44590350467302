import {
  Component,
  ElementRef,
  Inject,
  Injectable,
  Input,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { take } from 'rxjs/operators';

import { isPlatformBrowser } from '@angular/common';
import { NMRequest } from 'src/app/shared/models/base/NMRequest';
import {
  ModelTaggingBuscarSeguros,
  SearchTravelInsurance,
} from 'src/app/services/analytics/tagging.models';
import { TaggingService } from 'src/app/services/analytics/tagging.service';
import { DestinyService } from 'src/app/services/destiny/destiny.service';
import { NotificationService } from 'src/app/services/notifications/notification.service';
import { InputRangeComponent } from '../input-range/input-range.component';

export interface IFormSeguros {
  origenSafe: string;
  destinoSafe: string;
  passengers: IPassenger[];
  clienteCotizacion: any[];
  countCustomers: number;
  edades: string;
  fromDate: string;
  toDate: string;
  days: string;
  destinyString: IDestinyString;
  aniosNacimiento: IAniosNacimiento[];
}

export interface IAniosNacimiento {
  anio: number;
  edad: number;
}

export interface IDestinyString {
  id_destino: string;
  descripcion_destino: string;
  es_nacional: number;
  ref_assistcard: number;
}

export interface IPassenger {
  edad: string;
  fechaNacimiento: string;
}

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}

export interface State {
  flag: string;
  name: string;
  population: string;
}

@Component({
  selector: 'app-filtersafe',
  templateUrl: './filtersafe.component.html',
  styleUrls: ['./filtersafe.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class FiltersafeComponent implements OnInit {
  @Input() options: any[];
  @Input() plan: any;
  @ViewChild('childDates') childDates!: InputRangeComponent;
  insuranceQuoteForm: FormGroup;

  model!: NgbDateStruct;
  selected = 'idavuelta';
  customers!: number;
  ageCustomers: any;
  clienteCotizacion: Array<any> = [];
  anios: Array<any> = [];
  showPassengerBox: boolean = false;
  limitePassenger = false;
  limit = 0;

  errors: any[] = [];
  MSG_EMPTY: string = 'none';

  MSG_CUSTOMERS: string = 'age';
  MSG_DESTINO: string = 'destinoSafe';
  MSG_OUTFLY: string = 'fromDate';
  MSG_INFLY: string = 'toDate';

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  pop: any;

  filters: any;
  filtersJSON: any;
  isBrowser = false;

  json = {
    detailPay: 'safe',
    filter: 'filtersafe',
    title: '!Viaja seguro!',
    asistencia: false,
    reembolso: false,
    detalleViaje: false,
    detalleCobertura: true,
    cupon: false,
  };

  @ViewChild('dpFromDate', { static: false }) FromDate2!: ElementRef<HTMLInputElement>;
  @ViewChild('dpToDate', { static: false }) ToDate2!: ElementRef<HTMLInputElement>;
  @ViewChild('menorEdad', { static: false }) menor!: ElementRef<HTMLInputElement>;
  @ViewChild('adulto', { static: false }) adulto!: ElementRef<HTMLInputElement>;
  @ViewChild('mayor', { static: false }) mayor!: ElementRef<HTMLInputElement>;
  @ViewChild('destino', { static: false }) destino!: ElementRef<HTMLInputElement>;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly router: Router,
    private readonly renderer: Renderer2,
    private readonly notification: NotificationService,
    private readonly fb: FormBuilder,
    private readonly coreService: DestinyService
  ) {
    if (isPlatformBrowser(this.platformId)) this.isBrowser = true;
  }

  ngOnInit() {
    if (this.isBrowser) {
      this.filters = localStorage.getItem('filters');
      this.filtersJSON = JSON.parse(this.filters) || {};

      this.insuranceQuoteForm = this.fb.group({
        origenSafe: new FormControl('510', Validators.nullValidator),
        destinoSafe: new FormControl('', Validators.required),
        fromDate: new FormControl(null, Validators.nullValidator),
        toDate: new FormControl(null, Validators.nullValidator),
        passengers: this.fb.array([]),
      });

      const destiny = JSON.parse(localStorage.getItem('destiny')!) || [];
      if (destiny.length) this.options = destiny;
      else this.listDestiny().then(() => null);
    }

    this.updateForm();
  }

  async listDestiny() {
    await new Promise<boolean>((resolve, reject) => {
      let payload = new NMRequest();
      this.coreService
        .getDestiny(payload)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.options = response['Resultado'];
            resolve(true);
          },
          error: (err) => {
            console.error(err);
            reject(false);
          },
        });
    });
  }

  updateForm() {
    if (Object.keys(this.filtersJSON).length > 0) {
      this.getPassengers();
      const dmy = (param: string) => {
        const [day, month, year] = param.split('/');
        return {
          day,
          month,
          year,
        };
      };

      const fromDate = dmy(this.filtersJSON.fromDate);
      const toDate = dmy(this.filtersJSON.toDate);

      this.insuranceQuoteForm.patchValue({
        destinoSafe: this.filtersJSON.destination,
        fromDate,
        toDate,
      });
    } else {
      this.addPassenger();
    }
  }

  get passengers() {
    return this.insuranceQuoteForm.get('passengers') as FormArray;
  }

  addPassenger(): void {
    this.limit = this.insuranceQuoteForm.controls['passengers'].value.length + 1;

    if (this.limit > 4) this.limitePassenger = true;

    let formGroup = this.createPassengerForm();
    const row: any = {
      edad: '',
      fechaNacimiento: moment().format('DD/MM/YYYY'),
    };

    formGroup.patchValue(row);
    this.passengers.push(formGroup);
  }

  getPassengers(): void {
    this.limit = this.filtersJSON.passengers.length;

    if (this.limit > 4) this.limitePassenger = true;

    this.filtersJSON.passengers.forEach((element: any) => {
      let formGroup = this.createPassengerForm();
      formGroup.patchValue(element);
      this.passengers.push(formGroup);
    });
  }

  createPassengerForm(): FormGroup {
    return this.fb.group({
      edad: [''],
      fechaNacimiento: [],
    });
  }

  deletePassenger(index: number): void {
    this.limit = this.insuranceQuoteForm.controls['passengers'].value.length - 1;

    if (this.limit < 5) {
      this.limitePassenger = false;
    }

    this.passengers.removeAt(index);
  }

  confirmPassengers(): void {
    this.showPassengerBox = !this.showPassengerBox;

    const arrPassengers = this.insuranceQuoteForm.controls['passengers'].value;
    const arrAges = [];

    for (let i in arrPassengers) {
      Number(i);

      let age = Number(this.insuranceQuoteForm.controls['passengers'].value[i].edad);

      this.insuranceQuoteForm.controls['passengers'].value[i].fechaNacimiento = moment()
        .subtract(age, 'years')
        .format('DD/MM/YYYY');

      let fecha = this.insuranceQuoteForm.controls['passengers'].value[i].fechaNacimiento;
      let anioNacio: any = moment(fecha, 'DD/MM/YYYY').year();

      arrAges.push(age);

      this.ageCustomers = arrAges.join(';');
      this.anios.push({ anio: Number(anioNacio), edad: age });
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) this.fromDate = date;
    else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) this.toDate = date;
    else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  private validForm() {
    this.errors = [];

    let age: string =
      this.passengersControls()?.value?.length && this.passengersControls()?.value[0]['edad'];
    if (age === undefined || age === null || age.trim() === '') {
      this.showPassengerBox = true;
      this.notification.showNotificacion('Error', 'Debe ingresar la edad del pasajero', 10);
      this.errors.push({ name: this.MSG_CUSTOMERS, message: 'Debe ingresar la edad' });
    }

    let destinoSafe: string = this.destinoSafe()?.value;
    if (destinoSafe === undefined || destinoSafe === null || destinoSafe.trim() === '') {
      this.notification.showNotificacion('Error', 'Debe ingresar el destino', 10);
      this.errors.push({ name: this.MSG_DESTINO, message: 'Elija el Destino' });
    }

    if (this.fromDate === undefined || this.fromDate === null) {
      this.errors.push({ name: this.MSG_OUTFLY, message: 'Elija La fecha' });
    }

    if (this.toDate === undefined || this.toDate === null) {
      this.errors.push({ name: this.MSG_INFLY, message: 'Elija la fecha de llegada' });
    }

    return this.errors.length === 0;
  }

  getMessage(messageKey: any) {
    return this.errors.filter((item: any) => item.name === messageKey).length > 0
      ? this.errors.filter((item: any) => item.name === messageKey)[0].message
      : this.MSG_EMPTY;
  }

  insertTag(form: IFormSeguros) {
    const edades = form.edades.split(';');
    const sum = edades.reduce((acc, el) => Number(el) + acc, 0);
    const promEdades = sum / edades.length;

    const tag = new ModelTaggingBuscarSeguros(
      form.destinyString.descripcion_destino,
      form.destinyString.id_destino,
      form.passengers.length,
      promEdades,
      form.fromDate,
      form.toDate,
      'PE',
      'Peru',
      Number(this.diffDays())
    );

    TaggingService.tagBuscarSeguros(tag);

    const daysFromNow = moment(form.fromDate, 'DD/MM/YYYY').diff(moment(), 'days');

    const model: SearchTravelInsurance = {
      event: 'nmv_seguros_buscar',
      operacion: {
        dias_anticipacion: daysFromNow,
      },
      origen: {
        nombre: 'Peru',
        codigo: 'PE',
        pais: 'Peru',
      },
      destino: {
        nombre: form.destinyString.descripcion_destino,
        codigo: form.destinyString.id_destino,
        pais: '',
      },
      pasajeros: {
        total: form.passengers.length,
        infantes: form.passengers.filter((p) => Number(p.edad) <= 5).length,
        ninos: form.passengers.filter((p) => Number(p.edad) > 5 && Number(p.edad) < 18).length,
        adultos: form.passengers.filter((p) => Number(p.edad) >= 18).length,
      },
      fechas: {
        salida: moment(form.fromDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        retorno: moment(form.toDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        estadia: Number(this.diffDays()),
      },
    };

    TaggingService.tagSearchTravelInsurance(model);
  }

  quoteNow() {
    const { arrivalDate, departureDate } = this.childDates.getValuesByHotel();
    this.fromDate = departureDate;
    this.toDate = arrivalDate;

    if (this.validForm()) {
      let startDate = `${this.fromDate!.day}/${this.fromDate!.month}/${this.fromDate!.year}`;
      let endDate = `${this.toDate!.day}/${this.toDate!.month}/${this.toDate!.year}`;
      this.insuranceQuoteForm.addControl(
        'clienteCotizacion',
        new FormControl(this.clienteCotizacion, Validators.nullValidator)
      );
      this.insuranceQuoteForm.addControl(
        'countCustomers',
        new FormControl(this.clienteCotizacion.length, Validators.nullValidator)
      );
      this.insuranceQuoteForm.addControl(
        'edades',
        new FormControl(this.ageCustomers, Validators.nullValidator)
      );
      this.insuranceQuoteForm.removeControl('days');
      this.insuranceQuoteForm.addControl(
        'days',
        new FormControl(this.diffDays(), Validators.nullValidator)
      );
      this.insuranceQuoteForm.removeControl('destinyString');
      this.insuranceQuoteForm.addControl(
        'destinyString',
        new FormControl(
          this.options.find(
            (opt) => opt.ref_assistcard.toString(10) === this.destino.nativeElement.value
          ) ?? '',
          Validators.nullValidator
        )
      );

      // Validación fecha de nacimiento
      this.insuranceQuoteForm.addControl(
        'aniosNacimiento',
        new FormControl(this.anios, Validators.nullValidator)
      );
      this.fromDateField()?.setValue(startDate);
      this.toDateField()?.setValue(endDate);

      for (let i in this.insuranceQuoteForm.controls['passengers'].value) {
        let age = Number(this.insuranceQuoteForm.controls['passengers'].value[i].edad);
        this.insuranceQuoteForm.controls['passengers'].value[i].fechaNacimiento = moment()
          .subtract(age, 'years')
          .format('DD/MM/YYYY');
      }

      let form = this.insuranceQuoteForm.value;
      localStorage.removeItem('Datasafe');
      this.insertTag(form);
      localStorage.setItem('Datasafe', JSON.stringify(form));

      const filters = {
        destination: this.insuranceQuoteForm.getRawValue()['destinoSafe'],
        fromDate: startDate,
        toDate: endDate,
        passengers: this.insuranceQuoteForm.getRawValue()['passengers'],
      };
      localStorage.setItem('filters', JSON.stringify(filters));

      this.router.navigateByUrl('/seguros/planes', { skipLocationChange: true }).then(() => null);
    }
  }

  private destinyString() {
    for (const i of this.options) {
      if (String(i.ref_assistcard) === this.destino.nativeElement.value) return i;
    }
  }

  diffDays() {
    const FeIni = this.fromDate!.month + '/' + this.fromDate!.day + '/' + this.fromDate!.year;
    const FeFin = this.toDate!.month + '/' + this.toDate!.day + '/' + this.toDate!.year;
    const date1 = new Date(FeIni);
    const date2 = new Date(FeFin);
    const diff = Math.abs(date1.getTime() - date2.getTime());
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24)) + 1;
    return String(diffDays);
  }

  count(valor: number, e: any) {
    let customer: any;
    switch (e) {
      case 'menor':
        customer = this.menor.nativeElement;
        break;
      case 'adulto':
        customer = this.adulto.nativeElement;
        break;
      case 'mayor':
        customer = this.mayor.nativeElement;
        break;
      default:
        break;
    }

    let inputvalue = Number(customer.value);
    inputvalue = inputvalue + valor;
    this.renderer.setAttribute(customer, 'value', String(inputvalue));
  }

  allowNumeric(event: KeyboardEvent) {
    const pattern = /[0-9]/;

    if (!pattern.test(event.key)) event.preventDefault();
  }

  onPasteNumeric(event: ClipboardEvent) {
    if (this.isBrowser) {
      event.preventDefault();

      if (event.clipboardData != null) {
        const texto: string = event.clipboardData.getData('text/plain').replace(/\D/g, '');

        document.execCommand('insertText', false, texto);
      }
    }
  }

  denyDrop(event: DragEvent) {
    event.preventDefault();
  }

  private fromDateField() {
    return this.insuranceQuoteForm.get('fromDate');
  }

  private toDateField() {
    return this.insuranceQuoteForm.get('toDate');
  }

  private passengersControls() {
    return this.insuranceQuoteForm.get('passengers');
  }

  private destinoSafe() {
    return this.insuranceQuoteForm.get('destinoSafe');
  }
}
