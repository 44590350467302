export enum ENmWebsTypes {
  HOTEL = 'HOTEL',
  PACKAGE = 'PACKAGE',
  AIRLINE = 'AIRLINE',
  CAMPAIGN = 'CAMPAIGN',
}

export enum ENmWebElementTypes {
  ELEMENT_IMAGE = 'TYPIMG',
  ELEMENT_TITLE = 'TITLE',
  ELEMENT_BODY = 'BODY',
  ELEMENT_LINK = 'LINK',
  // ELEMENT_SUB_TITLE = 'SUB_TITLE',
  ELEMENT_LOGO = 'LOGO',
  ELEMENT_ALT = 'ALT',
}

export enum ENmWebSectionTypes {
  SECTION_BANNER_TOP = 'BANSEC',
  SECTION_BANNER_PROM = 'PROMBAN',
  SECTION_DESCRIPTION = 'DESCRI',
  SECTION_FAQ = 'FAQ',
  SECTION_POPULAR_ROUTES = 'POPROUTES',
  // SECTION_TITLE_CARD = 'TITLE_CARD',
  SECTION_CARD = 'CARD',
  SECTION_CARD_INT = 'CARD_INT',
  SECTION_SEARCHBAN = 'SEARCHBAN',
  SECTION_SCHBAN_MOBILE = 'SRCHB_MOB',
  SECTION_CARD_DETAIL = 'CARD_DET',
}

export interface INmWeb {
  webId?: number;
  name: string;
  description: string;
  metaDescription: string;
  metaTitle: string;
  slug: string;
  destinationIata: string;
  originIata: string;
  autoAction: boolean;
  detailSearch: string;
  destinationCity: string;
  userCreation: string;
  sections?: NmWebSection[];
}

export interface NmWebSection {
  sectionId: number;
  webId: number;
  genericTypeId: number;
  description: string;
  nmGenericType: NmGenericType;
  nmWebElements: NmWebElement[];
}

export interface NmGenericType {
  genericTypeId: number;
  name: string;
  description: string;
  typeCategory: string;
}

export interface NmWebElement {
  elementId?: number;
  elementOrder?: number;
  sectionId?: number;
  genericTypeId?: number;
  content?: string;
  mediaId?: number;
  dateCreation?: string;
  userCreation?: string;
  userUpdate?: string;
  dateUpdate?: string;
  redirectUrl?: string;
  nmGenericType?: NmGenericType;
  nmMedia?: NmMedia;
}

export interface NmMedia {
  mediaId: number;
  url: string;
  size: number;
  imageName: string;
  mimeType: string;
  dateCreation: string;
  userCreation: string;
  dateUpdate: string;
}
