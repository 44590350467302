import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { DestinyService } from 'src/app/Services/destiny/destiny.service';
import { ISearchHistoryModel } from '../../../Models/search-history/search-history.model';
import { CookiesService } from '../../../Services/cookies/cookies.service';
import { CookiesNamesEnum } from '../../../Services/cookies/enums/cookies-names.enum';
import { GlobalComponent } from '../../global';
import { ICardAutocomplete } from '../card-autocomplete/card-autocomplete.interface';
import { IGeoTree } from '../filter-tabs/tab-vuelos/tab-vuelos.interfaces';

@Component({
  selector: 'app-input-search-flight',
  templateUrl: './input-search-flight.component.html',
  styleUrls: ['./input-search-flight.component.scss'],
})
export class InputSearchFlightComponent implements OnInit, OnDestroy {
  @Input() typeFlight = 0;
  @Input() indexRowMulti = 0;

  private unsubscribe$ = new Subject<void>();
  private destroyDep$ = new Subject<unknown>();
  private destroyArr$ = new Subject<unknown>();

  rotate = false;
  valueSearchDeparture = new FormControl('');
  valueSearchArrival = new FormControl('');

  listResultDep: ICardAutocomplete[] = [];
  listResultRet: ICardAutocomplete[] = [];

  isClickSuggestionDep = false;
  isClickSuggestionArr = false;
  isParamsRet = false;
  isParamsDep = false;

  origin: string | null;
  destination: string | null;

  showLoaderDep = false;
  showLoaderRet = false;

  notResultDep = false;
  notResultRet = false;

  isReverse = false;

  constructor(
    private _destinyService: DestinyService,
    private _cookiesService: CookiesService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    this.onChangeSearchDep();
    this.onChangeSearchRet();
    this.subscribeSearchValues();

    if (isPlatformBrowser(this.platformId)) {
      if (window.location.href.includes('resultados')) {
        this.setValuesInit();
      }
    }
  }

  private setValuesInit() {
    const dataSearch = GlobalComponent.searchData;
    if (dataSearch.flightType !== 2 && this.indexRowMulti == 0) {
      this.isParamsDep = true;
      this.isParamsRet = true;
      if (dataSearch.arrivalLocation !== '' && dataSearch.departureLocation !== '') {
        this.valueSearchDeparture.setValue(this.extractCityName(dataSearch.fullDepartureLocation!));
        this.valueSearchArrival.setValue(this.extractCityName(dataSearch.fullArrivalLocation!));
        this.origin = dataSearch.fullDepartureLocation?.replace(/ /g, '%20') || '';
        this.destination = dataSearch.fullArrivalLocation?.replace(/ /g, '%20') || '';
      } else {
        //El link es de kayak
        this.valueSearchDeparture.setValue(dataSearch.fullDepartureLocation!);
        this.valueSearchArrival.setValue(dataSearch.fullArrivalLocation!);
      }
    }
    if (dataSearch.multicity) {
      this.isParamsDep = true;
      this.isParamsRet = true;
      this.valueSearchDeparture.setValue(
        dataSearch.multicity[this.indexRowMulti].departureLocation.replace(/,\s*$/, '')
      );
      this.valueSearchArrival.setValue(
        dataSearch.multicity[this.indexRowMulti].arrivalLocation.replace(/,\s*$/, '')
      );
      this.origin =
        dataSearch.multicity[this.indexRowMulti].fullDepartureLocation?.replace(/ /g, '%20') || '';
      this.destination =
        dataSearch.multicity[this.indexRowMulti].fullArrivalLocation?.replace(/ /g, '%20') || '';
    }
  }

  private subscribeSearchValues() {
    this._destinyService.origin$.pipe(takeUntil(this.unsubscribe$)).subscribe((origin) => {
      this.isParamsDep = true;
      this.origin = `${origin.iata}%20${origin.cityName},%20${origin.countryName}`;
      this.valueSearchDeparture.setValue(origin.cityName);
    });

    this._destinyService.destination$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((destination) => {
        this.isParamsRet = true;
        this.destination = `${destination.iata}%20${destination.cityName},%20${destination.countryName}`;
        this.valueSearchArrival.setValue(destination.cityName);
      });
  }

  private onChangeSearchDep(): void {
    this.valueSearchDeparture.valueChanges
      .pipe(
        map((search) => search?.toLowerCase().trim()),
        tap((search) => {
          if (search == undefined || search?.length < 3) {
            this.listResultDep = [];
            this.notResultDep = false;
          }
          return search;
        }),
        filter((search) => search !== '' && search !== undefined && search?.length > 2),
        debounceTime(400),
        tap((search) => this.onSearchServiceDep(search ?? '')),
        takeUntil(this.destroyDep$)
      )
      .subscribe();
  }

  private onChangeSearchRet(): void {
    this.valueSearchArrival.valueChanges
      .pipe(
        map((search) => search?.toLowerCase().trim()),
        tap((search) => {
          if (search == undefined || search?.length < 3) {
            this.listResultRet = [];
            this.notResultRet = false;
          }
          return search;
        }),
        filter((search) => search !== '' && search !== undefined && search?.length > 2),
        debounceTime(400),
        tap((search) => this.onSearchServiceRet(search ?? '')),
        takeUntil(this.destroyArr$)
      )
      .subscribe();
  }

  onSearchServiceDep(word: string) {
    if (!this.isClickSuggestionDep && !this.isReverse) {
      this.listResultDep = [];
      if (!this.isParamsDep || (this.isParamsDep && word.length <= 3)) this.showLoaderDep = true;
      if (this.showLoaderDep) {
        this.origin = null;
        this._destinyService
          .getGeoTree(word)
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              this.showLoaderDep = false;
              if (!this.isParamsDep) {
                this.listResultDep = this.formatArrayResult(res);
                this.notResultDep = this.listResultDep.length <= 0;
                const resultDep = this.listResultDep.find((item) => item.title === this.origin);
                if (resultDep) this.clickItemDep(this.listResultDep[0]);
              } else {
                const listResult = this.formatArrayResult(res);
                this.clickItemDep(listResult[0]);
                this.isParamsDep = false;
              }
            },
          });
      } else this.isParamsDep = false;
    } else {
      this.isClickSuggestionDep = false;
    }
  }

  onSearchServiceRet(word: string) {
    if (!this.isClickSuggestionArr && !this.isReverse) {
      this.listResultRet = [];
      if (!this.isParamsRet || (this.isParamsRet && word.length <= 3)) this.showLoaderRet = true;
      if (this.showLoaderRet) {
        this.destination = null;
        this._destinyService
          .getGeoTree(word)
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              this.showLoaderRet = false;
              if (!this.isParamsRet) {
                this.listResultRet = this.formatArrayResult(res);
                this.notResultRet = this.listResultRet.length <= 0;
              } else {
                const listResult = this.formatArrayResult(res);
                this.clickItemRet(listResult[0]);
                this.isParamsRet = false;
              }
            },
          });
      } else this.isParamsRet = false;
    } else {
      this.isClickSuggestionArr = false;
      this.isReverse = false;
    }
  }

  openDestinationHistory() {
    if (this.destination) return;

    const history: ISearchHistoryModel[] =
      this._cookiesService.getCookie(CookiesNamesEnum.SEARCH_HISTORY, true) ?? [];

    if (history.length < 1) return;

    this.listResultRet = history.map((item) => {
      return {
        children: [],
        id: item.code,
        codigo: item.code,
        country: item.country,
        title: item.name,
      } as ICardAutocomplete;
    });
    this.notResultRet = this.listResultRet.length <= 0;
  }

  formatArrayResult(response: IGeoTree[]) {
    const nuevoArray: ICardAutocomplete[] = [];
    response.forEach((x: IGeoTree) => {
      const elementFind = nuevoArray.find((item) => item.id == x.aerocodiata);
      if (!elementFind && x.tn_iata_padre_fn == '0') {
        const obj: ICardAutocomplete = {
          id: x.aerocodiata,
          codigo: x.city_code,
          title: x.city,
          country: x.country,
          children: [],
        };
        nuevoArray.push(obj);
      } else if (!elementFind && x.tn_iata_padre_fn == '2') {
        const obj = {
          id: x.aerocodiata,
          country: '',
          codigo: '',
          title: '',
          children: [
            {
              id: x.aerocodiata,
              codigo: x.city_code,
              title: x.city,
              country: x.country,
              children: [],
            },
          ],
        };
        nuevoArray.push(obj);
      } else if (elementFind && x.tn_iata_padre_fn == '2')
        elementFind.children.push({
          id: x.aerocodiata,
          codigo: x.city_code,
          title: x.city,
          country: x.country,
          children: [],
        });
    });
    return nuevoArray;
  }

  reverseInputs() {
    if (this.origin && this.destination) {
      this.isReverse = true;
      this.rotate = !this.rotate;
      const valueSearchDeparture = this.valueSearchDeparture.value;
      const valueSearchArrival = this.valueSearchArrival.value;
      const origin = this.origin;
      const destination = this.destination;

      this.valueSearchDeparture.setValue(valueSearchArrival);
      this.valueSearchArrival.setValue(valueSearchDeparture);

      this.origin = destination;
      this.destination = origin;
    }
  }

  resetInput() {
    this.valueSearchDeparture.setValue('');
    this.origin = null;
    this.notResultDep = false;
  }

  resetInput2() {
    this.valueSearchArrival.setValue('');
    this.destination = null;
    this.notResultRet = false;
  }

  clickItemDep(item: ICardAutocomplete) {
    this.isClickSuggestionDep = true;
    this.valueSearchDeparture.setValue(item.title);
    this.listResultDep = [];
    this.destroyDep$;
    this.origin = `${item.codigo}%20${item.title},%20${item.country}`;
  }

  clickItemRet(item: ICardAutocomplete) {
    this.isClickSuggestionArr = true;
    this.valueSearchArrival.setValue(item.title);
    this.listResultRet = [];
    this.destroyArr$;
    this.destination = `${item.codigo}%20${item.title},%20${item.country}`;
  }

  getValues() {
    return {
      departureLocation: this.origin,
      arrivalLocation: this.destination,
    };
  }

  @ViewChild('inputOrigin') inputOrigin: ElementRef;
  @ViewChild('inputDestiny') inputDestiny: ElementRef;

  @HostListener('document:click', ['$event'])
  blurRange(event: MouseEvent) {
    if (isPlatformBrowser(this.platformId)) {
      if (
        this.inputOrigin &&
        !this.inputOrigin.nativeElement.contains(event.target) &&
        (this.listResultDep.length > 0 || this.notResultDep)
      ) {
        this.resetInput();
      }

      if (
        this.inputDestiny &&
        !this.inputDestiny.nativeElement.contains(event.target) &&
        (this.listResultRet.length > 0 || this.notResultRet)
      ) {
        this.resetInput2();
      }
    }
  }

  private extractCityName(inputString: string) {
    let parts = inputString.split(',');
    parts = parts[0].split(' ');

    // slice first part ex: LIM, LBP
    if (parts.length) parts = parts.slice(1, parts.length);
    const cityName = parts.join(' ').trim();
    return cityName.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
