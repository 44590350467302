import { environment } from '../../../environments/environment';

/**
 * Helper class for managing local/session storage with support for legacy formats and expiration.
 */
export class StorageUtils {
  /**
   * Returns a parsed or fallback value from storage, supporting legacy formats and JSON strings.
   *
   * @param stored - Stored data in plain or legacy format (`T` or `{ value: T | string }`).
   * @param defaultValue - Value to return if stored is null, undefined, or invalid.
   * @returns Normalized value as type `T`.
   */
  static getValueOrDefault<T>(
    stored: T | { value: T | string } | null | undefined,
    defaultValue: T
  ): T {
    if (stored && typeof stored === 'object' && 'value' in stored) {
      const rawValue = stored.value;

      if (typeof rawValue === 'string') {
        try {
          return JSON.parse(rawValue) as T;
        } catch (err) {
          if (!environment.production) console.warn('Invalid JSON in storage value:', rawValue);
          return defaultValue;
        }
      }

      return rawValue;
    }

    return stored ?? defaultValue;
  }
}
