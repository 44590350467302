import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConformidadComponent } from './conformidad.component';
import { CoverageModule } from 'src/app/shared/components/coverage/coverage.module';
import { HttpClientModule } from '@angular/common/http';
import { GeneratePayService } from 'src/app/Services/generatePay/generate-pay.service';
import { RouterModule } from '@angular/router';
import { CountdownModule } from 'ngx-countdown';
import { UpdatePayService } from '../../../Services/updatePay/update-pay.service';
import { StatePayService } from '../../../Services/statePay/state-pay.service';
import { ReservaVuelosService } from '../../../Services/reservaVuelos/reserva-vuelos.service';
import { CardPaymentService } from '../../../Services/cardPayment/card-payment.service';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [ ConformidadComponent ],
  imports: [
    CommonModule,
    CoverageModule,
    RouterModule,
    HttpClientModule,
    CountdownModule,
  ],
  exports: [ ConformidadComponent ],
  providers: [CardPaymentService, ReservaVuelosService, GeneratePayService, UpdatePayService, StatePayService]
})
export class ConformidadModule { }
