import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CryptoService } from 'src/app/Services/util/crypto.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor(@Inject(PLATFORM_ID) private _platformId: Object, private _cryptoService: CryptoService) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this._platformId)) {
      let userID: string = '';
      let user_existingCustomer: boolean = false;
      const credentials = localStorage.getItem('usuario');
      const bookings = localStorage.getItem('bookings');

      if (credentials) {
        const credentialsJson = JSON.parse(credentials);
        userID = this._cryptoService.encrypt(credentialsJson.email);

        if (bookings)
          user_existingCustomer = JSON.parse(bookings).length > 0;
      }

      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push({
        event: 'user_info',
        userID: userID,
        user_existingCustomer: user_existingCustomer
      });

      (window as any).dataLayer.push({
        event: 'virtualPageView',
        virtualPagePath: '/404',
        virtualPageTitle: 'NMV: Page not found'
      });
    }
  }

}
