import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomButtonsModule } from '../../../../shared/components/moleculas/custom-buttons/custom-buttons.module';
import {
  PackageSectionModule,
  FlightSearchModule,
  SectionWrapperModule,
} from '../../../../shared/components';
import { AirlineCardComponent } from './components/airline-card/airline-card.component';
import { AirlinesRoutingModule } from './airlines-routing.module';
import { AirlineComponent } from './airline.component';
import { CardPlaceholderModule } from '../../../../shared/components/moleculas/card-placeholder/card-placeholder.module';
import {
  InfoCardComponent,
  DescriptionCardComponent,
  EmailSubscriptionComponent,
  FaqComponent,
} from '../../components';

@NgModule({
  declarations: [AirlineComponent, AirlineCardComponent],
  imports: [
    CommonModule,
    AirlinesRoutingModule,
    FlightSearchModule,
    CustomButtonsModule,
    PackageSectionModule,
    NgbNavModule,
    CustomButtonsModule,
    CardPlaceholderModule,
    SectionWrapperModule,
    DescriptionCardComponent,
    InfoCardComponent,
    EmailSubscriptionComponent,
    FaqComponent,
  ],
})
export class AirlinesModule {}
