import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdown, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AutoCompleteItem } from '../../../models';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { PaginatorModule } from 'primeng/paginator';

@Component({
  selector: 'app-autocomplete-dropdown',
  standalone: true,
  imports: [CommonModule, NgbDropdownModule, PaginatorModule, ReactiveFormsModule],
  templateUrl: './autocomplete-dropdown.component.html',
  styleUrls: ['./autocomplete-dropdown.component.scss'],
})
export class AutocompleteDropdownComponent {
  @ViewChild('dropdown') dropdown: NgbDropdown;

  @Input() placeholder: string = '';
  @Input() suggestions: AutoCompleteItem[] = [];
  @Input() showLoader: boolean = false;
  @Input() noResults: boolean = false;
  @Input() isPreloaded: boolean = false;
  @Input() containerClass: string | string[] | { [p: string]: any } | undefined;
  @Input() control!: FormControl;

  @Output() clearInput = new EventEmitter<void>();
  @Output() selectItem = new EventEmitter<AutoCompleteItem>();
  @Output() openChange = new EventEmitter<boolean>();
  @Output() inputClick = new EventEmitter<void>();

  onClearInput() {
    this.clearInput.emit();
  }

  onSelectItem(item: AutoCompleteItem) {
    this.selectItem.emit(item);
  }

  onDropdownOpenChange(opened: boolean) {
    this.openChange.emit(opened);
  }

  onInputClick() {
    this.inputClick.emit();
  }

  showSuggestions() {
    this.dropdown.open();
  }
}
