import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerImageComponent } from './banner-image.component';
import { DirectivesModule } from '../../../directives/directives.module';
import { RouterLinkWithHref } from '@angular/router';



@NgModule({
  declarations: [BannerImageComponent],
  imports: [CommonModule, DirectivesModule, RouterLinkWithHref],
  exports: [BannerImageComponent],
})
export class BannerImageModule {}
