export const PATH_NAMES = {
	FLIGHTS: 'vuelos',
	HOTELS: 'hoteles',
	FLIGHT_HOTEL: 'vuelohotel',
	TOURIST_PACKAGES: 'paquetes',
	TRAVEL_INSURANCE: 'seguro-viaje',
	HOT_SALE: 'ofertas',
	POLICIES: 'politicas',
	BOOKING: 'condiciones-de-reserva',
	RESPONSIBILITY: 'responsabilidad-social',
	DATE: 'tuscitas', 
	COMPLAINTS: 'libro-reclamaciones',
	BENEFITS: 'beneficios',
	JULY: '28-de-julio',
	COMPANY: 'nuestra-empresa', 
	DOCUMENT_TRAVEL: 'documentacion-viaje', 
	TERM_COND: 'terminos-condiciones', 
	PROMOTION: 'promociones', 
	SUSCRIPTION: 'suscribirse',
	EVENTS: 'eventos', 
	RECEPTION: 'recepcion', 
	TRAVEL_OUT:  'traveloutlet',
	CANALES: 'canales-de-atencion',
	AGENCIES: 'nuestras-agencias'

} as const;
