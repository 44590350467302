import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IToggleCardModel } from '../../../models';
import { CommonModule } from '@angular/common';
import { FormFieldsModule } from '../../atoms';

@Component({
  imports: [CommonModule, FormFieldsModule],
  selector: 'app-toggle-card',
  templateUrl: './toggle-card.component.html',
  standalone: true,
  styleUrls: ['./toggle-card.component.scss'],
})
export class ToggleCardComponent {
  @Input() model: IToggleCardModel = {
    title: '',
    description: '',
    hasSwitch: true,
    defaultValue: true,
    isDisabled: false,
  };

  @Output() switchChanged = new EventEmitter<boolean>();
}
