import { Component, Input } from '@angular/core';
import { BreakpointService } from '../../../../services/breakpoints';
import { Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-section-wrapper',
  templateUrl: './section-wrapper.component.html',
})
export class SectionWrapperComponent {
  @Input() hasWhiteBackground: boolean = false;
  @Input() verticalPadding: number = 5;
  @Input() fixedVerticalPadding: boolean = false;
  @Input() title!: string;
  @Input() titleId: string | null = 'section-title';
  @Input() titleLimitedWidth: boolean = false;
  @Input() customTitleClass: string = '';

  isDesktop$ = this.breakpointService.isBreakpoint(Breakpoints.Web);

  constructor(private readonly breakpointService: BreakpointService) {}

  getSectionVerticalPadding(): string {
    const verticalPadding = Math.min(this.verticalPadding, 5);
    const xsVerticalPadding = Math.min(Math.max(1, verticalPadding - 1), 5);

    if (this.fixedVerticalPadding) return `py-${verticalPadding}`;

    return `py-${xsVerticalPadding} py-lg-${verticalPadding}`;
  }
}
