import { Component, OnInit } from '@angular/core';
import { NmWebsService } from '../../services/nm-webs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subject } from 'rxjs';
import { catchError, finalize, map, take, takeUntil, tap } from 'rxjs/operators';
import {
  ENmWebSectionTypes,
  ENmWebsTypes,
  INmWeb,
  NmMedia,
  NmWebSection,
} from '../../../../shared/constants/nm-webs.model';
import { SeoService } from '../../../../services/seo/seo.service';
import { SeoModel } from '../../../../shared/models/seo.model';
import { IImageCardModel } from '../../../../shared/models';

@Component({
  selector: 'app-interbank-nmviajes-fair-landing',
  templateUrl: './campaign.component.html',
})
export class CampaignComponent implements OnInit {
  private slug: string;
  private readonly destroy$ = new Subject<void>();

  loading = false;
  campaign: INmWeb;
  topBanner?: NmWebSection;
  mobileTopBanner?: NmWebSection;
  bannerMedia?: NmMedia;
  mobileBannerMedia?: NmMedia;
  bannerRedirectUrl?: string;
  imageGrid?: NmWebSection;
  imageCardGrid?: IImageCardModel[];
  bottomBanners?: IImageCardModel[];

  constructor(
    private readonly landingService: NmWebsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly seoService: SeoService
  ) {}

  ngOnInit() {
    this.getSlug();
  }

  private getSlug() {
    this.route.params
      .pipe(
        map((params) => params.slug ?? ''),
        tap((slug) => (this.slug = slug)),
        tap((slug) => this.validateSlug(slug)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private validateSlug(slug: string) {
    slug ? this.getCampaign() : this.handleError();
  }

  private getCampaign() {
    this.loading = true;

    this.landingService
      .getWebByNameAndSlug(ENmWebsTypes.CAMPAIGN, this.slug)
      .pipe(
        take(1),
        tap((campaign: INmWeb) => this.handleCampaignData(campaign)),
        catchError(() => {
          this.handleError();
          return of(null);
        }),
        finalize(() => (this.loading = false))
      )
      .subscribe();
  }

  private handleCampaignData(campaign: INmWeb) {
    this.campaign = campaign;
    this.applyMetaTags(campaign);
    this.handleSections();
  }

  private applyMetaTags(campaign: INmWeb) {
    const seoData: SeoModel = {
      title: campaign.metaTitle,
      description: campaign.metaDescription,
      url: this.router.url,
    };

    this.seoService.setSEOValues(seoData);
  }

  private handleSections() {
    if (!this.campaign.webId) {
      this.handleError();
      return;
    }

    this.landingService
      .getNmWebSectionByWebId(this.campaign.webId)
      .pipe(
        take(1),
        tap((sections) => this.processSections(sections)),
        catchError(() => {
          this.handleError();
          return of([]);
        })
      )
      .subscribe();
  }

  private processSections(sections: NmWebSection[]) {
    const sectionTypes = ENmWebSectionTypes;

    const topBanner = this.findAndSortSections(sections, sectionTypes.SECTION_SEARCHBAN);
    const mobileTopBanner = this.findAndSortSections(sections, sectionTypes.SECTION_SCHBAN_MOBILE);
    const imageGrid = this.findAndSortSections(sections, sectionTypes.SECTION_BANNER_TOP);
    const bottomBanners = this.findAndSortSections(sections, sectionTypes.SECTION_BANNER_PROM);

    this.topBanner = topBanner;
    this.mobileTopBanner = mobileTopBanner;
    this.imageGrid = imageGrid;
    this.bottomBanners = this.mapSectionsToImageCards(bottomBanners);

    this.imageCardGrid = this.mapSectionsToImageCards(imageGrid);
    this.bannerMedia = topBanner?.nmWebElements?.[0]?.nmMedia;
    this.mobileBannerMedia = mobileTopBanner?.nmWebElements?.[0]?.nmMedia;
    this.bannerRedirectUrl = topBanner?.nmWebElements?.[0]?.redirectUrl;
  }

  private mapSectionsToImageCards(section?: NmWebSection): IImageCardModel[] | undefined {
    return section?.nmWebElements?.map((element) => ({
      imageUrl: element.nmMedia?.url ?? '',
      altText: section.description,
      linkUrl: element.redirectUrl,
    }));
  }

  private findAndSortSections(sections: NmWebSection[], type: string) {
    const section = sections.find((item) => item.nmGenericType.name === type);
    section?.nmWebElements?.sort((a, b) => (a?.elementOrder ?? 0) - (b?.elementOrder ?? 0));
    return section;
  }

  private handleError() {
    this.router.navigateByUrl('/').then();
  }
}
