import { NgModule } from '@angular/core';
import { HtmlSanitizerPipe } from './html-sanitizer.pipe';
import { IframeSafePipe } from './iframe-sanitizer.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';

@NgModule({
	declarations: [HtmlSanitizerPipe, IframeSafePipe, SafeHtmlPipe],
	exports: [HtmlSanitizerPipe, IframeSafePipe, SafeHtmlPipe]
})
export class PipesModule {}
