import { Component, EventEmitter, Input, OnInit, Output, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { PlatformService } from '../../../services';
import { DEVICES } from '../../../constants';

@Component({
	selector: 'app-mobile',
	templateUrl: './mobile.component.html',
	styleUrls: [ './mobile.component.scss' ]
})
export class MobileComponent implements OnInit {

	@Input() showItems = false;
	@Input() isLogged = false;
	@Output() clickButton = new EventEmitter();
	@Output() clickInfo = new EventEmitter();

	isBrowser: Boolean = false;
	hideHelp = false;

	constructor(
			@Inject(PLATFORM_ID) private _platformId: Object,
			private _router: Router,
			private _platformService: PlatformService
	) {
	}

	ngOnInit(): void {
		if (isPlatformBrowser(this._platformId)) {
			this.isBrowser = true;
			this.hideHelp = this._platformService.getDevice() === DEVICES.IOS;
		}
	}

	clickAction() {
		this.clickButton.emit();
	}

	redirect(e: any) {
		if (this.isBrowser) {
			this.clickInfo.emit();
			if (e.charAt(0) == '/') this._router.navigateByUrl(e).then(() => null);
			else window.open(e, '_blank');
		}
	}
}
