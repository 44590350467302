import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-toggle-field',
  templateUrl: './toggle-field.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class ToggleFieldComponent {
  @Input() id!: string;
  @Input() controlName: string;
  @Input() checked = false;
  @Input() indeterminate = false;
  @Input() isSwitchStyle = false;
  @Input() disabled = false;
  @Input() showValidState = false;
  @Input() showInvalidSate = false;
  @Input() invalidMessage: string;
  @Input() inputCssClass = '';
  @Input() labelCssClass = '';

  @Output() toggleChange = new EventEmitter<boolean>();

  onToggle(event: any) {
    this.toggleChange.emit(event.target.checked);
  }
}
