import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-filled-button',
	templateUrl: './filled-button.component.html',
	styleUrls: [ './filled-button.component.scss' ]
})
export class FilledButtonComponent implements OnInit {
	@Input() btnClass = '';
	@Input() btnBlock = false;
	@Input() btnId = '';
	@Input() disabled = false;
	@Input() hierarchy: 'primary' | 'secondary' | 'tertiary' = 'primary';
	@Input() label = '';
	@Input() link: string;
	@Input() loading = false;
	@Input() type: 'button' | 'submit' = 'button';
	@Input() size: 'sm' | 'md' | 'lg' = 'md';
	@Input() hrefUrl = '';
	@Input() target: string;
	@Output() btnClick = new EventEmitter<MouseEvent>();

	constructor() {
	}

	ngOnInit(): void {
	}

}
