import { Injectable } from '@angular/core';
import { CookieConfig } from '../../shared/models';
import { ConsentStatesEnum } from './constants/consent-states.enum';
import { StorageService } from '../storage';
import { StorageKeysEnum } from '../../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  constructor(private readonly storageService: StorageService) {}

  setConsent() {
    const config = this.storageService.getItem<CookieConfig>(StorageKeysEnum.COOKIES_CONFIG);
    if (config === null) return;

    const adStorageConsent = config.advertisement
      ? ConsentStatesEnum.GRANTED
      : ConsentStatesEnum.DENIED;
    const analyticsStorageConsent = config.analytics
      ? ConsentStatesEnum.GRANTED
      : ConsentStatesEnum.DENIED;

    const consentData = {
      event: 'consent_update',
      'gtm.consent': {
        ad_storage: adStorageConsent,
        analytics_storage: analyticsStorageConsent,
      },
    };

    (window as any).gtag_consent = {
      ad_storage: adStorageConsent,
      analytics_storage: analyticsStorageConsent,
    };

    (window as any).dataLayer.push(consentData);
  }
}
