import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-chevron-toggle',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './chevron-toggle.component.html',
  styleUrls: ['./chevron-toggle.component.scss'],
  animations: [
    trigger('chevronMove', [
      state(
        'closed',
        style({
          transform: 'translate(0, 0)',
          opacity: 1,
        })
      ),
      state(
        'open',
        style({
          transform: 'translate(0, 0)',
          opacity: 1,
        })
      ),
      transition(
        'closed => open',
        [style({ transform: '{{transformValue}}', opacity: 0.6 }), animate('300ms ease-out')],
        { params: { transformValue: 'translateX(0)' } }
      ),
      transition(
        'open => closed',
        [style({ transform: '{{transformValue}}', opacity: 0.6 }), animate('300ms ease-out')],
        { params: { transformValue: 'translateX(0)' } }
      ),
    ]),
  ],
})
export class ChevronToggleComponent implements OnChanges {
  @Input() isOpen: boolean = false;
  @Input() direction: 'r' | 'l' | 't' | 'b' = 'r';
  @Input() size: number = 24;
  @Input() color: string = '#CE132E';
  @Input() isDisabled: boolean = false;
  @Input() containerClass: string = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isOpen && !changes.isOpen.isFirstChange()) this.isOpenChange();
  }

  private isOpenChange() {
    switch (this.direction) {
      case 'l':
        return (this.direction = 'r');
      case 'r':
        return (this.direction = 'l');
      case 't':
        return (this.direction = 'b');
      case 'b':
        return (this.direction = 't');
      default:
        return (this.direction = 'r');
    }
  }

  get transformValue(): string {
    switch (this.direction) {
      case 'l':
        return 'translateX(-4px)';
      case 'r':
        return 'translateX(4px)';
      case 't':
        return 'translateY(-4px)';
      case 'b':
        return 'translateY(4px)';
      default:
        return 'translateX(0)';
    }
  }

  get animationParams() {
    return { transformValue: this.transformValue };
  }

  get chevronPath(): string {
    switch (this.direction) {
      case 'l':
        return 'M16 4 L10 12 L16 20';
      case 'r':
        return 'M8 4 L14 12 L8 20';
      case 't':
        return 'M4 16 L12 10 L20 16';
      case 'b':
        return 'M4 8 L12 14 L20 8';
      default:
        return '';
    }
  }
}
