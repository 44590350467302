export const INSURANCE_PRICE: number = 2.8;

export const INSURANCE_INFO = [
  'Cobertura médica de hasta $40,000.',
  'Cobertura cancelación e interrupción de viajes hasta $250.',
  'Pérdida de pasaporte $100.',
  'Demora de equipaje hasta $100.',
  'Pérdida de equipaje complementaria hasta $800.',
] as const;

export const INSURANCE_DETAILS = [
  { description: 'Asistencia médica en caso de accidente hasta', coverage: 'USD 40,000' },
  {
    description: 'Asistencia médica en caso de enfermedad no preexistente (incluye COVID), hasta',
    coverage: 'USD 40,000',
  },
  {
    description:
      'Primera asistencia médica en caso de enfermedad preexistente y dolencias crónicas, hasta',
    coverage: 'USD 10,000',
  },
  { description: 'Medicamentos ambulatorios, hasta', coverage: 'USD 250' },
  { description: 'Odontología de urgencia, hasta', coverage: 'USD 300' },
  { description: 'Prótesis y órtesis, hasta', coverage: 'USD 1,000' },
  { description: 'Práctica de deportes amateur, hasta', coverage: 'USD 5,000' },
  {
    description: 'Estado de embarazo (primera atención de urgencia hasta semana 26), hasta',
    coverage: 'USD 1,500',
  },
  { description: 'Terapia de recuperación física en caso de traumatismo', coverage: 'Incluido' },
  { description: 'Traslado y repatriación sanitaria o de restos mortales', coverage: 'USD 40,000' },
  { description: 'Acompañamientos de menores o mayores', coverage: 'Incluido' },
  {
    description:
      'Regreso de acompañante del titular repatriado y/o familiar en caso de hospitalización',
    coverage: 'Ticket clase turista',
  },
  {
    description:
      'Estadía de un familiar en caso de hospitalización superior (USD x día / Tope USD)',
    coverage: 'USD 80 / USD 400',
  },
  {
    description: 'Diferencia de tarifa por viaje de regreso retrasado o anticipado',
    coverage: 'USD 80',
  },
  {
    description:
      'Reembolso de gastos por vuelo demorado (a partir de las 4 hrs. del retraso), hasta',
    coverage: 'USD 50',
  },
  {
    description: 'Reembolso de gastos de reprogramación de vuelo por COVID, hasta',
    coverage: 'USD 100',
  },
  {
    description:
      'Viaje de regreso por fallecimiento, por enfermedad de un familiar o siniestro grave en domicilio',
    coverage: 'Ticket clase turista',
  },
  { description: 'Cancelación e interrupción de viaje, hasta', coverage: 'USD 250' },
  {
    description: 'Ingreso a sala VIP por vuelo demorado o cancelado, hasta',
    coverage: 'No aplica',
  },
  {
    description: 'Reembolso de gastos de cancelación y reprogramación de viaje, hasta',
    coverage: 'USD 300',
  },
  { description: 'Compra protegida (shopping), hasta', coverage: 'USD 100' },
  {
    description:
      'Reemb. de gastos de hotel por reposo forzoso y/o convalecencia y/o estancia de acompañante, luego de una hospitalización (USD x día / Tope)',
    coverage: 'USD 80 / USD 400',
  },
  { description: 'Pérdida de pasaporte', coverage: 'USD 100' },
  {
    description:
      'Asistencia legal por accidente / adelanto en concepto de préstamos (anticipo de fondos para fianzas), hasta',
    coverage: 'USD 1,500',
  },
  {
    description: 'Tecnología protegida: tablet, smartphone, cámara, notebook, e-book',
    coverage: 'No aplica',
  },
  {
    description: 'Indemnización por demora en la entrega de equipaje (más de 24 hrs), hasta',
    coverage: 'USD 100',
  },
  {
    description:
      'Asesoría, Localización y compensación por pérdida de equipaje (Complementario: USD x Kg / Tope USD)',
    coverage: '40 Kg / USD 800',
  },
  {
    description:
      'Seguro de accidentes personales (invalidez total y permanente / menores de 75 años)',
    coverage: 'No aplica',
  },
] as const;
