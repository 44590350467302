import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CustomButtonsModule } from '../../../../shared/components/moleculas/custom-buttons/custom-buttons.module';
import { FilterTabsModule } from '../../../../shared/components/moleculas/filter-tabs/filter-tabs.module';
import { PackageSectionModule, SectionWrapperModule } from '../../../../shared/components';
import { DestinationsModule } from '../destinations/destinations.module';
import { TravelPackageComponent } from './travel-package/travel-package.component';
import { TravelPackagesRoutingModule } from './travel-packages-routing.module';
import { DescriptionCardComponent, FaqComponent } from '../../components';

@NgModule({
  declarations: [TravelPackageComponent],
  imports: [
    CommonModule,
    TravelPackagesRoutingModule,
    FilterTabsModule,
    DestinationsModule,
    CustomButtonsModule,
    PackageSectionModule,
    SectionWrapperModule,
    DescriptionCardComponent,
    FaqComponent,
  ],
})
export class TravelPackagesModule {}
