import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IOpenPayInfoResponseData } from '../../api/api-checkout/models/rq-openpay';
import { GlobalComponent } from '../../shared/global';
import { Guid } from '../../shared/utils/shared-utils';

@Injectable({ providedIn: 'root' })
export class PasarelaService {
  private readonly apiUrl: string = '/api/payment';

  constructor(private readonly http: HttpClient) {}

  getInfoTypePayment(): Observable<IOpenPayInfoResponseData> {
    const params = new HttpParams().set('TrackingCode', GlobalComponent.transactionId || Guid());
    const url = `${this.apiUrl}/info?${params.toString()}`;
    return this.http.get<IOpenPayInfoResponseData>(url, { headers: { booking: 'true' } });
  }

  getOpenPayInstallments(bin: string): Observable<any> {
    const params = new HttpParams()
      .set('TrackingCode', GlobalComponent.transactionId)
      .set('Parameter.Bines', bin);
    const url = `${this.apiUrl}/openpay-installments?${params.toString()}`;
    return this.http.get<any>(url, { headers: { 'not-loading': 'true' } });
  }
}
