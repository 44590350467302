import { Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import {
  Directive,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { BreakpointService } from '../../services/breakpoints';

@Directive({
  selector: '[appResponsiveImage]',
})
export class ResponsiveImageDirective implements OnInit, OnChanges, OnDestroy {
  @Input() desktopSrc!: string;
  @Input() mobileSrc?: string;

  private isMobile = false;
  private breakpointSubscription!: Subscription;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly breakpointService: BreakpointService,
    private readonly el: ElementRef,
    private readonly renderer: Renderer2
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId))
      this.breakpointSubscription = this.breakpointService
        .isBreakpoint([Breakpoints.XSmall, Breakpoints.Small])
        .subscribe((matches) => {
          this.isMobile = matches;
          this.setSelectedSrc();
        });
    else this.renderer.setAttribute(this.el.nativeElement, 'src', this.desktopSrc); // SSR always use desktop image
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.desktopSrc) this.setSelectedSrc();
  }

  private setSelectedSrc() {
    const selectedSrc = this.isMobile && this.mobileSrc ? this.mobileSrc : this.desktopSrc;
    this.renderer.setAttribute(this.el.nativeElement, 'src', selectedSrc);
  }

  ngOnDestroy() {
    this.breakpointSubscription?.unsubscribe();
  }
}
