import { Component, ElementRef } from '@angular/core';
import { PATH_NAMES } from 'src/app/shared/constants';
import { SeoService } from 'src/app/shared/services';

@Component({
	selector: 'app-benefits',
	templateUrl: './benefits.component.html',
	styleUrls: [ './benefits.component.scss' ]
})
export class BenefitsComponent {
	
	conditionsCollapsed = true;

	constructor(private elementRef: ElementRef, 		
		private _seoService: SeoService
	) {
	}


	ngOnInit(): void {

		const path  = PATH_NAMES.BENEFITS;
		this._seoService.setSEOByPath(path);
	}

	


	scrollCondiciones() {
		this.conditionsCollapsed = false;

		const el = this.elementRef.nativeElement.querySelector('#condiciones');
		el.scrollIntoView({ behavior: 'smooth' });
	}
}
