import { Component, Input, ViewEncapsulation } from '@angular/core';
import { IIataDetail } from '../../models/destination.models';
import { PipesModule } from '../../../../shared/pipes/pipes.module';

@Component({
  selector: 'app-description-card',
  templateUrl: './description-card.component.html',
  styleUrls: ['./description-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [PipesModule],
})
export class DescriptionCardComponent {
  @Input() destinationContent: Partial<IIataDetail>;
  @Input() altImg: string = '';

  constructor() {}
}
