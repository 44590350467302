import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { INotificationModel } from '../../../../Models/notifications/notification.model';

@Component({
	selector: 'app-notifications-sheet',
	templateUrl: './notifications.component.html',
	styleUrls: [ './notifications.component.scss' ]
})
export class NotificationsComponent {

	constructor(public bottomSheetRef: MatBottomSheetRef,
							@Inject(MAT_BOTTOM_SHEET_DATA) public data: { notificationList: INotificationModel[] }) {
	}

	openLink(notification: INotificationModel, event: MouseEvent) {
		this.bottomSheetRef.dismiss({ notification });
		event.preventDefault();
	}
}
