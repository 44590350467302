import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Offer } from 'src/app/public/Models/offers/offers.model';
import { OffersService } from 'src/app/services/offers/offers.service';
import { auditTime, distinctUntilChanged, finalize, map, take } from 'rxjs/operators';
import { ConfigCatService } from '../../../../../../shared/services/config-cat.service';
import { TokenService } from '../../../../../../api/api-nmviajes/services/token.service';
import { ExchangeRate } from '../../../../../../api/api-checkout/models/rq-checkout-search';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-section-super-offers',
  templateUrl: './section-super-offers.component.html',
  styleUrls: ['./section-super-offers.component.scss', '../../../styles/sections.scss'],
})
export class SectionSuperOffersComponent implements OnInit, OnDestroy {
  protected _isBrowser: Boolean = false;
  private _flagSubscription = new Subscription();

  private itemsPerPage = 4;
  private totalItems = 8;
  private isMobileView = false;
  private incrementCount = 4;

  private _exchangeRate: ExchangeRate | null = null;
  private _exchangeSubscription = new Subscription();

  isLoading = false;
  placeholderItems: number[];

  indexTab = 1;

  internationalFlights: Offer[] = [];
  nationalFlights: Offer[] = [];

  internationalFlightsPag: Offer[] = [];
  nationalFlightsPag: Offer[] = [];

  showOffersNac = true;
  showOffersInt = true;
  limitReached = false;

  testData: any[] = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private offersService: OffersService,
    private _router: Router,
    private _configCatService: ConfigCatService,
    private _tokenService: TokenService
  ) {
    if (isPlatformBrowser(this.platformId)) this._isBrowser = true;
  }

  ngOnInit(): void {
    this.getExchangeRate();
    this.listLimitSubscription();

    if (this._isBrowser) {
      this.checkScreenSize();
      this.validateOnResize();
    }
  }

  private validateOnResize() {
    fromEvent(window, 'resize')
      .pipe(
        auditTime(100),
        map(() => window.innerWidth),
        distinctUntilChanged()
      )
      .subscribe(() => this.checkScreenSize());
  }

  private checkScreenSize() {
    this.isMobileView = window.innerWidth < 768;
    this.itemsPerPage = this.isMobileView ? 1 : 4;
    this.placeholderItems = Array(this.itemsPerPage)
      .fill(0)
      .map((x, i) => i);
  }

  private listLimitSubscription() {
    this._flagSubscription = this._configCatService.cardListLimitFlag$.subscribe((result) => {
      this.totalItems = result ? 12 : 8;
      this.offersNacInt();
    });
  }

  private updateItemsToShow() {
    this.nationalFlightsPag = this.nationalFlights
      .map((item) => this.addExchangeRateToOffer(item))
      .slice(0, this.itemsPerPage);
    this.internationalFlightsPag = this.internationalFlights
      .map((item) => this.addExchangeRateToOffer(item))
      .slice(0, this.itemsPerPage);
    this.limitReached = false;
  }

  private getExchangeRate() {
    this._exchangeSubscription = this._tokenService.exchangeRate$.subscribe((result) => {
      this._exchangeRate = result;
      this.nationalFlightsPag.forEach((item) => (item.CurrencyExchange = result));
      this.internationalFlightsPag.forEach((item) => (item.CurrencyExchange = result));
    });
  }

  private offersNacInt() {
    this.isLoading = true;
    this.offersService
      .getOffersNationalInternational()
      .pipe(
        take(1),
        finalize(() => {
          if (this._isBrowser) setTimeout(() => (this.isLoading = false), 800);
        })
      )
      .subscribe({
        next: (res) => {
          this.nationalFlights = res.Result.filter((item) => item.Type == 'NAC')
            .map((item) => this.addExchangeRateToOffer(item))
            .slice(0, this.totalItems);
          this.internationalFlights = res.Result.filter((item) => item.Type == 'INT')
            .map((item) => this.addExchangeRateToOffer(item))
            .slice(0, this.totalItems);

          this.showOffersNac = this.nationalFlights.length > 0;
          this.showOffersInt = this.internationalFlights.length > 0;

          this.updateItemsToShow();

          this.testData = this.nationalFlights.map((data) => ({
            imageUrl: data.Image,
            linkUrl: '/nuestras-agencias',
            altText: 'prueba',
          }));
        },
        error: () => {
          this.showOffersNac = false;
          this.showOffersInt = false;
        },
      });
  }

  updateItemsDisplayed() {
    if (!this.limitReached) {
      this.nationalFlightsPag = this.showMore(
        [...this.nationalFlightsPag],
        [...this.nationalFlights]
      );
      this.internationalFlightsPag = this.showMore(
        [...this.internationalFlightsPag],
        [...this.internationalFlights]
      );
    } else this.showLess();
  }

  private showMore(itemsToShow: Offer[], items: Offer[]): Offer[] {
    const newCount = itemsToShow.length === 1 ? 4 : itemsToShow.length + this.incrementCount;

    if (newCount >= this.totalItems) {
      itemsToShow = items
        .map((item) => this.addExchangeRateToOffer(item))
        .slice(0, this.totalItems);
      this.limitReached = true;
    } else itemsToShow = items.map((item) => this.addExchangeRateToOffer(item)).slice(0, newCount);

    return itemsToShow;
  }

  private showLess() {
    this.updateItemsToShow();
    this.scrollReset();
  }

  private addExchangeRateToOffer(item: Offer) {
    return {
      ...item,
      CurrencyExchange: this._exchangeRate,
    } as Offer;
  }

  private scrollReset() {
    if (this._isBrowser)
      document
        .querySelector('#sectionOffers')
        ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  trackByUserId(index: number, name: any): any {
    return name.DestinationCode;
  }

  trackByNameId(index: number, name: any): any {
    return name.DestinationCode;
  }

  ngOnDestroy() {
    this._flagSubscription.unsubscribe();
  }
}
