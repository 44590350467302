import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-card',
  templateUrl: './image-card.component.html',
})
export class ImageCardComponent implements OnInit {
  @Input() imageUrl!: string;
  @Input() linkUrl?: string;
  @Input() altText?: string;
  @Input() radius: number = 2;
  @Input() hasShadow: boolean = false;

  classList: string[] = [];

  get imgCardClasses(): string[] {
    const classes = [];
    if (this.hasShadow) classes.push('shadow');
    classes.push(`rounded-${Math.max(this.radius, 0)}`);
    return classes;
  }

  constructor() {}

  ngOnInit() {
    if (!this.imageUrl) console.warn('ImageCardComponent: imageUrl is required');
  }
}
