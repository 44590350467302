import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ScriptConfigModel } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  private readonly isBrowser = isPlatformBrowser(this.platformId);

  constructor(@Inject(PLATFORM_ID) private readonly platformId: Object) {}

  addScriptElement(scriptConfig: ScriptConfigModel) {
    if (!this.isBrowser) return;

    if (this.checkExistence(scriptConfig.url, scriptConfig.isNoscript)) {
      console.warn(`El script o noscript con URL "${scriptConfig.url}" ya existe.`);
      return;
    }

    this.createScriptElement(scriptConfig);
  }

  private checkExistence(url: string, isNoscript: boolean): boolean {
    if (isNoscript) {
      const existingNoscript = Array.from(document.getElementsByTagName('noscript')).find(
        (noscript) => noscript.innerHTML.includes(`src="${url}"`)
      );
      return !!existingNoscript;
    }

    const tagName = isNoscript ? 'noscript' : 'script';
    return !!document.querySelector(`${tagName}[src="${url}"]`);
  }

  private createScriptElement(scriptConfig: ScriptConfigModel) {
    const element = document.createElement(scriptConfig.isNoscript ? 'noscript' : 'script');

    if (scriptConfig.isNoscript) {
      element.innerHTML = `<iframe src="${scriptConfig.url}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      document.body.appendChild(element);
    } else if (element instanceof HTMLScriptElement) {
      if (scriptConfig.url) {
        element.src = scriptConfig.url;
        element.async = scriptConfig.async;
        element.defer = scriptConfig.defer;
      } else if (scriptConfig.text) {
        element.type = 'text/javascript';
        element.innerHTML = scriptConfig.text;
      }

      document.head.appendChild(element);
    }
  }
}
