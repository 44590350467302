import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { CryptoService } from 'src/app/Services/util/crypto.service';
import { toUp } from '../../../shared/utils';

@Component({
	selector: 'app-nuestras-agencias',
	templateUrl: './nuestras-agencias.component.html',
	styleUrls: [ './nuestras-agencias.component.scss' ]
})
export class NuestrasAgenciasComponent implements OnInit {
	agencias = [
		{
			id: 1,
			direccion: 'Av. 28 de Julio 1136 -',
			distrito: 'Miraflores',
			horario: 'Lun - Vie 10:00 am a 07:00 pm',
			correo: 'vacaciones@nmviajes.com',
			imagen: './assets/agencias/agencia_.png'
		},
		{
			id: 2,
			direccion: 'Av. José Pardo 801 -',
			distrito: 'Miraflores',
			horario: 'Lun - Vie 10:00 am a 07:00 pm',
			horario2: 'Sab 10:00 am - 6:00 pm',
			correo: 'vacaciones@nmviajes.com',
			imagen: './assets/agencias/Pardo.png'
		},
		{
			id: 3,
			direccion: 'Av. Primavera 1352 -',
			distrito: 'Monterrico - Surco',
			horario: 'Lun - Vie 09:00 am a 07:00 pm',
			horario2: 'Sab 10:00 am - 1:00 pm',
			correo: 'primavera@nmviajes.com',
			telefono: '745-7638',
			telefono2: '745-7639',
			imagen: './assets/agencias/Primavera.png'
		},
		{
			id: 4,
			direccion: 'Av. Caminos del Inca 257 tienda 215 -',
			distrito: 'Chacarilla, Surco',
			horario: 'Lun - Vie 10:00 am a 07:00 pm',
			horario2: 'Sab 10:00 am - 1:00 pm',
			correo: 'caminosdelinca@nmviajes.com',
			telefono: '',
			telefono2: '',
			imagen: './assets/agencias/CaminosDelInca.png'
		},
		{
			id: 5,
			direccion: 'Av. Javier Prado Este 5268 tienda 213 –',
			distrito: 'La Molina',
			horario: 'Lun - Vie 10:00 am a 07:00 pm',
			horario2: 'Sab 10:00 am - 1:00 pm',
			correo: 'fontana@nmviajes.com',
			telefono: '782-4812',
			telefono2: '',
			imagen: './assets/agencias/LaFontana.png'
		},
		{
			id: 6,
			direccion: 'Av. Alfredo Mendiola 25017 –',
			distrito: 'Independencia',
			horario: 'Lun - Dom 10:00 am a 10:00 pm',
			horario2: '',
			correo: 'vacaciones@nmviajes.com',
			telefono: '',
			telefono2: '',
			imagen: './assets/agencias/MegaPlaza.png'
		},
		{
			id: 7,
			direccion: 'Av. Rivera Navarrete 723 –',
			distrito: 'San Isidro',
			horario: 'Lun - Vie 10:00 am a 07:00 pm',
			horario2: '',
			correo: 'vacaciones@nmviajes.com',
			telefono: '',
			telefono2: '',
			imagen: './assets/agencias/SanIsidro.png'
		},
		{
			id: 8,
			direccion: 'Av. La Marina 2000 tienda 236-A 2do piso –',
			distrito: 'San Miguel',
			horario: 'Lun - Dom 10:00 am a 10:00 pm',
			horario2: '',
			correo: 'vacaciones@nmviajes.com',
			telefono: '',
			telefono2: '',
			imagen: './assets/agencias/SanMiguel.png'
		},
		{
			id: 9,
			direccion: 'Av. Faustino Sánchez Carrión 1029 (Ex Av. Pershing) –',
			distrito: 'Jesús María',
			horario: 'Lun - Vi de 10:00 a.m. - 7:00 p.m. / Sab de 10:00 a.m. - 2:00 p.m.',
			horario2: '',
			correo: 'vacaciones@nmviajes.com',
			telefono: '',
			telefono2: '',
			imagen: './assets/agencias/JM.webp'
		},
		{
			id: 10,
			direccion: 'Portal San Agustin 111, Plaza de Armas –',
			distrito: 'Arequipa',
			horario: 'Lun - Sab 08:30 a.m. - 08:00 p.m. / Dom 09:00 a.m. - 01:00 p.m.',
			horario2: '',
			correo: 'vacaciones@nmviajes.com',
			telefono: '',
			telefono2: '',
			imagen: './assets/agencias/AQP.webp'
		},
		{
			id: 11,
			direccion: 'Mall Aventura Chiclayo –',
			distrito: 'Chiclayo',
			horario: 'Lun - Dom de 10:00 a.m. a 10:00 p.m.',
			horario2: '',
			correo: 'vacaciones@nmviajes.com',
			telefono: '',
			telefono2: '',
			imagen: './assets/agencias/CIX.webp'
		}
	];

	distrito: string;
	direccion: string;
	direccion2: string;
	horario: string;
	horario2: string;
	correo: string;
	telefono: string;
	telefono2: string;
	btnActivo: number;
	map: number;
	imagen: string;
	isBrowser: Boolean = false;

	constructor(
			private _cryptoService: CryptoService,
			private router: Router,
			@Inject(PLATFORM_ID) private platformId: Object
	) {
		this.agenciaView(1);
	}

	ngOnInit(): void {
		if (isPlatformBrowser(this.platformId))
			this.isBrowser = true;

		if (this.isBrowser) {
			toUp();

			let userID: string = '';
			let user_existingCustomer: boolean = false;
			const credentials = localStorage.getItem('usuario');
			const bookings = localStorage.getItem('bookings');

			if (credentials) {
				const credentialsJson = JSON.parse(credentials);
				userID = this._cryptoService.encrypt(credentialsJson.email);

				if (bookings) user_existingCustomer = JSON.parse(bookings).length > 0;
			}

			(window as any).dataLayer = (window as any).dataLayer || [];
			(window as any).dataLayer.push({
				event: 'user_info',
				userID: userID,
				user_existingCustomer: user_existingCustomer
			});

			(window as any).dataLayer.push({
				event: 'virtualPageView',
				virtualPagePath: '/nuestras-agencias',
				virtualPageTitle: 'NMV: Nuestras agencias'
			});
		}
	}

	agenciaView(id: number) {
		this.agencias.filter((agencia: any) => {
			const agenciaId = agencia.id;
			if (agenciaId === id) {
				this.btnActivo = agencia.id;
				this.distrito = agencia.distrito;
				this.direccion = agencia.direccion;
				this.direccion2 = agencia.direccion2;
				this.horario = agencia.horario;
				this.horario2 = agencia.horario2;
				this.correo = agencia.correo;
				this.telefono = agencia.telefono;
				this.telefono2 = agencia.telefono2;
				this.map = agencia.id;
				this.imagen = agencia.imagen;
			}
		});
	}

	onVerMasClick() {
		if (this.btnActivo === 1) this.router.navigate([ '/28-de-julio/' ]);
	}

	portalAyuda() {
		if (this.isBrowser) window.open('https://ayuda.nmviajes.com/support/home', '_blank');
	}
}
