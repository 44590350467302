import { Component } from '@angular/core';
import { ChatCardState } from './enum/chat-card-state.enum';

@Component({
  selector: 'app-chat-launcher',
  templateUrl: './chat-launcher.component.html',
  styleUrls: ['./chat-launcher.component.scss'],
})
export class ChatLauncherComponent {
  isChatButtonVisible: boolean = true;
  chatCardState: ChatCardState = ChatCardState.Hidden;
  backdropClass: string = '';

  toggleVisibilityPanel() {
    this.isChatButtonVisible = !this.isChatButtonVisible;
    if (!this.isChatButtonVisible) this.toggleChatCard(true);
  }

  toggleChatCard(forceClose: boolean = false) {
    if (forceClose) this.closeChatCard();
    else {
      const shouldOpen = this.chatCardState !== ChatCardState.Visible;
      shouldOpen ? this.openChatCard() : this.closeChatCard();
    }
  }

  private openChatCard() {
    this.chatCardState = ChatCardState.Showing;
    setTimeout(() => (this.chatCardState = ChatCardState.Visible));
    this.updateBackdropClass(true);
  }

  private closeChatCard() {
    this.chatCardState = ChatCardState.Showing;
    setTimeout(() => (this.chatCardState = ChatCardState.Hidden), 250);
    this.updateBackdropClass(false);
  }

  private updateBackdropClass(isVisible: boolean) {
    this.backdropClass = isVisible
      ? 'offcanvas-backdrop bg-black bg-opacity-50 vh-100'
      : 'offcanvas-backdrop bg-transparent';

    if (!isVisible) setTimeout(() => (this.backdropClass = ''), 250);
  }

  get isChatCardVisible(): boolean {
    return this.chatCardState === ChatCardState.Visible;
  }

  get isChatCardRendered(): boolean {
    return this.chatCardState !== ChatCardState.Hidden;
  }
}
