import { BreakpointObserver } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DestinyService } from '../../../../services/destiny/destiny.service';
import {
  ENmWebElementTypes,
  ENmWebSectionTypes,
  ENmWebsTypes,
  INmWeb,
  NmWebSection,
} from '../../../../shared/constants/nm-webs.model';
import { ConfigCatService } from '../../../../services/labs/config-cat.service';
import { NmWebsService } from '../../services/nm-webs.service';
import {
  IAirlinesSection,
  IIataDetail,
  IInfoCardSection,
  ISeoFaq,
} from '../../models/destination.models';

@Component({
  selector: 'app-nm-airlines',
  templateUrl: './airline.component.html',
  styleUrls: ['./airline.component.scss'],
})
export class AirlineComponent implements OnInit {
  destinations: string = '';
  airline: INmWeb;
  bannerSection: NmWebSection | undefined;
  descriptionSection: NmWebSection | undefined;
  descriptionSectionShow: Partial<IIataDetail> | undefined;
  faqSection: NmWebSection[] = [];
  faqSectionShow: ISeoFaq[] = [];
  airlinesCards: NmWebSection[] = [];
  airlinesIntCards: NmWebSection[] = [];
  airlinesCardsShow: IAirlinesSection[] = [];
  airlinesInterCardsShow: IAirlinesSection[] = [];
  airlinesCardsContentShow: IAirlinesSection[] = [];
  airlinesInterCardsContentShow: IAirlinesSection[] = [];
  popularRoutesSection: NmWebSection | undefined;
  isLoading = false;
  unsubscribe$ = new Subject<void>();
  isBrowser = false;
  slug: string = '';
  destinationAlt: string = '';
  bannerImageRender = '';
  bannerImageUrl = '';
  bannerImageMobileUrl = '';
  indexTab = 1;
  limitReached = false;
  itemsPerPage = 4;
  incrementCount = 4;
  itemsToShow = 4;
  informationCardSection: NmWebSection[] = [];
  informationCard: IInfoCardSection[] = [];

  seoTitle: string = '';
  seoDescription: string = '';

  constructor(
    private readonly titleService: Title,
    private readonly metaService: Meta,
    private readonly nmWebsService: NmWebsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly _configCatService: ConfigCatService,
    private readonly destinationService: DestinyService,
    private readonly breakpointObserver: BreakpointObserver
  ) {
    if (isPlatformBrowser(this.platformId)) this.isBrowser = true;
  }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.slug = params.routename || '';
      if (this.slug) {
        this.loadData(ENmWebsTypes.AIRLINE, this.slug);
      } else {
        this.router.navigateByUrl('/').then(() => null);
      }
    });

    if (this.isBrowser) {
      this.breakpointBackground();
    }
  }

  onTabChange(event: NgbNavChangeEvent<any>) {
    this.limitReached = false;
    this.itemsToShow = 4;
    if (event.nextId === 1) {
      this.updateDisplayedItems('nacional');
      return;
    }
    this.updateDisplayedItems('internacional');
  }

  breakpointBackground() {
    this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result) => {
      if (result.matches) {
        this.bannerImageRender = this.bannerImageMobileUrl;
      } else {
        this.bannerImageRender = this.bannerImageUrl;
      }
    });
  }

  loadData(name: string, slug: string) {
    this.isLoading = true;
    this.nmWebsService
      .getWebByNameAndSlug(name, slug)
      .pipe(take(1))
      .subscribe({
        next: (web) => this.handleWebData(web),
        error: (err) => this.handleError(err),
      });
  }

  private handleWebData(web: INmWeb) {
    this.airline = web;
    this.destinations = web?.destinationIata ?? '';
    if (isPlatformBrowser(this.platformId)) {
      if (web?.autoAction) {
        this.destinationService.vueloHotelValues$.next({
          destination: web?.destinationCity,
          origin: web?.originIata,
        });
      }
    }

    this.applyMetatags(web);

    if (web?.webId) {
      this.nmWebsService.getNmWebSectionByWebId(web.webId).subscribe({
        next: (sections) => this.handleSections(sections),
        error: (err) => this.handleError(err),
      });
    } else {
      this.router.navigate(['/']);
    }
  }

  applyMetatags(web: INmWeb) {
    this.seoTitle = web.metaTitle;
    this.seoDescription = web.metaDescription;
    if (isPlatformBrowser(this.platformId)) {
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      this.setTitleAndMeta(web.metaTitle, web.metaDescription);
      this.setCanonicalUrl(baseUrl);
      this.setCanonicalLinkUrl(baseUrl);
    }
  }

  updateDisplayedItems(typeLocation: string) {
    this.setGeneralAirlines(typeLocation);
    this.limitReached = this.itemsToShow >= this.airlinesCardsShow.length;
  }

  setGeneralAirlines(typeLocation: string) {
    if (typeLocation === 'nacional') {
      this.airlinesCardsContentShow = this.airlinesCardsShow.slice(0, this.itemsToShow);
    } else {
      this.airlinesInterCardsContentShow = this.airlinesInterCardsShow.slice(0, this.itemsToShow);
    }
  }

  updateItemsDisplayed(typeLocation: string) {
    this.itemsToShow = this.limitReached
      ? this.itemsPerPage
      : this.itemsToShow + this.incrementCount;
    this.updateDisplayedItems(typeLocation);
  }

  private handleSections(sections: NmWebSection[]) {
    this._configCatService.setCardListLimitFlag(!!this.airline);
    this.airline.sections = sections;
    this.bannerSection = this.findAndSortSection(sections, ENmWebSectionTypes.SECTION_SEARCHBAN);
    const bannerImgSection = this.findAndSortSection(
      sections,
      ENmWebSectionTypes.SECTION_SCHBAN_MOBILE
    );
    this.bannerImageMobileUrl = bannerImgSection?.nmWebElements[0]?.nmMedia?.url ?? '';
    this.bannerImageUrl = this.bannerSection?.nmWebElements[0]?.nmMedia?.url ?? '';
    this.descriptionSection = this.findSection(sections, ENmWebSectionTypes.SECTION_DESCRIPTION);
    this.informationCardSection = this.findSections(
      sections,
      ENmWebSectionTypes.SECTION_CARD_DETAIL
    );
    this.informationCard = this.extractInformationCardSection(this.informationCardSection);
    this.descriptionSectionShow = this.extractDescriptionSection(this.descriptionSection);
    this.destinationAlt = this.descriptionSectionShow?.bannerTitulo ?? '';
    this.faqSection = this.findSections(sections, ENmWebSectionTypes.SECTION_FAQ);
    this.faqSectionShow = this.extractFaqSection(this.faqSection);
    this.airlinesCards = this.findSections(sections, ENmWebSectionTypes.SECTION_CARD);
    this.airlinesIntCards = this.findSections(sections, ENmWebSectionTypes.SECTION_CARD_INT);
    this.airlinesCardsShow = this.extractAirlinesSection(this.airlinesCards);
    this.airlinesInterCardsShow = this.extractAirlinesSection(this.airlinesIntCards);

    this.updateDisplayedItems('nacional');
    this.updateDisplayedItems('internacional');

    this.setDefaultTab();

    if (this.breakpointObserver.isMatched('(max-width: 768px)')) {
      this.bannerImageRender = this.bannerImageMobileUrl;
    } else {
      this.bannerImageRender = this.bannerImageUrl;
    }
    this.popularRoutesSection = this.findSection(
      sections,
      ENmWebSectionTypes.SECTION_POPULAR_ROUTES
    );
    this.isLoading = false;
  }

  setDefaultTab() {
    if (!this.airlinesCardsContentShow.length && this.airlinesInterCardsContentShow.length) {
      this.indexTab = 2;
    } else if (this.airlinesCardsContentShow.length && !this.airlinesInterCardsContentShow.length) {
      this.indexTab = 1;
    }
  }

  extractInformationCardSection(sections: NmWebSection[]): IInfoCardSection[] {
    return sections
      .map((section) => {
        if (section.nmWebElements?.length) {
          return {
            urlImage:
              section.nmWebElements.find(
                (element) => element.nmGenericType?.name === ENmWebElementTypes.ELEMENT_IMAGE
              )?.nmMedia?.url ?? '',
            detail:
              section.nmWebElements.find(
                (element) => element.nmGenericType?.name === ENmWebElementTypes.ELEMENT_BODY
              )?.content ?? '',
            altImage:
              section.nmWebElements.find(
                (element) => element.nmGenericType?.name === ENmWebElementTypes.ELEMENT_ALT
              )?.content ?? '',
          } as IInfoCardSection;
        }
        return undefined;
      })
      .filter((infoCard): infoCard is IInfoCardSection => infoCard !== undefined);
  }

  extractAirlinesSection(sections: NmWebSection[]) {
    return sections
      .map((section) => {
        if (section.nmWebElements.length) {
          return {
            sectionId: section.sectionId,
            title:
              section.nmWebElements.find(
                (element) => element.nmGenericType?.name === ENmWebElementTypes.ELEMENT_TITLE
              )?.content ?? '',
            subTitle:
              section.nmWebElements.find(
                (element) => element.nmGenericType?.name === ENmWebElementTypes.ELEMENT_BODY
              )?.content ?? '',
            link:
              section.nmWebElements.find(
                (element) => element.nmGenericType?.name === ENmWebElementTypes.ELEMENT_LINK
              )?.content ?? '',
            image:
              section.nmWebElements.find(
                (element) => element.nmGenericType?.name === ENmWebElementTypes.ELEMENT_IMAGE
              )?.nmMedia?.url ?? '',
            logo:
              section.nmWebElements.find(
                (element) => element.nmGenericType?.name === ENmWebElementTypes.ELEMENT_LOGO
              )?.nmMedia?.url ?? '',
          } as IAirlinesSection;
        }
        return undefined;
      })
      .filter((airline): airline is IAirlinesSection => airline !== undefined);
  }

  private findAndSortSection(sections: NmWebSection[], type: string) {
    const section = sections.find((section) => section.nmGenericType.name === type);
    if (section?.nmWebElements) {
      section.nmWebElements.sort((a, b) => (a?.elementOrder ?? 0) - (b?.elementOrder ?? 0));
    }
    return section;
  }

  private findSection(sections: NmWebSection[], type: string) {
    return sections.find((section) => section.nmGenericType.name === type);
  }

  private findSections(sections: NmWebSection[], type: string) {
    return sections.filter((section) => section.nmGenericType.name === type);
  }

  private extractDescriptionSection(section: NmWebSection | undefined) {
    if (!section) return undefined;
    return {
      bannerTitulo:
        section.nmWebElements.find(
          (element) => element.nmGenericType?.name === ENmWebElementTypes.ELEMENT_TITLE
        )?.content ?? '',
      bannerTexto:
        section.nmWebElements.find(
          (element) => element.nmGenericType?.name === ENmWebElementTypes.ELEMENT_BODY
        )?.content ?? '',
      bannerImagenUrl:
        section.nmWebElements.find(
          (element) => element.nmGenericType?.name === ENmWebElementTypes.ELEMENT_IMAGE
        )?.nmMedia?.url ?? '',
    };
  }

  private extractFaqSection(sections: NmWebSection[]) {
    return sections
      .map((section) => {
        if (section.nmWebElements.length) {
          return {
            pregunta:
              section.nmWebElements.find(
                (element) => element.nmGenericType?.name === ENmWebElementTypes.ELEMENT_TITLE
              )?.content ?? '',
            respuesta:
              section.nmWebElements.find(
                (element) => element.nmGenericType?.name === ENmWebElementTypes.ELEMENT_BODY
              )?.content ?? '',
          };
        }
        return undefined;
      })
      .filter((faq): faq is ISeoFaq => faq !== undefined);
  }

  private handleError(err: any) {
    this.isLoading = false;
    console.error('Error:', err);
  }

  setTitleAndMeta(title: string, description: string): void {
    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({ name: 'title', content: title });
  }

  setCanonicalUrl(baseUrl: string) {
    const canonicalUrl = `${baseUrl}${this.router.url}`;
    this.metaService.updateTag({ rel: 'canonical', href: canonicalUrl });
  }

  setCanonicalLinkUrl(baseUrl: string) {
    const canonicalUrl = `${baseUrl}${this.router.url}`;
    let link: HTMLLinkElement | null = document.querySelector('link[rel="canonical"]');

    if (link) {
      link.setAttribute('href', canonicalUrl);
    } else {
      link = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', canonicalUrl);
      document.head.appendChild(link);
    }
  }

  onRedirect(link?: string) {
    if (!!link && isPlatformBrowser(this.platformId)) {
      if (link.includes('http')) {
        window.open(link, '_blank');
      } else {
        this.router.navigate([link]);
      }
    }
  }
}
