import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DEVICES, PLATFORM_STORAGE_KEYS } from '../../constants';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class PlatformService {
	protected _isBrowser = false;
	private _mobile: number | null;
	private _device: number = DEVICES.WEB;

	constructor(@Inject(PLATFORM_ID) private _platformId: Object) {
		if (isPlatformBrowser(this._platformId)) this._isBrowser = true;
	}

	setMobile(value: number) {
		if (this._isBrowser) {
			sessionStorage.setItem(PLATFORM_STORAGE_KEYS.MOBILE, JSON.stringify(value));
			this._mobile = value;
		}
	}

	getMobile(): boolean {
		return !!sessionStorage.getItem(PLATFORM_STORAGE_KEYS.MOBILE);
	}

	setDevice(value: number) {
		if (this._isBrowser) {
			sessionStorage.setItem(PLATFORM_STORAGE_KEYS.DEVICE, JSON.stringify(value));
			this._device = value;
		}
	}

	getDevice(): number {
		const itemStr = sessionStorage.getItem(PLATFORM_STORAGE_KEYS.DEVICE);
		if (itemStr) {
			const item = Number.parseInt(itemStr);
			return !Number.isNaN(item) ? item : this._device;
		}
		return this._device;
	}
}
