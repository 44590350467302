import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[CardNumberFormat]',
})
export class CardNumberFormatDirective {
  @Input('CardNumberFormat') attr = true;

  constructor() {}

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    if (!this.attr) return;

    const input = event.target as HTMLInputElement;
    let trimmedValue = input.value.replace(/\s+/g, '');
    const sanitizedValue = trimmedValue.replace(/\D/g, '');
    input.value = this.formatCreditCardNumber(sanitizedValue);
  }

  private formatCreditCardNumber(value: string): string {
    const chunkSize = 4;
    const regex = new RegExp(`.{1,${chunkSize}}`, 'g');
    return value.match(regex)?.join(' ') || '';
  }
}
