import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { IPopularRoutes } from '../destino.models';

@Component({
	selector: 'app-routes-list',
	templateUrl: './routes-list.component.html',
	styleUrls: ['./routes-list.component.scss']
})
export class RoutesListComponent implements OnInit {
	@Input() popularRoutes: IPopularRoutes[] = [];
	@Input() destination: string;
	constructor(
		public route: Router,
		@Inject(PLATFORM_ID) private platformId: Object
	) {}

	ngOnInit(): void {}

	onRedirect(link?: string) {
		if (!!link && isPlatformBrowser(this.platformId)) {
			if (link.includes('http')) {
				window.open(link, '_blank');
			} else {
				this.route.navigate([link]);
			}
		}
	}
}
