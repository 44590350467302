import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Directive({
    selector: 'img[appImgDefault]'
  })

  export class DefaultImageDirective implements OnInit{
    // @Input() src:string;
    @Input() default:string;
    @Output() errorEvent = new EventEmitter<boolean>();

    constructor(@Inject(PLATFORM_ID) private _platformId: Object, private elementRef:ElementRef){}

    ngOnInit(): void {
    }

    @HostListener('error') cargarImgDefecto() {
      if (isPlatformBrowser(this._platformId)) {
        this.errorEvent.emit(true);
        const element = this.elementRef.nativeElement;
        element.src = this.default || '../../../assets/img/no-image.webp';
      }
      }

}


