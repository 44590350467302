import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotificacionErrorComponent } from './notificacion-error/notificacion-error.component';
import { NotificationComponent } from './notificacion-error/notification/notification.component';

@NgModule({
	declarations: [NotificacionErrorComponent, NotificationComponent],
	imports: [CommonModule],
	exports: [NotificacionErrorComponent, NotificationComponent]
})
export class NotificationModule {}
