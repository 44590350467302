import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { GoogleSignInUtils } from '../../shared/utils/google-sign-in-utils';
import { GoogleAccountPayload } from './model/google-account-payload.model';
import { SOCIAL_NETWORK_GOOGLE } from '../../shared/constants';
import { AccountsService } from '../user-account/accounts.service';
import { NotificationService } from '../notifications/notification.service';

declare var google: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleSignInService {
  constructor(
    private readonly accountService: AccountsService,
    private notificationService: NotificationService
  ) {}

  checkGoogle() {
    const check = setInterval(() => {
      if (typeof google !== 'undefined' && google.accounts) {
        clearInterval(check);
        this.initGoogleLogin();
      }
    }, 100);
  }

  private initGoogleLogin() {
    google.accounts.id.initialize({
      client_id: environment.googleAccountClientId,
      callback: (response: any) => this.handleGoogleSignIn(response),
    });

    if (!this.accountService.getUserStorage()) google.accounts.id.prompt();
  }

  private handleGoogleSignIn(response: any) {
    try {
      const decoded = GoogleSignInUtils.decodeIdToken(response);
      if (decoded)
        this.loginWithGoogle(
          decoded.given_name,
          decoded.family_name,
          decoded.email,
          decoded.sub,
          decoded.picture
        );
      else this.notificationService.showNotificacion('Error', 'Token inválido', 10);
    } catch (error) {
      this.notificationService.showNotificacion(
        'Error',
        'Error al procesar el token de Google',
        10
      );
    }
  }

  private loginWithGoogle(
    name: string,
    lastname: string,
    email: string,
    sub: string,
    photo: string
  ) {
    const payload: GoogleAccountPayload = {
      BusinessName: '',
      Email: email,
      FatherLastname: lastname,
      Firstname: name,
      IdSocialNetwork: sub,
      IsPerson: true,
      MotherLastname: '',
      Password: '',
      Ruc: '',
      SocialNetwork: SOCIAL_NETWORK_GOOGLE,
    };
    this.accountService.saveAccount(payload).subscribe({
      next: (response) => {
        if (response.Result.IsSuccess)
          this.accountService.guardarStorage(response.Result, photo, 'G');
      },
      error: () => this.notificationService.showNotificacion('Error', 'Error del servidor', 10),
    });
  }
}
