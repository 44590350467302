import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { EnumRequestApplications, EnumRequestCompanies } from '../../../api/api-correos/models';
import { IConfigurationsResponse } from '../models/destination.models';
import { INmWeb, NmWebSection } from '../../../shared/constants/nm-webs.model';
import { Guid } from '../../../shared/utils/shared-utils';
import { PlatformService } from '../../../services/platform/platform.service';

@Injectable({
  providedIn: 'root',
})
export class NmWebsService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly platformService: PlatformService
  ) {}

  getWebByNameAndSlug(name: string, slug: string) {
    const url = `${environment.nmConfigurations}/v1/api/NmWeb/GetWebByNameAndSlug/${name}/${slug}`;

    return this.httpClient
      .get<IConfigurationsResponse<INmWeb>>(url)
      .pipe(map((resp) => resp?.result ?? null));
  }

  getNmWebSectionByWebId(webId: number): Observable<NmWebSection[]> {
    const url = `${environment.nmConfigurations}/v1/api/NmWebSection/GetWebSectionByWebId`;
    const body = {
      trackingCode: Guid(),
      muteExceptions: true,
      caller: {
        company: EnumRequestCompanies.EXPERTIA,
        application: EnumRequestApplications.NMVIAJES,
        deviceId: this.platformService.getDevice().toString(),
        fromIP: '10.10.10.10',
        fromBrowser: 'Chrome',
      },
      parameter: {
        id: webId,
      },
    };

    return this.httpClient
      .post<IConfigurationsResponse<NmWebSection[]>>(url, body)
      .pipe(map((resp) => resp?.result ?? null));
  }
}
