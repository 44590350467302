import { IToggleCardModel } from '../../../../shared/models';

export const CookiesConfigOptsConstant: IToggleCardModel[] = [
  {
    configName: '',
    defaultValue: true,
    description:
      'Sirven para reconocerte cuando ingresas, guardas tus preferencias de configuración y ' +
      'protegen tu cuenta. No pueden deshabilitarse porque son necesarias para el ' +
      'funcionamiento de nuestro sitio.',
    hasSwitch: false,
    title: 'Cookies esenciales',
  },
  {
    configName: 'technical',
    defaultValue: true,
    description:
      'Son necesarias para garantizar el funcionamiento del sitio web y no pueden ser eliminadas. ' +
      'Se determinan en respuesta a las acciones hechas por usted, como sus preferencias de ' +
      'privacidad, login, solicitudes de servicios o relleno de formularios. Estas cookies no ' +
      'almacenan información identificatoria del usuario.',
    hasSwitch: true,
    title: 'Cookies técnicas',
  },
  {
    configName: 'functional',
    defaultValue: true,
    description:
      'Tienen como función una personalización más avanzada de la sesión del usuario que la que ' +
      'permiten las cookies técnicas.',
    hasSwitch: true,
    title: 'Cookies funcionales',
  },
  {
    configName: 'analytics',
    defaultValue: true,
    description:
      'Permiten ver el comportamiento de navegación de los visitantes en el sitio web, con el ' +
      'objetivo de mejorar la experiencia del usuario. Los datos capturados son agregados y ' +
      'anonimizados.',
    hasSwitch: true,
    title: 'Cookies analíticas',
  },
  {
    configName: 'advertisement',
    defaultValue: true,
    description:
      'Nos permiten entender tus preferencias para mostrarte productos y anuncios interesantes ' +
      'para ti.',
    hasSwitch: true,
    title: 'Cookies de publicidad personalizada',
  },
];
