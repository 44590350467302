import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import Hotjar from '@hotjar/browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService, OS } from 'ngx-device-detector';
import { combineLatest, fromEvent, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { NMRequest } from './public/Models/base/NMRequest';
import { DestinosService } from './public/pages/vuelos/commons/components/destinos/services/destinos.service';
import { AccountsService } from './services/accounts.service';
import { BusinessUnitService } from './services/businessUnit/business-unit.service';
import { NotificationService } from './services/notification.service';
import { PopupService } from './services/pop-up/popup.service';
import { DataPagePresenterService } from './services/presenter/data-page-presenter.service';
import { MainService, OptionsItem } from './services/presenter/main/main.service';
import { AdvertisementModalComponent } from './shared/components/moleculas/modals';
import { ToolbarService } from './shared/services';
import { ConfigCatService } from './shared/services/config-cat.service';
import { GoogleSignInUtils } from './shared/utils/google-sign-in-utils';

declare var google: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  protected _isBrowser = false;
  private _ipAddress: string = 'xyx';
  private unsubscribe$ = new Subject<any>();

  title = 'NMViajes';

  showAdvertisement = false;
  advertisementContent: string = '';
  options: OptionsItem[];
  isBookingRoute: boolean;
  whatsAppNumber = '51919294628';

  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private readonly _popUpSubject: PopupService,
    private readonly _accountService: AccountsService,
    private readonly _notification: NotificationService,
    private readonly configCatService: ConfigCatService,
    private readonly router: Router,
    private readonly modal: NgbModal,
    private readonly deviceDetector: DeviceDetectorService,
    private readonly toolbarService: ToolbarService,
    private readonly businessUnitService: BusinessUnitService,
    private readonly mainService: MainService,
    private readonly destinosService: DestinosService,
    public dataPagePresenterService: DataPagePresenterService
  ) {
    if (isPlatformBrowser(this._platformId)) this._isBrowser = true;

    this.configCatService.initClient();
    this.onRouteChange();
    this.getOptions();
  }

  ngOnInit(): void {
    if (this._isBrowser) {
      this.loadHotjar();
      this.initGoogleLogin();
      this.getBusinessUnit();
      this.setIpCliente();
      this.closePopups();
    }

    this.configAdvertisement();
    this.getWhatsAppTesting();
    this.getListOfDestinos();
  }

  private onRouteChange() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((event) => {
        const parsed = event as NavigationEnd;
        if (this.deviceDetector.isMobile() && parsed.url.includes('resultados')) {
          this.configCatService.getAppAdFlag().then((value) => {
            if (value) this.openAdModal();
          });
        }

        this.isBookingRoute = parsed.url.includes('booking');
        this.validateShowAd();
      });
  }

  private getListOfDestinos() {
    this.destinosService.getDestinations().subscribe({
      next: (data) => this.destinosService.currentDestinos$.next(data),
      error: (err) => console.error(err),
    });
  }

  private configAdvertisement() {
    this.toolbarService.advertisementContent$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((content: string) => {
        this.advertisementContent = content;
        this.validateShowAd();
      });
  }

  private validateShowAd() {
    const currentRoute = this.router.url.split('?')[0];
    this.showAdvertisement =
      !!this.advertisementContent &&
      (currentRoute === '/' ||
        currentRoute.includes('resultados') ||
        currentRoute === '/vuelos' ||
        currentRoute === '/hoteles' ||
        currentRoute === '/vuelohotel' ||
        currentRoute === '/paquetes' ||
        currentRoute === '/seguro-viaje');
    this.toolbarService.showAdvertisement$.next(this.showAdvertisement);
  }

  private openAdModal() {
    let redirectionUrl: string;
    if (this.deviceDetector.os === OS.IOS)
      redirectionUrl = 'https://apps.apple.com/pe/app/nm-viajes/id6503088852';
    else
      redirectionUrl =
        'https://play.google.com/store/apps/details?id=com.expertiaNmviajes.app&pcampaignid=web_share';

    const modalRef = this.modal.open(AdvertisementModalComponent, {
      centered: true,
      modalDialogClass: 'rounded-lg',
    });
    modalRef.componentInstance.bannerSrc = 'assets/ads/download-app-ad.webp';
    modalRef.componentInstance.title = 'Encuentra vuelos más baratos en nuestra App';
    modalRef.componentInstance.description =
      'Sigue tu búsqueda en la App y encuentra precios más bajos';
    modalRef.componentInstance.denyText = 'Ahora no';
    modalRef.componentInstance.acceptText = 'Ir a la App';

    modalRef.closed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      if (this._isBrowser) window.open(redirectionUrl, '_blank');
    });
  }

  private initGoogleLogin() {
    google.accounts.id.initialize({
      client_id: environment.googleAccountClientId,
      callback: (response: any) => this.handleGoogleSignIn(response),
    });

    if (!this._accountService.getUserStorage()) google.accounts.id.prompt();
  }

  private handleGoogleSignIn(response: any) {
    const decoded = GoogleSignInUtils.decodeIdToken(response);
    this.loginWithGoogle(
      decoded.given_name,
      decoded.family_name,
      decoded.email,
      decoded.sub,
      decoded.picture
    );
  }

  private loginWithGoogle(
    name: string,
    lastname: string,
    email: string,
    sub: string,
    photo: string
  ) {
    const payload: any = {
      Firstname: name,
      FatherLastname: lastname,
      MotherLastname: '',
      Email: email,
      Password: '',
      IsPerson: true,
      Ruc: '',
      BusinessName: '',
      SocialNetwork: 'G',
      IdSocialNetwork: sub,
    };
    this._accountService.saveAccount(payload).subscribe({
      next: (response) => {
        if (response.Result.IsSuccess)
          this._accountService.guardarStorage(response.Result, photo, 'G');
      },
      error: () => this._notification.showNotificacion('Error', 'Error del servidor', 10),
    });
  }

  private loadHotjar() {
    const siteId = 3837481;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion, {
      debug: !environment.production,
    });
  }

  private getWhatsAppTesting() {
    this.configCatService
      .getWhatsAppAITestingFlag()
      .then((flag) => (flag ? (this.whatsAppNumber = '51908930305') : this.resetWhatsAppNumber()))
      .catch(() => this.resetWhatsAppNumber());
  }

  private resetWhatsAppNumber() {
    this.whatsAppNumber = '51919294628';
  }

  private closePopups() {
    if (this._isBrowser) {
      combineLatest([fromEvent(document, 'click'), this._popUpSubject.state()])
        .pipe(filter((resp) => resp[1].open))
        .subscribe((resp) => {
          if (this._isBrowser) {
            const htmlSelected = resp[0].target as HTMLElement;
            const popUpElement = document.getElementById(resp[1].id);

            if (htmlSelected.contains(popUpElement)) {
              this._popUpSubject.closePopUp('');
            }
          }
        });
    }
  }

  private getBusinessUnit() {
    if (localStorage.getItem('businessunit') === null) {
      let payload = new NMRequest();

      this.businessUnitService.businessUnit(payload).subscribe(
        (data) => {
          let info = data['Resultado'].filter(
            (und: any) => und.id_unidad_negocio == environment.undidadNegocioAC
          );
          localStorage.setItem('businessunit', info.length > 0 ? JSON.stringify(info[0]) : '');
        },
        (err) => console.error(err)
      );
    }
  }

  private getOptions() {
    this.options = this.mainService.menu;
  }

  private setIpCliente() {
    localStorage.setItem('ipCliente', this._ipAddress);
  }

  ngOnDestroy() {
    this.closePopups();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
