import { IBrevoContactModel } from '../models';

export const BrevoInitialData: IBrevoContactModel = {
  email: '',
  emailBlacklisted: false,
  smsBlacklisted: false,
  listIds: [63],
  updateEnabled: false,
  attributes: {},
};
