import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CustomButtonsModule } from '../../../../shared/components/moleculas/custom-buttons/custom-buttons.module';
import { FilterTabsModule } from '../../../../shared/components/moleculas/filter-tabs/filter-tabs.module';
import { PackageSectionModule, SectionWrapperModule } from '../../../../shared/components';
import { HotelsRoutingModule } from './hotels-routing.module';
import { HotelComponent } from './hotel.component';
import { DescriptionCardComponent, FaqComponent } from '../../components';

@NgModule({
  declarations: [HotelComponent],
  imports: [
    CommonModule,
    HotelsRoutingModule,
    FilterTabsModule,
    CustomButtonsModule,
    PackageSectionModule,
    SectionWrapperModule,
    DescriptionCardComponent,
    FaqComponent,
  ],
})
export class HotelsModule {}
