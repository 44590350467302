import { animate, style, transition, trigger } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { concat, forkJoin, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { ParamsVuelos } from 'src/app/Component/home-page/resultados/models/resultados.interfaces';
import { EnumCabinsVuelos, EnumFlightType } from 'src/app/shared/components/tabs/tabs.models';
import { IDestinationDetails, IIataDetail, IPopularRoutes, ISeoFaq } from './destino.models';
import { DestinosService } from './services/destinos.service';
import { DestinyService } from '../../../../../../Services/destiny/destiny.service';

export interface IVueloDestino {
  IataCode: string;
  OriginCode: string;
  Origin: string;
  DestinationCode: string;
  Destination: string;
  Rate: number;
  Tax: number;
  Total: number;
  DateStart: string;
  DateEnd: string;
  SearchTime: string;
}

@Component({
  selector: 'app-destinos',
  templateUrl: './destinos.component.html',
  styleUrls: ['./destinos.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition('void => *', [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))]),
      transition('* => void', [style({ opacity: 0 })]),
    ]),
  ],
})
export class DestinosComponent implements OnInit, OnDestroy {
  protected isBrowser = false;
  private unsubscribe$ = new Subject<void>();
  private loadingSeoData: boolean = false;
  private popularRoutes: IPopularRoutes[] = [];
  private slug: string;
  private originIata: string;
  private destinationIata: string;

  public origen: string;
  public destino: string;
  public title: string;
  public subTitle: string;

  destinationContent: IIataDetail | null = null;
  seoFaq: ISeoFaq[];
  popularRoutesSection: IPopularRoutes[][] = [];
  informationCard: IDestinationDetails[] = [];
  limit: number = 2;
  vuelos: any;
  isLoading: boolean = true;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly titleService: Title,
    private readonly metaService: Meta,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly service: DestinosService,
    private readonly _router: Router,
    private readonly destinosService: DestinosService,
    private flightSearchService: DestinyService
  ) {
    if (isPlatformBrowser(this.platformId)) this.isBrowser = true;
  }

  ngOnInit(): void {
    this._activatedRoute.params.pipe(takeUntil(this.unsubscribe$)).subscribe((param) => {
      this.destinationIata = param.destination || '';
      this.originIata = param.origin || '';
      this.slug = param.slug || '';
      if (this.destinationIata && this.originIata) {
        this.loadCiudad(this.destinationIata);
        this.loadDestinationDetails(this.originIata, this.destinationIata);
        this.getLocationsByIata(this.originIata, this.destinationIata);
      } else if (this.slug) {
        this.loadDestinationDetailsBySlug(this.slug);
      }
    });
  }

  private loadDestinationDetails(origin: string, destination: string) {
    this.loadingSeoData = true;
    this.resetSeoData();
    this.destinosService
      .getDestinationByIata(origin, destination)
      .pipe(take(1))
      .subscribe({
        next: this.seedWebData.bind(this),
        error: () => (this.loadingSeoData = false),
      });
  }

  loadDestinationDetailsBySlug(slug: string) {
    this.loadingSeoData = true;
    this.resetSeoData();
    this.destinosService
      .getDestinationBySlug(slug)
      .pipe(take(1))
      .subscribe({
        next: this.seedWebDataBySlug.bind(this),
        error: () => (this.loadingSeoData = false),
      });
  }

  private seedWebData(destination: IIataDetail) {
    if (destination) {
      this.destinationContent = destination;
      this.getSectionsWebData(destination.id);
    }
  }

  private seedWebDataBySlug(destination: IIataDetail) {
    if (destination) {
      this.destinationContent = destination;
      this.loadCiudadByIatas(destination.originIata.trim(), destination.iata.trim());
      this.setMetaTags(destination);
      this.getSectionsWebData(destination.id);
      this.getLocationsByIata(destination.originIata.trim(), destination.iata.trim());
    }
  }

  private setMetaTags(destination: IIataDetail) {
    if (this.isBrowser) {
      this.setTitleAndMeta(destination.metaTitle, destination.metaDescription);
    }
  }

  private setTitleAndMeta(title: string, description: string): void {
    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({ name: 'title', content: title });
  }

  private getSectionsWebData(destinationId: number) {
    forkJoin({
      details: this.destinosService.getDestinationDetails(destinationId),
      questions: this.destinosService.getDestinationQuestions(destinationId),
      routes: this.destinosService.getDestinationRoutes(destinationId),
    })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ({ details, questions, routes }) => {
          this.informationCard = details || [];
          this.seoFaq = questions || [];
          this.popularRoutes = routes || [];
          this.popularRoutesSection = this.chunkArray(this.popularRoutes, 3);
          this.loadingSeoData = false;
        },
        error: () => (this.loadingSeoData = false),
      });
  }

  private resetSeoData() {
    this.destinationContent = null;
    this.informationCard = [];
    this.seoFaq = [];
    this.popularRoutes = [];
    this.popularRoutesSection = [];
  }

  private loadCiudadByIatas(origin: string, destination: string) {
    this.fetchVuelos(destination, origin);
  }

  private loadCiudad(destinationIata: string) {
    this.fetchVuelos(destinationIata);
  }

  private fetchVuelos(destination: string, origin?: string) {
    this.service
      .getVuelos(destination, origin)
      .pipe(
        map((c) => c.slice(0, 6)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: (data) => {
          this.isLoading = false;
          this.vuelos = data;
          this.origen = data[0]?.Origin || '';
          this.destino = data[0]?.Destination || '';
          this.title = `Vuelos desde ${this.origen} a ${this.destino}`;
          this.subTitle = `Mejores ofertas a ${this.destino} en los últimos 15 minutos`;
        },
        error: () => (this.isLoading = false),
      });
  }

  private chunkArray(array: any[], chunkSize: number): any[][] {
    return array.reduce((acc, curr, i) => {
      const index = Math.floor(i / chunkSize);
      acc[index] = acc[index] || [];
      acc[index].push(curr);
      return acc;
    }, []);
  }

  private generateParams(v: IVueloDestino) {
    return new ParamsVuelos(
      EnumFlightType.ida_vuelta.toString(),
      `${v.OriginCode} ${v.Origin}`,
      `${v.DestinationCode} ${v.Destination}`,
      v.DateStart,
      v.DateEnd,
      '1',
      '0',
      '0',
      EnumCabinsVuelos.economy
    );
  }

  buscarVuelo(vuelo: IVueloDestino) {
    const params = this.generateParams(vuelo);
    let url = '/resultados?rand=' + Math.round(Math.random() * 10000000000) + '&';
    url += `departureLocation=${params.departure}&arrivalLocation=${
      params.destination
    }&departureDate=${params.departureDate}&arrivalDate=${
      params.arrivalDate
    }&adults=${params.adults}&children=${params.children}&infants=${
      params.infants
    }&flightType=${params.flightType}&flightClass=${params.flightClass}&lang=ES&email=${params.email}`;
    localStorage.setItem('searchParams', url);
    this._router.navigateByUrl(url).then(() => null);
  }

  viewMoreOffers(more: boolean): void {
    this.limit = more ? 2 : this.vuelos.length;
  }

  private getLocationsByIata(originIata: string, destinationIata: string) {
    const origin$ = this.flightSearchService
      .getGeoTree(originIata)
      .pipe(map((data) => ({ type: 'origin', data })));
    const destination$ = this.flightSearchService
      .getGeoTree(destinationIata)
      .pipe(map((data) => ({ type: 'destination', data })));

    concat(origin$, destination$)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result.type === 'origin') {
          const origin = result.data.find((item) => item.aerocodiata === originIata)!;
          this.flightSearchService.setOrigin(origin.aerocodiata, origin.city, origin.country);
        } else {
          const destination = result.data.find((item) => item.aerocodiata === destinationIata)!;
          this.flightSearchService.setDestination(
            destination.aerocodiata,
            destination.city,
            destination.country
          );
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
