import { isPlatformBrowser, NgClass, NgSwitch, NgSwitchCase, UpperCasePipe } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MailingService } from '../../../../services/mailing/mailing.service';
import { NotificationService } from '../../../../services/notifications/notification.service';
import { ETypeNotification } from '../../../../shared/constants';
import { CustomButtonsModule } from '../../../../shared/components/moleculas/custom-buttons/custom-buttons.module';

@Component({
  selector: 'app-email-subscription',
  templateUrl: './email-subscription.component.html',
  styleUrls: ['./email-subscription.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgClass,
    NgSwitch,
    CustomButtonsModule,
    UpperCasePipe,
    ReactiveFormsModule,
    NgSwitchCase,
  ],
})
export class EmailSubscriptionComponent implements OnInit {
  @Input() title: string = '';
  @Input() confirmedTitle: string = '';
  currentStep: 'initial' | 'email' | 'confirmed' = 'initial';
  emailForm: FormGroup;
  loading: boolean = false;

  get email() {
    return this.emailForm.get('email');
  }

  constructor(
    private fb: FormBuilder,
    private mailingService: MailingService,
    private notification: NotificationService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }
  ngOnInit(): void {}

  proceedToEmail() {
    this.currentStep = 'email';
  }

  submitEmail() {
    if (this.emailForm.valid) {
      this.loading = true;
      let data = {
        email: this.email?.value,
        name: '',
        privacyPolicy: false,
        dataPolicy: false,
      };
      this.mailingService.createContact(data, 'VUELOS', true).subscribe({
        next: (response: any) => {
          this.loading = false;
          this.currentStep = 'confirmed';
        },
        error: (err) => {
          this.loading = false;
          if (isPlatformBrowser(this.platformId)) {
            window.scroll({ top: 0, behavior: 'smooth' });
            const isError = err?.code !== 'duplicate_parameter';
            this.notification.showNotificacion(
              isError ? 'Error' : '',
              isError
                ? 'Al parecer hubo un error al enviar su suscripción'
                : 'Usted ya se encuentra suscrito a nuestras promociones',
              5,
              isError ? ETypeNotification.ERROR : ETypeNotification.SUCCESS
            );
          }
        },
      });
    }
  }
}
