import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-input-group-field',
  templateUrl: './grouped-input-field.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class GroupedInputFieldComponent {
  @Input() controlName!: string;
  @Input() labelText = '';
  @Input() inputType = 'text';
  @Input() inputPlaceholder = '';
  @Input() inputCssClass = '';
  @Input() required = false;
  @Input() maxInputLength = 1000;
  @Input() htmlAutocomplete = '';
  @Input() inputMode = '';
  @Input() showValidState = false;
  @Input() validMessage = '';
  @Input() showInvalidState = false;
  @Input() invalidMessage = '';
  @Input() isCreditCardField = false;
  @Input() allowOnlyNumbers = false;
  @Input() allowOnlyLetters = false;
  @Input() prefixText = '';
  @Input() prefixIconSrc = '';

  onPaste(event: ClipboardEvent) {
    if (this.isCreditCardField) event.preventDefault();
  }
}
