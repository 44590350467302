import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DestinyService } from 'src/app/services/destiny/destiny.service';
import { NotificationService } from 'src/app/services/notification.service';
import { InputValidationService } from '../../../../../services/inputValidation.service';
import { ClassValueCalendar } from '../../calendar/calendar.models';
import { InputClassComponent } from '../../input-class/input-class.component';
import { InputRangeComponent } from '../../input-range/input-range.component';
import { PopUpPasajeroComponent } from '../../pop-up-pasajero/pop-up-pasajero.component';
import { DistributionObjectA } from '../../pop-up-pasajero/pop-up-pasajero.model';
import { ParamsVueloHotel, URLVueloHotel } from '../../tabs/tabs.models';

moment.locale('es');

@Component({
  selector: 'app-tab-vuelo-hotel',
  templateUrl: './tab-vuelo-hotel.component.html',
  styleUrls: ['./tab-vuelo-hotel.component.scss'],
})
export class TabVueloHotelComponent implements OnInit, OnDestroy {
  @ViewChild('popUp') popUpElement: PopUpPasajeroComponent | undefined;
  @ViewChild('childDates') childDates!: InputRangeComponent;
  @ViewChild('childClass') childClass!: InputClassComponent;

  form!: FormGroup;
  fromDate: NgbDate | null;
  citysOrigenSelect: Array<any> = [];
  citysDestinosSelect: Array<any> = [];
  origen: any;
  destino: any;
  toDate: NgbDate | null;
  loadingOrigen = false;
  loadingDestinos = false;

  distribution = '';
  distributionObject: DistributionObjectA;
  hoveredDate: NgbDate | null = null;
  private readonly unsubscribe$ = new Subject<void>();

  isSubmit = false;

  constructor(
    private readonly calendar: NgbCalendar,
    private readonly destineService: DestinyService,
    public formatter: NgbDateParserFormatter,
    private readonly notification: NotificationService,
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    public inputValidator: InputValidationService
  ) {
    this.createForm();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  ngOnInit(): void {
    this.destineService.vueloHotelValues$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.form.controls.origen.setValue(data.origin);
        this.form.controls.destino.setValue(data.destination);
        this.getCiudadesOrigen(this.getValueToSearch(data?.origin), 1);
        this.getCiudadesOrigen(this.getValueToSearch(data?.destination), 2);
      }
    });
  }

  getCiudadesOrigen(location: string, type: number) {
    if (type === 1) {
      this.loadingOrigen = true;
    } else {
      this.loadingDestinos = true;
    }
    this.getListCiudades(this.getValueToSearch(location), type);
  }

  getValueToSearch(location?: string) {
    try {
      return location ? location.split(',')[0] : '';
    } catch (error) {
      return '';
    }
  }

  createForm() {
    this.form = new FormGroup({
      clase: new FormControl('economy'),
      origen: new FormControl('', [Validators.required, Validators.minLength(3)]),
      destino: new FormControl('', [Validators.required, Validators.minLength(3)]),
    });
  }

  navigateToResponseUrl(url: string): void {
    if (isPlatformBrowser(this.platformId)) {
      window.open(url, '_blank');
    }
  }

  validateForm(field: string) {
    return this.form.controls[field].errors && this.isSubmit;
  }

  getErrorsForm(form: FormGroup): string[] {
    let errors: any[] = [];

    if (form.controls['origen'].invalid) {
      errors.push('El campo origen es obligatorio');
    }
    if (form.controls['destino'].invalid) {
      errors.push('El campo destino es obligatorio');
    }
    if (!this.toDate) {
      errors.push('La fecha final es requerido');
    }
    if (!this.fromDate) {
      errors.push('La fecha de inicio es requerido');
    }

    return errors;
  }

  public async searchVueloHotel() {
    this.isSubmit = true;
    const valueClass = this.childClass.getValues();
    const newValue = valueClass.flightClass == 0 ? 'economy' : 'business';
    const valuesDateRange = this.childDates.getValuesByHotel();
    this.form.controls.clase.setValue(newValue);
    this.toDate = valuesDateRange.arrivalDate;
    this.fromDate = valuesDateRange.departureDate;

    let errosInputs = this.getErrorsForm(this.form);

    if (errosInputs.length > 0) {
      this.notification.showNotificacion('Error', errosInputs.join(', '), 10);
      return;
    }

    let errorHabitaciones = this.popUpElement?.isValid();

    if (!errorHabitaciones?.isValid) {
      this.notification.showNotificacion(
        'Error',
        errorHabitaciones?.message || 'Error en las habitaciones'
      );
      return;
    }

    let url = this.getUrlVueloHotel();

    this.navigateToResponseUrl(url);
  }

  getParamsVueloHotel() {
    return new ParamsVueloHotel(
      this.fromDate,
      this.toDate,
      this.form,
      this.citysDestinosSelect,
      this.citysOrigenSelect
    ).getParams();
  }

  public getUrlVueloHotel(): string {
    let url: string;
    let params = this.getParamsVueloHotel();

    url = new URLVueloHotel(params, this.distribution).getUrl();
    return url;
  }

  autoComplete(e: any, type: number, typeSearch = 'FLIGHT_HOTEL') {
    let elemento = e.target;

    let value = elemento.value;
    if (value.length >= 3) {
      this.getListCiudades(value, type, typeSearch);
    }
  }

  getListCiudades(e: any, type: number, typeSearch = 'FLIGHT_HOTEL') {
    this.destineService.getDestinyPaqueteDinamico(e, typeSearch).subscribe(
      (data) => {
        if (type === 1) {
          this.citysOrigenSelect = data;
        } else {
          this.citysDestinosSelect = data;
        }
      },
      (err) => console.log(err),
      () => {
        if (type === 1) {
          this.loadingOrigen = false;
        } else {
          this.loadingDestinos = false;
        }
      }
    );
  }

  changeDate(value: ClassValueCalendar) {
    this.toDate = value.toDate;
    this.fromDate = value.fromDate;
  }
}
