import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonModule } from '../button/button.module';
import { InputClassModule } from '../input-class/input-class.module';
import { InputPassengersModule } from '../input-passengers/input-passengers.module';
import { InputRangeModule } from '../input-range/input-range.module';
import { FlightLocationsInputsComponent } from '../../molecules';
import { MultivueloComponent } from './multivuelo.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FlightLocationsInputsComponent,
    InputRangeModule,
    ButtonModule,
    InputClassModule,
    InputPassengersModule,
  ],
  exports: [MultivueloComponent],
  declarations: [MultivueloComponent],
  providers: [],
})
export class MultivueloModule {}
