import { HttpClient } from '@angular/common/http';
import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ResponseModelT } from 'src/app/shared/shared-models';
import { Guid, NmvModel } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification.service';
import { isPlatformBrowser } from '@angular/common';

class LoginPerson {
	constructor(
			public email = '',
			public password = ''
	) {
	}
}

export interface AuthDTO {
	IsSuccess: boolean;
	Message: string;
	Id: number;
	MotherLastname: string;
	FatherLastname: string;
	Firstname: string;
	Status: boolean;
	Email: string;
}

export interface UserStorage {
	email: string;
	name: string;
	id: number;
	image: string;
	socialNetwork: 'G' | 'F' | null;
}

@Injectable({
	providedIn: 'root'
})
export class AccountsService {
	private _isBrowser = false;

  saveGoogleAccount = new EventEmitter<any>();
	saveAppleAccount = new EventEmitter<any>();

	private userLogged = new BehaviorSubject<boolean>(false);

	private userConfirmate = new BehaviorSubject<boolean>(false);

	constructor(
			@Inject(PLATFORM_ID) private _platformId: Object,
		private _http: HttpClient,
		private _matSnackBar: MatSnackBar,
		private _notification: NotificationService
	) {
		if (isPlatformBrowser(this._platformId))
			this._isBrowser = true;
	}

	saveAccount(parameter: any): Observable<any> {
		const url = environment.urlNmviajesAccount + '/v1/api/Account/Signup';
		const data = {
			TrackingCode: Guid(),
			MuteExceptions: environment.muteExceptions,
			Caller: {
				Company: 'Expertia',
				Application: 'NMViajes'
			},
			Parameter: parameter
		};
		return this._http.post<any>(url, data).pipe(
			tap((res) => {
				if (res.Result.IsSuccess)
					this._matSnackBar.open(
						`Gracias por ${
							parameter.IsPerson ? 'registrarte' : 'registrar su empresa'
						} ${res.Result.Firstname} ${res.Result.FatherLastname}`,
						'OK',
						{
							verticalPosition: 'top',
							duration: 2000
						}
					);
				else this._notification.showNotificacion('Error', res.Result.Message || 'Ingrese datos correctos', 10);
			})
		);
	}

	signIn(login: LoginPerson, isPerson: boolean) {
		let payload: any;

		const parameter = {
			Username: login.email,
			Password: login.password,
			IsPerson: isPerson
		};

		const nmvModel = new NmvModel();

		payload = { ...nmvModel.getPayload() };

		payload.parameter = parameter;

		const url = environment.urlNmviajesAccount + '/v1/api/Account/Signin';

		return this._http.post<ResponseModelT<AuthDTO>>(url, payload).pipe(map((resp) => resp.Result));
	}

	confirmationAccount(UserId: string) {
		let payload: any;

		const parameter = {
			UserId
		};

		const nmvModel = new NmvModel();

		payload = { ...nmvModel.getPayload(), parameter };

		const url = environment.urlNmviajesAccount + '/v1/api/Account/Confirmation';

		return this._http.put<ResponseModelT<any>>(url, payload).pipe(map((resp) => resp.Result));
	}

	dispatchLogged(value: boolean) {
		this.userLogged.next(value);
	}

	dispatchConfirmate(value: boolean) {
		this.userConfirmate.next(value);
	}

	isLogged() {
		return this.userLogged.asObservable();
	}

	guardarStorage(usuario: AuthDTO, image?: string, socialNetwork?: 'G' | 'A') {
		if (this._isBrowser) {
			const user = {
				email: usuario.Email,
				name: usuario.Firstname + ' ' + usuario.FatherLastname,
				id: usuario.Id,
				image: image || '',
				socialNetwork: socialNetwork || null
			};

			localStorage.setItem('usuario', JSON.stringify(user));

			this.dispatchLogged(true);
		}
	}

	guardarImage(image: string) {
		if (this._isBrowser) {
			const user = this.getUserStorage();
			user.image = image;
			localStorage.setItem('usuario', JSON.stringify(user));
		}
	}

	getUserStorage(): UserStorage {
		if (this._isBrowser) {
			const userStr: string = localStorage.getItem('usuario') || '';

			return userStr.length > 0 ? JSON.parse(userStr) : '';
		}
		return {
			email: '', id: 0, image: '', name: '', socialNetwork: null
		} as UserStorage;
	}

	signOut(): void {
		if (this._isBrowser) {
			localStorage.removeItem('usuario');
			localStorage.removeItem('bookings');

			this.dispatchLogged(false);
		}
	}

	deleteAccount(Id: number) {
		let payload: any;

		const parameter = {
			Id
		};

		const nmvModel = new NmvModel();

		payload = { ...nmvModel.getPayload(), parameter };

		const url = environment.urlNmviajesAccount + '/v1/api/Account';

		return this._http.delete<ResponseModelT<any>>(url, { body: payload }).pipe(map((resp) => resp.Result));
	}

	passwordSend(Email: string) {
		let payload: any;

		const parameter = {
			Email
		};

		const nmvModel = new NmvModel();

		payload = { ...nmvModel.getPayload(), parameter };

		const url = environment.urlNmviajesAccount + '/v1/api/Account/PasswordSend';

		return this._http.post<ResponseModelT<any>>(url, payload).pipe(map((resp) => resp.Result));
	}

	getAccountToken(): any {
		if (this._isBrowser) {
			const userStorage: any = localStorage.getItem('usuario');

			if (userStorage) {
				const user: any = JSON.parse(userStorage);

				const url = `${environment.urlNmviajesAccount}/v1/api/Account/token?Parameter.email=${user.email}&TrackingCode=${Guid()}&MuteExceptions=${environment.muteExceptions}&Caller.Company=Expertia&Caller.Application=NMViajes`;
				return this._http.get(url).toPromise();
			}
		}
	}
}
