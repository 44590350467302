import { Component, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';
import { ISuggest } from '../../../../models';
import { DestinyService } from 'src/app/services/destiny/destiny.service';
import { ParamsAutos, URLAutos } from '../../tabs/tabs.models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InputValidationService } from 'src/app/shared/validators/inputValidation.service';
import * as moment from 'moment';
import { NewCalendarComponent } from '../../new-calendar/new-calendar.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-tab-autos',
  templateUrl: './tab-autos.component.html',
  styleUrls: ['../tab-paquetes/tab-paquetes.component.scss', './tab-autos.component.scss'],
})
export class TabAutosComponent {
  @ViewChild('calendarDeparture') calendarDeparture!: NewCalendarComponent;
  @ViewChild('calendarReturn') calendarReturn!: NewCalendarComponent;

  form!: FormGroup;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  countriesSearch: Array<ISuggest> = [];
  countriesSearchRecojo: Array<ISuggest> = [];
  viewInputRecojo = false;
  conductor: Array<any> = [
    { code: '21', name: '21' },
    { code: '22', name: '22' },
    {
      code: '23',
      name: '23',
    },
    { code: '24', name: '24' },
    { code: '25', name: '25+' },
  ];
  isChecked = true;

  constructor(
    private destineService: DestinyService,
    private _snackBar: MatSnackBar,
    public inputValidator: InputValidationService,
    private calendar: NgbCalendar,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.form = new FormGroup({
      destino: new FormControl(''),
      recojo: new FormControl(''),
      initHour: new FormControl(''),
      lastHour: new FormControl(''),
      conductor: new FormControl(''),
      checkDevolver: new FormControl(true),
    });
    this.form.controls['initHour'].patchValue('12:00');
    this.form.controls['lastHour'].patchValue('12:00');
  }

  autoComplete(e: any) {
    // let elemento = this.origen.nativeElement;
    let elemento = e.target;

    let value = elemento.value;

    if (value.length >= 3) {
      this.getListSuggestions(value, 1);
      //this.getListCiudades(value, typeSearch);
    }
  }

  autocompleteRecojo(e: any) {
    let elemento = e.target;

    let value = elemento.value;

    if (value.length >= 3) {
      this.getListSuggestions(value, 2);
      //this.getListCiudades(value, typeSearch);
    }
  }

  getListSuggestions(e: any, type: number) {
    this.destineService.getSuggest(e).subscribe(
      (data) => {
        if (type === 1) {
          this.countriesSearch = data;
        } else {
          this.countriesSearchRecojo = data;
        }
      },
      (err) => console.error(err)
    );
  }

  getParamsAutos() {
    return new ParamsAutos(
      this.fromDate,
      this.toDate,
      this.form,
      this.countriesSearch,
      this.countriesSearchRecojo
    ).getParams();
  }

  searchAuto(): void {
    const dateDeparture = this.calendarDeparture.getValues();
    const dateReturn = this.calendarReturn.getValues();
    this.fromDate = dateDeparture;
    this.toDate = dateReturn;
    const errors = this.validateTab();

    if (errors.length > 0) {
      this.openSnackBar(errors.join(' - '));
      return;
    }
    const url = this.getUrlAutos();
    this.navigateToResponseUrl(url);
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', {
      duration: 2000,
      panelClass: ['mat-toolbar', 'mat-warn'],
    });
  }

  validateTab() {
    const errors = [];

    if (this.form.controls['destino'].value == '') errors.push('El destino es requerido');

    if (this.form.controls['initHour'].value == '') errors.push('La hora de inicio es requerida');

    if (this.form.controls['lastHour'].value == '') errors.push('La hora de fin es requerida');

    if (!this.toDate) errors.push('La fecha final es requerida');

    if (!this.fromDate) errors.push('La fecha de inicio es requerida');

    if (this.fromDate && this.toDate) {
      let startDateStr = `${this.fromDate!.day.toString()}/${this.fromDate!.month.toString()}/${this.fromDate!.year.toString()}`;
      let endDateStr = `${this.toDate!.day.toString()}/${this.toDate!.month.toString()}/${this.toDate!.year.toString()}`;
      let startDate = moment(startDateStr, 'D/M/YYYY').format('YYYY-MM-DD');
      let endDate = moment(endDateStr, 'D/M/YYYY').format('YYYY-MM-DD');
      const startDateTime = new Date(startDate);
      const endDateTime = new Date(endDate);

      if (endDateTime < startDateTime)
        errors.push('La fecha final no puede ser menor a la fecha de inicio');
    }

    return errors;
  }

  navigateToResponseUrl(url: string): void {
    if (isPlatformBrowser(this.platformId)) window.open(url, '_blank');
  }

  public getUrlAutos() {
    let url: string;
    let params = this.getParamsAutos();
    url = new URLAutos(params).getUrl();
    return url;
  }

  changeChecked(): void {
    this.isChecked = !this.isChecked;
    this.form.controls.checkDevolver.setValue(this.isChecked);
    this.form.controls.recojo.setValue('');
    this.viewInputRecojo = !this.form.controls['checkDevolver'].value;
  }
}
