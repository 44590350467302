import { NgModule } from '@angular/core';
import { ImageResponsiveLoadDirective } from './image-responsive-load.directive';
import { DefaultImageDirective } from './imagen.directive';
import { OnlyNumberDirective } from './only-number.directive';
import { OnlyLettersDirective } from './only-letters.directive';
import { CardNumberFormatDirective } from './credit-card.directive';

@NgModule({
	imports: [],
	exports: [ DefaultImageDirective, ImageResponsiveLoadDirective, OnlyNumberDirective, OnlyLettersDirective, CardNumberFormatDirective ],
	declarations: [ DefaultImageDirective, ImageResponsiveLoadDirective, OnlyNumberDirective, OnlyLettersDirective, CardNumberFormatDirective ],
	providers: []
})
export class DirectivesModule {
}
