import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalComponent } from '../../global';
import { FareBreakDown as FareBreakDownUpSell, IUpSell } from '../../../api/api-checkout/models/rq-checkout-up-sell';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UpsellService {
	private _upsellList = new BehaviorSubject<IUpSell[]>([]);
	upsellList$ = this._upsellList.asObservable();

	private _selectedUpsell = new BehaviorSubject<IUpSell | null>(null);
	selectedUpsell$ = this._selectedUpsell.asObservable();

	constructor(private _httpClient: HttpClient) {
	}

	setUpsellList(value: IUpSell[]) {
		GlobalComponent.upSellGroup = value;
		this._upsellList.next(value);
	}

	setSelectedUpsell(value: IUpSell | null) {
		if (value) this.setPricingValuesByUpSell(value);
		this._selectedUpsell.next(value);
	}

	getDetailPricingByUpsell(upsell: IUpSell) {
		return this.setPricingValuesByUpSell(upsell);
	}

	getSelectedUpsell(): IUpSell | null {
		return this._selectedUpsell.value;
	}

	getUpsellByGroup() {
		const url = `${environment.urlApiMotorVuelos}/mv/up-sell`;

		const group = GlobalComponent.appGroupSeleted;
		const credentials = localStorage.getItem('usuario');

		const body = {
			group,
			groupId: group.id,
			segmentSelected: GlobalComponent.segmentSelected,
			brandedFareName: '',
			exchangeRate: GlobalComponent.appExchangeRate,
			email: credentials ? JSON.parse(credentials).email : '',
			totalFare: parseFloat(GlobalComponent.detailPricing.totalPay.toFixed(2)),
			gds: GlobalComponent.appGroupSeleted.gds.idGDS
		};
		const headers = new HttpHeaders()
				.set('Content-Type', 'application/json')
				.set('Authorization', `Bearer ${GlobalComponent.tokenMotorVuelo}`);

		return this._httpClient.post<IUpSell[]>(url, body, { headers }).pipe(
				map((response) => {
					if (response.length >= 1) {
						this.setUpsellList(response);
						GlobalComponent.upSellGroup = response;
						this.setSelectedUpsell(response[0]);
						GlobalComponent.appBooking.brandedFareName = response[0].name;
					}

					response.forEach(item => {
						item.includeHandBagDep = !!item.fareBreakDowns[0].equipaje.equipaje[0].cabina;
						item.includesHoldBagDep = !!item.fareBreakDowns[0].equipaje.equipaje[0].piezas;

						item.includeHandBagRet = GlobalComponent.appGroupSeleted.returns ?
								!!item.fareBreakDowns[0].equipaje.equipaje[1].cabina : false;
						item.includesHoldBagRet = GlobalComponent.appGroupSeleted.returns ?
								!!item.fareBreakDowns[0].equipaje.equipaje[1].piezas : false;
					});

					return response;
				})
		);
	}

	private setPricingValuesByUpSell(upsell: IUpSell | null) {
		let taxes = 0;
		let totalPay: number;
		let baseFareADT = 0;
		let baseFareINF = 0;
		let baseFareCNN = 0;
		let baseFareTotal = 0;

		upsell?.fareBreakDowns.forEach((item: FareBreakDownUpSell) => {
			if (item.equivalentCode == 'ADT') baseFareADT = item.baseFare;

			if (item.equivalentCode == 'CNN') baseFareCNN = item.baseFare;

			if (item.equivalentCode == 'INF') baseFareINF = item.baseFare;

			baseFareTotal += item.baseFare * item.quantity;
			taxes +=
					item.taxes.reduce(function(acc, obj) {
						return acc + obj.fareAmount;
					}, 0) * item.quantity;
		});

		totalPay = baseFareTotal + taxes + GlobalComponent.detailPricing.feeNMV + GlobalComponent.detailPricing.feePTA;

		GlobalComponent.detailPricing = {
			...GlobalComponent.detailPricing,
			baseFareADT,
			baseFareINF,
			baseFareCNN,
			taxes,
			totalPay
		};
		return GlobalComponent.detailPricing;
	}
}
