import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService, OS } from 'ngx-device-detector';
import { combineLatest, fromEvent, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { NMRequest } from './shared/models/base/NMRequest';
import { DestinosService } from './services/destination/destinos.service';
import { AccountsService } from './services/user-account/accounts.service';
import { NotificationService } from './services/notifications/notification.service';
import { PopupService } from './services/pop-up/popup.service';
import { DataPagePresenterService } from './services/presenter/data-page-presenter.service';
import { BusinessUnitService, MainService, OptionsItem } from './core/services';
import { AdvertisementModalComponent } from './shared/components/moleculas/modals';
import { ConfigCatService } from './services/labs/config-cat.service';
import { StorageKeysEnum } from './shared/constants';
import { StorageService } from './services/storage';
import { CookiesService } from './services/cookies';
import { CookieConfig } from './shared/models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly isBrowser = isPlatformBrowser(this.platformId);
  private readonly ipAddress: string = 'xyx';
  private readonly unsubscribe$ = new Subject<void>();

  title = 'NMViajes';

  options: OptionsItem[];
  isBookingRoute: boolean;
  whatsAppNumber = '51919294628';
  showCookiesConfig = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private readonly popupService: PopupService,
    private readonly accountsService: AccountsService,
    private readonly notificationService: NotificationService,
    private readonly configCatService: ConfigCatService,
    private readonly router: Router,
    private readonly modalService: NgbModal,
    private readonly deviceDetectorService: DeviceDetectorService,
    private readonly businessUnitService: BusinessUnitService,
    private readonly mainService: MainService,
    private readonly destinosService: DestinosService,
    private readonly storageService: StorageService,
    private readonly cookiesService: CookiesService,
    public readonly dataPagePresenterService: DataPagePresenterService
  ) {
    this.configCatService.initClient();
    this.getOptions();
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      // this.loadHotjar();
      this.getBusinessUnit();
      this.setIpCliente();
      this.closePopups();
      this.checkCookiesConfig();
    }

    this.onRouteChange();
    this.getWhatsAppTesting();
    this.getListOfDestinos();
  }

  private onRouteChange() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((event) => {
        const parsed = event as NavigationEnd;
        if (this.deviceDetectorService.isMobile() && parsed.url.includes('resultados')) {
          this.configCatService.getAppAdFlag().then((value) => {
            if (value) this.openAdModal();
          });
        }

        this.isBookingRoute = parsed.url.includes('booking');
      });
  }

  private getListOfDestinos() {
    this.destinosService.getDestinations().subscribe({
      next: (data) => this.destinosService.currentDestinos$.next(data),
      error: (err) => console.error(err),
    });
  }

  private checkCookiesConfig() {
    const cookiesConfig = this.storageService.getItem<CookieConfig>(StorageKeysEnum.COOKIES_CONFIG);
    this.showCookiesConfig = cookiesConfig === null;
    if (cookiesConfig) this.cookiesService.configCookies(cookiesConfig);
  }

  private openAdModal() {
    let redirectionUrl: string;
    if (this.deviceDetectorService.os === OS.IOS)
      redirectionUrl = 'https://apps.apple.com/pe/app/nm-viajes/id6503088852';
    else
      redirectionUrl =
        'https://play.google.com/store/apps/details?id=com.expertiaNmviajes.app&pcampaignid=web_share';

    const modalRef = this.modalService.open(AdvertisementModalComponent, {
      centered: true,
      modalDialogClass: 'rounded-lg',
    });
    modalRef.componentInstance.bannerSrc = 'assets/ads/download-app-ad.webp';
    modalRef.componentInstance.title = 'Encuentra vuelos más baratos en nuestra App';
    modalRef.componentInstance.description =
      'Sigue tu búsqueda en la App y encuentra precios más bajos';
    modalRef.componentInstance.denyText = 'Ahora no';
    modalRef.componentInstance.acceptText = 'Ir a la App';

    modalRef.closed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      if (this.isBrowser) window.open(redirectionUrl, '_blank');
    });
  }

  /* private loadHotjar() {
    const siteId = 3837481;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion, {
      debug: !environment.production,
    });
  } */

  private getWhatsAppTesting() {
    this.configCatService
      .getWhatsAppAITestingFlag()
      .then((flag) => (flag ? (this.whatsAppNumber = '51908930305') : this.resetWhatsAppNumber()))
      .catch(() => this.resetWhatsAppNumber());
  }

  private resetWhatsAppNumber() {
    this.whatsAppNumber = '51919294628';
  }

  private closePopups() {
    if (this.isBrowser) {
      combineLatest([fromEvent(document, 'click'), this.popupService.state()])
        .pipe(filter((resp) => resp[1].open))
        .subscribe((resp) => {
          if (this.isBrowser) {
            const htmlSelected = resp[0].target as HTMLElement;
            const popUpElement = document.getElementById(resp[1].id);

            if (htmlSelected.contains(popUpElement)) {
              this.popupService.closePopUp('');
            }
          }
        });
    }
  }

  private getBusinessUnit() {
    if (localStorage.getItem('businessunit') === null) {
      let payload = new NMRequest();

      this.businessUnitService.businessUnit(payload).subscribe(
        (data) => {
          let info = data['Resultado'].filter(
            (und: any) => und.id_unidad_negocio == environment.undidadNegocioAC
          );
          localStorage.setItem('businessunit', info.length > 0 ? JSON.stringify(info[0]) : '');
        },
        (err) => console.error(err)
      );
    }
  }

  private getOptions() {
    this.options = this.mainService.menu;
  }

  private setIpCliente() {
    localStorage.setItem('ipCliente', this.ipAddress);
  }

  ngOnDestroy() {
    this.closePopups();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
