export const environment = {
  production: false,
  productionEnv: false,
  // urlBase: 'https://gds.webfarefinder.com/ServiciosTarjetaAsistencia/api/',  // PRODUCCIÓN
  muteExceptions: false, // DESARROLLO
  nameAppAC: 'Intranet',
  identifierAC: 'NMViajes - Assist Card',
  codeEnvironmentAC: 'PROD/NMO/NMO',
  undidadNegocioAC: 13,
  dkAgenciaAC: '29581',
  subcodigoAgenciaAC: '1',
  sucursalAgenciaAC: '3',
  ptoventaAgenciaAC: '1',
  comisionistaAgenciaAC: '',
  apiIp: 'https://api.ipify.org/?format=json',
  today: (hoy: any) => {
    return `${hoy.getDay()}/${hoy.getMonth()}/${hoy.getFullYear()}`;
  },
  offerText: ' ¡Apúrate que las ofertas vuelan!',
  offerExpireDate: '2023-11-26 T 23:59:59', //YYYY-MM-DD T hh:mm:ss
  offerLink:
    'https://www.nmviajes.com/vuelos?utm_source=web&utm_medium=reloj&utm_campaign=blackweek&utm_id=blackweek&utm_term=reloj&utm_content=reloj',
  urlPaqueteDinamico: 'https://vacaciones.nmviajes.com/',
  urlPaqueteDinamicoNmViajes: 'https://nmviajes.paquetedinamico.com/',
  urlAutosNmViajes: 'https://autos.nmviajes.com/',
  firebase: {
    projectId: 'nm-viajes',
    appId: '1:1068048799100:web:d4c8ffb9c709136d8940f9',
    storageBucket: 'nm-viajes.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyDw9Pd5sBSKba1FuZyo_MaoKVhm5sX_JVM',
    authDomain: 'nm-viajes.firebaseapp.com',
    messagingSenderId: '1068048799100',
    measurementId: 'G-FE42H792WL',
  },
  nmConfigurations: 'https://serviciodev.nmviajes.com:9443/NMConfiguracionesAPI',
  googleAccountClientId:
    '1068048799100-8gm1gqcc2h1h74vf3ufgqjg03c3ohap9.apps.googleusercontent.com',
  reCaptchaPublicKey: '6Lc9CbsmAAAAANpAUZ5Ubxl2s25BwmhZVgp7Yrev',
  appleClientId: {
    clientId: 'com.nmviajes.www', // Client ID (Service ID)
    scope: 'name email', // Qué datos quieres pedir
    redirectURI: 'https://dev-nm.nmviajes-it.com', // URL de redirección
    usePopup: true, // Usa una ventana emergente en lugar de redirección completa
  },

  urlNmviajes: 'https://servicio.nmviajes.com:9443/homevuelos/v1/api',
  urlMeta: 'https://meta.nmviajes.com',
  //urlNmviajes: "http://localhost:12639/v1/api",
  metaSearchEngines: ['Kayak', 'turismocity', 'Viajala', 'WSSPIA'],

  urlLibro: 'https://servicio.nmviajes.com:9443/libroreclamacion',
  urlApiTickets: 'https://servicios.expertiatravel.com/widgetactividades',
  urlApiHotels: 'https://servicios.expertiatravel.com/widgethotel',
  urlNmviajesAccount: 'https://servicio.nmviajes.com:9443/ZonaPrivada',
  urlApiForms: 'https://serviciodev.nmviajes.com:9443/NMForms/api',
  //urlNewsletter: 'https://servicio.nmviajes.com:9443/suscripcion',
  brevoBaseUrl: 'https://api.brevo.com/v3',
  brevoTrackerBaseUrl: 'https://try.readme.io/https://in-automate.sendinblue.com/api/v2',
  brevoMAKey: 'uj51zjlc0abyke655fgtjf1f',
  brevoApiKey:
    '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',
  urlSeguros: 'https://servicio.nmviajes.com:9443/segurosrv/',
  urlZonaPrivada: 'https://servicio.nmviajes.com:9443/ZonaPrivada/',
  urlGeo: 'https://dev2-motorvuelos.nmviajes-it.com/mv',
  // urlNmForms: "https://localhost:7016/",
  //urlIframeMotorVuelos: "https://vuelos-dev.nmviajes.com/#/nmviajes/search/resultados",
  urlIframeMotorVuelos: 'https://dev-motorwl.nmviajes-it.com/#/nmviajes/search/resultados',
  //urlIframeMotorVuelos: "http://localhost:4200/#/nmviajes/search/resultados",
  satVuelosBaseUrl: 'https://services-motordevuelos.expertiatravel.com/APISatVuelos/v1/api',

  urlIframeMotorVuelosItinerary: 'https://vuelos.nmviajes.com/#/nmviajes/booking/itinerary',

  urlBase: 'http://10.75.131.17:10508/api/productoAsistencia/',
  url_api: 'https://gds.webfarefinder.com/ServiciosTarjetaAsistencia/api/',

  serverUrlApi: 'http://localhost:8080/api/',

  url_autos: 'https://autos.nmviajes.com/es/site/',

  urlApiPayment: 'https://pasarella.expertiatravel.com/serviciopasareladev',
  urlApiPasarela: 'https://pasarella.expertiatravel.com/serviciopasareladev/v1/api/',
  //urlApiPayment: "http://10.75.102.23:15001/pasarelaservicio",
  //urlApiPayment: 'http://localhost:30850',

  urlSuggest: 'https://suggest.agentcars.com/suggest/',

  urlApiCorreos: 'https://servicios.expertiatravel.com/NMMailVuelos',

  urlApiMotorVuelos: 'https://dev2-motorvuelos.nmviajes-it.com',

  //url Terms and Conditions - Checkout

  urlTermsConditions: 'https://www.nmviajes.com/terminos-condiciones',
  urlBookingConditions: 'https://www.nmviajes.com/condiciones-de-reserva',
  urlTravelDocuments: 'https://www.nmviajes.com/documentacion-viaje',

  urlBlog: 'https://blog.nmviajes.com',
  GDS: {
    AMADEUS: 0,
    SABRE: 1,
    KIU: 2,
    VIVAAIR: 4,
    KIWI: 5,
    SKY: 6,
    MULTITICKET: 7,
    AMADEUS_NDC: 21,
    LATAM: 22,
  },
  openPayConfiguration: {
    isSandBox: true,
    Id: 'mqxn16kmicqj2fde6fhi',
    ApiKey: 'pk_c74367b872364e91b3143c5d87d64bb8',
  },
  configCatSdkKey: 'configcat-sdk-1/RUTcCEmIhUSNszxfq-vVBQ/inNXoJJWDEedu0prwy3i-w',
  urlApiDatosCliente: 'https://servicio.nmviajes.com:9443/Ecommerce',
  resultsInactivityTime: 300,
  urlApiDynamicPackages: 'https://agilservice-dev.nmviajes-it.com/APIServiceTravelC',
  ocpApimSubscr: '',
};
