import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NguCarouselModule } from '@ngu/carousel';
import { CarouselModule } from 'primeng/carousel';
import { GalleriaModule } from 'primeng/galleria';
import { AereolineasModule } from 'src/app/shared/components/moleculas/aereolineas/aereolineas.module';
import { ButtonModule } from '../../../../shared/components/moleculas/button/button.module';
import { CardPlaceholderModule } from '../../../../shared/components/moleculas/card-placeholder/card-placeholder.module';
import { CustomButtonsModule } from '../../../../shared/components/moleculas/custom-buttons/custom-buttons.module';
import { FlightSearchModule } from '../../../../shared/components';
import { MaterialModule } from '../../../../shared/material.module';
import { PipesModule } from '../../../../shared/pipes/pipes.module';
import { DestinationComponent } from './destination.component';
import { PopularRoutesComponent } from './popular-routes/popular-routes.component';
import {
  InfoCardComponent,
  DescriptionCardComponent,
  EmailSubscriptionComponent,
  FaqComponent,
} from '../../components';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [DestinationComponent, DestinationComponent, PopularRoutesComponent],
  imports: [
    CommonModule,
    MaterialModule,
    CarouselModule,
    GalleriaModule,
    AereolineasModule,
    NguCarouselModule,
    CustomButtonsModule,
    PipesModule,
    ReactiveFormsModule,
    FlightSearchModule,
    ButtonModule,
    CardPlaceholderModule,
    DescriptionCardComponent,
    InfoCardComponent,
    EmailSubscriptionComponent,
    FaqComponent,
  ],
  exports: [DestinationComponent, PopularRoutesComponent],
  providers: [],
})
export class DestinationsModule {}
