import { Component, Input, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { WHATSAPPCONSTANT } from 'src/app/shared/constant';
import { emailRequestIdle } from 'src/app/shared/utils/bodyEmailIdle';
import { Group } from 'src/app/api/api-checkout/models/rq-checkout-search';
import { ReengagementService } from './services';
import { isPlatformBrowser } from '@angular/common';
import { ContactsService } from '../../../services/contacts';
import { IBrevoContactModel } from '../../models';
import { BrevoInitialData, BrevoResponseCodesEnum } from '../../constants';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-idle-modal',
  templateUrl: './idle-modal.component.html',
  styleUrls: ['./idle-modal.component.scss'],
})
export class IdleModalComponent implements OnInit {
  @Input() firstTwoFlights: Group[] = [];

  protected isBrowser = false;
  form!: FormGroup;
  submitted = false;
  showMustCheck = false;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly reengagementService: ReengagementService,
    private readonly fb: FormBuilder,
    private readonly contactsService: ContactsService,
    public readonly activeModal: NgbActiveModal
  ) {
    if (isPlatformBrowser(this.platformId)) this.isBrowser = true;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      nombres: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      dataPolicy: new FormControl(false, Validators.requiredTrue),
      adsPolicy: new FormControl(false, Validators.requiredTrue),
    });
  }

  onSubmit() {
    this.submitted = true;
    this.showMustCheck = false;
    if (this.form.valid) {
      this.updateContact();
      this.sendEmail();
      this.activeModal.close();
    }
  }

  onOpenChat() {
    if (this.dataPolicy.valid || this.adsPolicy.valid) {
      window.open(`https://api.whatsapp.com/send/?phone=${WHATSAPPCONSTANT.phoneNumber}`, '_blank');
      return;
    }

    this.submitted = false;
    this.showMustCheck = true;
  }

  private sendEmail() {
    const data = this.form.getRawValue();
    const bodyEmail = emailRequestIdle(data.email, data.nombres, this.firstTwoFlights);
    this.reengagementService.sendEmail(bodyEmail).pipe(take(1)).subscribe();
  }

  private updateContact() {
    const data: IBrevoContactModel = {
      email: this.email.value,
      attributes: {
        CONTACTAR: this.dataPolicy.value,
        ENVIAR_PROMOS: this.adsPolicy.value,
      },
    };
    this.contactsService
      .updateContact(data)
      .pipe(take(1))
      .subscribe({
        error: (err) => {
          if (err?.code === BrevoResponseCodesEnum.NOT_FOUND) this.createContact();
        },
      });
  }

  private createContact() {
    const data: IBrevoContactModel = {
      ...BrevoInitialData,
      email: this.email.value,
      attributes: {
        EMAIL: this.email.value,
        NOMBRE: this.nombres.value,
        CONTACTAR: this.dataPolicy.value,
        ENVIAR_PROMOS: this.adsPolicy.value,
      },
    };
    this.contactsService.createContact(data).pipe(take(1)).subscribe();
  }

  get nombres() {
    return this.form.get('nombres') as FormControl;
  }

  get email() {
    return this.form.get('email') as FormControl;
  }

  get dataPolicy() {
    return this.form.get('dataPolicy') as FormControl;
  }

  get adsPolicy() {
    return this.form.get('adsPolicy') as FormControl;
  }
}
