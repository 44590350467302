import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SEO, PATH_NAMES } from '../../shared/constants';
import { SeoModel } from '../../shared/models/seo.model';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  private readonly isBrowser = isPlatformBrowser(this.platformId);

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly title: Title,
    private readonly meta: Meta
  ) {}

  setSEOByPath(path: string): void {
    const seoMap = new Map<string, any>([
      [PATH_NAMES.FLIGHTS, SEO.flights],
      [PATH_NAMES.HOTELS, SEO.hotels],
      [PATH_NAMES.FLIGHT_HOTEL, SEO.flightAndHotel],
      [PATH_NAMES.TOURIST_PACKAGES, SEO.touristPackages],
      [PATH_NAMES.TRAVEL_INSURANCE, SEO.travelInsurance],
      [PATH_NAMES.POLICIES, SEO.policies],
      [PATH_NAMES.BOOKING, SEO.booking],
      [PATH_NAMES.RESPONSIBILITY, SEO.responsability],
      [PATH_NAMES.DATE, SEO.date],
      [PATH_NAMES.COMPLAINTS, SEO.complaints],
      [PATH_NAMES.BENEFITS, SEO.benefits],
      [PATH_NAMES.JULY, SEO.july],
      [PATH_NAMES.COMPANY, SEO.company],
      [PATH_NAMES.DOCUMENT_TRAVEL, SEO.documentTravel],
      [PATH_NAMES.TERM_COND, SEO.termCond],
      [PATH_NAMES.PROMOTION, SEO.promotions],
      [PATH_NAMES.SUSCRIPTION, SEO.subscriptions],
      [PATH_NAMES.EVENTS, SEO.events],
      [PATH_NAMES.RECEPTION, SEO.reception],
      [PATH_NAMES.TRAVEL_OUT, SEO.traveloutlet],
      [PATH_NAMES.CANALES, SEO.canales],
      [PATH_NAMES.AGENCIES, SEO.agencies],
    ]);

    this.setSEOValues(seoMap.get(path) || SEO.home);
  }

  setSEOValues(seoData: SeoModel) {
    if (!this.isBrowser) return;

    this.title.setTitle(seoData.title);

    const fullUrl = `${this.document.location.origin}${seoData.url ?? ''}`;

    const metaTags = {
      title: seoData.title,
      description: seoData.description,
      'og:title': seoData.title,
      'og:description': seoData.description,
      'og:image': seoData.image,
      'og:image:height': seoData.height,
      'og:image:width': seoData.width,
      'og:url': fullUrl,
    };

    Object.entries(metaTags).forEach(([name, content]) =>
      this.meta.updateTag({ name, content: content ?? '' })
    );

    this.updateLinkTag('canonical', fullUrl);
    this.updateLinkTag('alternate', fullUrl);
  }

  private updateLinkTag(rel: string, href: string) {
    let link: HTMLLinkElement | null = this.document.querySelector(`link[rel='${rel}']`);

    if (!link) {
      link = this.document.createElement('link');
      link.setAttribute('rel', rel);
      this.document.head.appendChild(link);
    }

    link.setAttribute('href', href);
  }
}
