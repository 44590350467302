import { Injectable } from '@angular/core';
import { CryptoService } from '../../../Services/util/crypto.service';

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {

  constructor(private _cryptoService: CryptoService) {
  }

  sendVirtualPage(path: string, title: string) {
    (window as any).dataLayer.push({
      event: 'virtualPageView',
      virtualPagePath: path,
      virtualPageTitle: title
    });
  }

  sendUserInfo() {
    let userID: string = '';
    let user_existingCustomer: boolean = false;
    const credentials = localStorage.getItem('usuario');
    const bookings = localStorage.getItem('bookings');

    if (credentials) {
      const credentialsJson = JSON.parse(credentials);
      userID = this._cryptoService.encrypt(credentialsJson.email);

      if (bookings) user_existingCustomer = JSON.parse(bookings).length > 0;
    }

    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      event: 'user_info',
      userID: userID,
      user_existingCustomer: user_existingCustomer
    });
  }
}
