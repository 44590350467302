import { isPlatformBrowser, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { PATH_NAMES, SEO } from '../../../shared/constants';
import { IHomeBanner, IHomeBannerResponse } from '../../../shared/models/home-banner.model';
import { SeoService } from '../../../services/seo/seo.service';
import { TAB_INDEXES } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  private _isBrowser = false;

  setUrlPathFromTabIndex$: Subject<string> = new Subject<string>();

  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private _location: Location,
    private _seoService: SeoService,
    private _httpClient: HttpClient
  ) {
    if (isPlatformBrowser(this._platformId)) this._isBrowser = true;
  }

  getBannersHome(): Observable<IHomeBannerResponse<IHomeBanner[]>> {
    let url = `${environment.nmConfigurations}/v1/api/NmBanner/GetBanners`;
    return this._httpClient.get<IHomeBannerResponse<IHomeBanner[]>>(url).pipe(
      map((response) => {
        const sortedFilteredBanners = response.result
          .filter((banner) => banner.status)
          .sort((a, b) => a.position - b.position)
          .slice(0, 10);

        return {
          ...response,
          result: sortedFilteredBanners,
        };
      })
    );
  }

  setSelectedTabFromPath(path: string): number {
    this._seoService.setSEOByPath(path);

    if (path.includes(PATH_NAMES.HOT_SALE)) return TAB_INDEXES.HOT_SALE;

    switch (path) {
      case PATH_NAMES.FLIGHTS:
        return TAB_INDEXES.FLIGHTS;
      case PATH_NAMES.HOTELS:
        return TAB_INDEXES.HOTELS;
      case PATH_NAMES.FLIGHT_HOTEL:
        return TAB_INDEXES.FLIGHT_HOTEL;
      case PATH_NAMES.TOURIST_PACKAGES:
        return TAB_INDEXES.TOURIST_PACKAGES;
      case PATH_NAMES.TRAVEL_INSURANCE:
        return TAB_INDEXES.INSURANCE;
      default:
        return TAB_INDEXES.FLIGHTS;
    }
  }

  setUrlPathFromTabIndex(index: number) {
    switch (index) {
      case TAB_INDEXES.FLIGHTS:
        this._location.go(PATH_NAMES.FLIGHTS);
        this._seoService.setSEOValues(SEO.flights);
        this.setUrlPathFromTabIndex$.next(PATH_NAMES.FLIGHTS);
        break;
      case TAB_INDEXES.HOTELS:
        this._location.go(PATH_NAMES.HOTELS);
        this._seoService.setSEOValues(SEO.hotels);
        this.setUrlPathFromTabIndex$.next(PATH_NAMES.HOTELS);
        break;
      case TAB_INDEXES.FLIGHT_HOTEL:
        this._location.go(PATH_NAMES.FLIGHT_HOTEL);
        this._seoService.setSEOValues(SEO.flightAndHotel);
        this.setUrlPathFromTabIndex$.next(PATH_NAMES.FLIGHT_HOTEL);
        break;
      case TAB_INDEXES.TOURIST_PACKAGES:
        this._location.go(PATH_NAMES.TOURIST_PACKAGES);
        this._seoService.setSEOValues(SEO.touristPackages);
        this.setUrlPathFromTabIndex$.next(PATH_NAMES.TOURIST_PACKAGES);
        break;
      case TAB_INDEXES.INSURANCE:
        this._location.go(PATH_NAMES.TRAVEL_INSURANCE);
        this._seoService.setSEOValues(SEO.travelInsurance);
        this.setUrlPathFromTabIndex$.next(PATH_NAMES.TRAVEL_INSURANCE);
        break;
      case TAB_INDEXES.HOT_SALE:
        this._location.go(PATH_NAMES.HOT_SALE);
        this.setUrlPathFromTabIndex$.next(PATH_NAMES.HOT_SALE);
        break;
      default:
        this._location.go('/');
        this._seoService.setSEOValues(SEO.home);
        this.setUrlPathFromTabIndex$.next('/');
        break;
    }
  }
}
