import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-verified-user',
	templateUrl: './verified-user.component.html',
	styleUrls: [ './verified-user.component.scss' ]
})
export class VerifiedUserComponent implements OnInit {

	constructor(public activeModal: NgbActiveModal) {
	}

	ngOnInit(): void {
	}

	acceptConfirmation() {
		this.activeModal.close(true);
	}
}
