import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DestinyService } from 'src/app/services/destiny/destiny.service';
import { InputValidationService } from 'src/app/shared/validators/inputValidation.service';
import { NotificationService } from 'src/app/services/notifications/notification.service';
import { InputRangeComponent } from '../../input-range/input-range.component';
import { PopUpPasajeroComponent } from '../../pop-up-pasajero/pop-up-pasajero.component';
import { DistributionObjectA } from '../../pop-up-pasajero/pop-up-pasajero.model';
import { ParamsHoteles, URLHotel } from '../../tabs/tabs.models';

@Component({
  selector: 'app-tab-hotel',
  templateUrl: './tab-hotel.component.html',
  styleUrls: ['./tab-hotel.component.scss'],
})
export class TabHotelComponent implements OnInit, OnDestroy {
  @ViewChild('popUp') popUpElement: PopUpPasajeroComponent | undefined;
  @ViewChild('childDates') childDates!: InputRangeComponent;

  form: FormGroup = new FormGroup({
    destino: new FormControl('', [Validators.required, Validators.minLength(3)]),
  });
  fromDate: NgbDate | null;
  citys: Array<any> = [];
  origen: any;
  destino: any;
  toDate: NgbDate | null;
  unsubscribe$ = new Subject();

  distribution = '';

  distributionObject: DistributionObjectA;

  hoveredDate: NgbDate | null = null;

  isSubmit = false;

  get destinoControl(): FormControl {
    return this.form.get('destino') as FormControl;
  }

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly destineService: DestinyService,
    public formatter: NgbDateParserFormatter,
    private readonly notification: NotificationService,
    public inputValidator: InputValidationService
  ) {
    this.form = new FormGroup({
      destino: new FormControl('', [Validators.required, Validators.minLength(3)]),
    });
  }

  ngOnInit(): void {
    this.destineService.destinationHotel$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.destinoControl.setValue(data?.id);
        this.getListCiudades(data?.city);
      }
    });
  }

  validateForm(field: string) {
    return this.form.controls[field]?.errors && this.isSubmit;
  }

  getErrorsForm(form: FormGroup): string[] {
    let errors: any[] = [];

    if (form.controls['destino'].invalid) {
      errors.push('El campo destino es obligatorio');
    }
    if (!this.toDate) {
      errors.push('La fecha final es requerido');
    }
    if (!this.fromDate) {
      errors.push('La fecha de inicio es requerido');
    }

    return errors;
  }

  autoComplete(e: any, typeSearch = 'ONLY_HOTEL') {
    // let elemento = this.origen.nativeElement;
    let elemento = e.target;

    let value = elemento.value;

    if (value.length >= 3) {
      this.getListCiudades(value, typeSearch);
    }
  }

  getListCiudades(e: any, typeSearch = 'ONLY_HOTEL') {
    this.destineService.getDestinyPaqueteDinamico(e, typeSearch).subscribe(
      (data) => {
        this.citys = data;
      },
      (err) => console.error(err)
    );
  }

  navigateToResponseUrl(url: string): void {
    if (isPlatformBrowser(this.platformId)) window.open(url, '_blank');
  }

  public async searchAlojamiento() {
    this.isSubmit = true;
    const valuesDateRange = this.childDates.getValuesByHotel();
    this.toDate = valuesDateRange.arrivalDate;
    this.fromDate = valuesDateRange.departureDate;

    let errosInputs = this.getErrorsForm(this.form);

    if (errosInputs.length > 0) {
      this.notification.showNotificacion('Error', errosInputs.join(', '), 10);
      return;
    }

    let errorHabitaciones = this.popUpElement?.isValid();

    if (!errorHabitaciones?.isValid) {
      this.notification.showNotificacion(
        'Error',
        errorHabitaciones?.message || 'Error en las habitaciones'
      );
      return;
    }

    let url = this.getUrlAlojamiento();

    /*const result = await this._accountsService.getAccountToken();
    if (result) {
      if (result.Result.IsSuccess) {
        const token: string = result.Result.Token;
        url = `${url}&token=${token}&submit=true`;
      }
    }*/

    this.navigateToResponseUrl(url);
  }

  public getUrlAlojamiento() {
    let url: string;
    let params = this.getParamsAlojamiento();
    url = new URLHotel(params, this.distribution).getUrl();
    return url;
  }

  getParamsAlojamiento() {
    return new ParamsHoteles(this.fromDate, this.toDate, this.form, this.citys).getParams();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
