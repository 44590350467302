import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { of } from 'rxjs';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { Cintillo, IHomeBannerResponse } from '../../shared/models';
import { catchError, map, tap } from 'rxjs/operators';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';

interface CachedData {
  data: Cintillo;
  timestamp: number;
}

const CINTILLO_KEY = makeStateKey<CachedData>('cintillo-data');

@Injectable({
  providedIn: 'root',
})
export class CintilloService {
  private readonly isPlatformServer = isPlatformServer(this.platformId);
  private readonly isPlatformBrowser = isPlatformBrowser(this.platformId);

  content: string = '';
  visible: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly http: HttpClient,
    private readonly transferState: TransferState
  ) {}

  updateVisibility(visible: boolean) {
    this.visible = visible;
  }

  updateBanner(content: string, visible: boolean) {
    this.content = content;
    this.visible = visible;
  }

  clearBanner() {
    this.content = '';
    this.visible = false;
  }

  getData() {
    if (this.transferState.hasKey(CINTILLO_KEY)) {
      const cache = this.transferState.get(CINTILLO_KEY, null);
      const isFresh = cache && Date.now() - cache.timestamp < 10000;

      if (cache && isFresh) {
        if (this.isPlatformBrowser) this.transferState.remove(CINTILLO_KEY);
        return of(cache.data);
      }
    }

    let url = `${environment.nmConfigurations}/v1/api/Advertisements/GetAdvertisement`;

    return this.http
      .get<IHomeBannerResponse<Cintillo>>(url, {
        headers: { 'not-loading': 'true' },
      })
      .pipe(
        map((res) => res?.result || null),
        tap((result) => {
          if (this.isPlatformServer)
            this.transferState.set<CachedData>(CINTILLO_KEY, {
              data: result,
              timestamp: Date.now(),
            });
        }),
        catchError((err) => {
          if (!environment.production) console.error('Error at getting Cintillo data', err);
          return of(null);
        })
      );
  }
}
