import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '../../moleculas/button/button.module';
import { CardPlaceholderModule } from '../../moleculas/card-placeholder/card-placeholder.module';
import { CustomButtonsModule } from '../../moleculas/custom-buttons/custom-buttons.module';
import { PackageCardV2Component, PackageCardComponent } from './components';
import { PackageSectionComponent } from './package-section.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomButtonsModule,
    ButtonModule,
    CardPlaceholderModule,
  ],
  exports: [PackageSectionComponent],
  declarations: [PackageSectionComponent, PackageCardComponent, PackageCardV2Component],
  providers: [],
})
export class PackageSectionModule {}
