import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-advertisement-modal',
	templateUrl: './advertisement-modal.component.html',
	styleUrls: [ './advertisement-modal.component.scss' ]
})
export class AdvertisementModalComponent implements OnInit {
	@Input() bannerSrc: string;
	@Input() title: string;
	@Input() description: string;
	@Input() denyText: string;
	@Input() acceptText: string;

	constructor(public activeModal: NgbActiveModal) {
	}

	ngOnInit(): void {
	}

}
