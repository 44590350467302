import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlightSearchComponent } from './flight-search.component';
import { InputPassengersModule } from '../../../moleculas/input-passengers/input-passengers.module';
import { InputClassModule } from '../../../moleculas/input-class/input-class.module';
import { FlightLocationsInputsComponent } from '../../../molecules';
import { InputRangeModule } from '../../../moleculas/input-range/input-range.module';
import { ButtonComponent } from '../../../atoms';
import { MultivueloModule } from '../../../moleculas/multivuelo/multivuelo.module';

@NgModule({
  declarations: [FlightSearchComponent],
  imports: [
    CommonModule,
    InputPassengersModule,
    InputClassModule,
    FlightLocationsInputsComponent,
    InputRangeModule,
    ButtonComponent,
    MultivueloModule,
  ],
  exports: [FlightSearchComponent],
})
export class FlightSearchModule {}
