import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardPlaceholderComponent } from './card-placeholder.component';
import { CustomButtonsModule } from '../custom-buttons/custom-buttons.module';


@NgModule({
	declarations: [
		CardPlaceholderComponent
	],
	exports: [
		CardPlaceholderComponent
	],
	imports: [
		CommonModule,
		CustomButtonsModule
	]
})
export class CardPlaceholderModule {
}
