import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IPackageResponseModel } from '../models/packages-response.model';
import { IBaseResponseModel } from '../../../../models/base-response.model';
import { environment } from '../../../../../../environments/environment';
import { Guid } from '../../../../utils';

@Injectable({
  providedIn: 'root',
})
export class DynamicPackagesService {
  constructor(private readonly _httpClient: HttpClient) {}

  getPackages(
    first: number,
    limit: number,
    destinations?: string,
    filterByPriceAmount?: boolean
  ): Observable<IBaseResponseModel<IPackageResponseModel>> {
    let url: string = `${environment.urlApiDynamicPackages}/v1/api/blocking/nmviajes?Parameter.First=${first}&Parameter.Limit=${limit}&TrackingCode=${Guid()}&MuteExceptions=${environment.production}&Caller.Company=Expertia&Caller.Application=NMViajes&Caller.FromIP=&Caller.FromBrowser=`;
    if (destinations) {
      url += `&Parameter.Destinations=${destinations.trim()?.toLowerCase()}`;
    }
    if (typeof filterByPriceAmount === 'boolean') {
      url += `&Parameter.FilterTotalPriceAmount=${filterByPriceAmount}`;
    }
    return this._httpClient
      .get<IBaseResponseModel<IPackageResponseModel>>(url, {
        headers: { 'not-loading': 'true' },
      })
      .pipe(catchError((err) => throwError(err)));
  }
}
