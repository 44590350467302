import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { CustomSelectItem } from '../../../../models/custom-select-item.model';

@Component({
  selector: 'app-select-field',
  templateUrl: './select-field.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class SelectFieldComponent {
  @Input() htmlAutocomplete: string = '';
  @Input() controlName!: string;
  @Input() id?: string;
  @Input() options: CustomSelectItem[] = [];
  @Input() labelText = '';
  @Input() selectPlaceholder = '';
  @Input() required = false;
  @Input() showValidState = false;
  @Input() showInvalidState = false;
  @Input() invalidMessage: string;
  @Input() selectCssClass = '';

  constructor() {}

  trackByValue(_: number, option: CustomSelectItem): any {
    return option.value;
  }
}
