import { Component, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { IImageCardModel } from '../../../models';
import { BreakpointService } from '../../../../services/breakpoints';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-image-grid',
  templateUrl: './image-grid.component.html',
})
export class ImageGridComponent implements OnInit, OnDestroy {
  @Input() images: IImageCardModel[] = [];
  @Input() mobileItems: number = 1;
  @Input() desktopItems: number = 3;
  @Input() rowClass: string;
  @Input() radius: number = 2;
  @Input() hasShadow: boolean = false;
  @Input() showToggler: boolean = true;
  @Input() togglerLabels: string[] = ['Ver más', 'Ver menos'];
  @Input() scrollTargetId?: string;

  private readonly destroy$ = new Subject<void>();
  private isMobile: boolean = true;

  visibleItems: number = this.mobileItems;

  constructor(
    private readonly breakpointService: BreakpointService,
    private readonly viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.observeScreenSize();
  }

  private observeScreenSize() {
    this.breakpointService
      .isBreakpoint(Breakpoints.Handset)
      .pipe(takeUntil(this.destroy$))
      .subscribe((matches) => {
        this.isMobile = matches;
        this.visibleItems = this.isMobile ? this.mobileItems : this.desktopItems;
      });
  }

  get shouldShowToggler(): boolean {
    return (
      this.showToggler &&
      ((this.isMobile && this.images.length > 1) ||
        (!this.isMobile && this.images.length > this.desktopItems))
    );
  }

  toggleVisibility(): void {
    const allItemsVisible = this.visibleItems >= this.images.length;
    const defaultVisibleItems = this.isMobile ? this.mobileItems : this.desktopItems;

    if (allItemsVisible) {
      this.visibleItems = defaultVisibleItems;
      if (this.scrollTargetId) this.scrollToTarget();
      return;
    }

    this.visibleItems =
      this.isMobile && this.visibleItems < this.desktopItems
        ? this.desktopItems
        : this.images.length;
  }

  private scrollToTarget() {
    const parentElement = this.viewContainerRef.element.nativeElement.parentElement;
    const targetElement = parentElement?.querySelector(`#${this.scrollTargetId}`);

    if (targetElement) {
      const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY;
      const toolbarOffset = 70;

      window.scrollTo({ top: elementPosition - toolbarOffset, behavior: 'smooth' });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
