import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CampaignComponent } from './campaign.component';
import { RouterModule, Routes } from '@angular/router';
import {
  ImageGridModule,
  PackageSectionModule,
  SectionWrapperModule,
  BannerImageModule,
} from '../../../../shared/components';
import { DirectivesModule } from '../../../../shared/directives/directives.module';

const routes: Routes = [{ path: ':slug', component: CampaignComponent }];

@NgModule({
  declarations: [CampaignComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgOptimizedImage,
    ImageGridModule,
    PackageSectionModule,
    SectionWrapperModule,
    DirectivesModule,
    BannerImageModule,
  ],
})
export class CampaignsModule {}
