import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-banner-image',
  templateUrl: './banner-image.component.html',
})
export class BannerImageComponent {
  @Input() desktopSrc!: string;
  @Input() mobileSrc?: string;
  @Input() alt: string = 'Banner Image';
  @Input() loading: 'eager' | 'lazy' = 'lazy';
  @Input() link?: string;
  @Input() isExternal: boolean = false;
}
