import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookiesModalComponent } from './modal/cookies-modal.component';
import { Subscription } from 'rxjs';
import { Breakpoints } from '@angular/cdk/layout';
import { StorageService } from '../../../services/storage';
import { BreakpointService } from '../../../services/breakpoints';
import { CookiesService } from '../../../services/cookies';
import { CookieConfig } from '../../../shared/models';
import { StorageKeysEnum } from '../../../shared/constants';

@Component({
  selector: 'app-cookies-config',
  templateUrl: './cookies-config.component.html',
  styleUrls: ['./cookies-config.component.scss'],
  host: {
    class: 'position-fixed end-0 bottom-0 start-0',
    style: 'z-index: 1200',
  },
})
export class CookiesConfigComponent implements OnInit, OnDestroy {
  @Output() hide = new EventEmitter<void>();
  private subscription = new Subscription();
  isMobile = false;

  constructor(
    private readonly storageService: StorageService,
    private readonly modal: NgbModal,
    private readonly breakpointService: BreakpointService,
    private readonly cookiesService: CookiesService
  ) {}

  ngOnInit() {
    this.subscription = this.breakpointService
      .isBreakpoint(Breakpoints.Handset)
      .subscribe((value) => (this.isMobile = value));
  }

  acceptAllCookiesClick() {
    const config: CookieConfig = { all: true };
    this.storageService.setItem<CookieConfig>(StorageKeysEnum.COOKIES_CONFIG, config);
    this.cookiesService.configCookies(config);
    this.hide.emit();
  }

  openCookiesConfiguration() {
    this.modal.open(CookiesModalComponent, {
      backdrop: 'static',
      centered: true,
      fullscreen: 'sm',
      keyboard: false,
      scrollable: true,
      size: 'lg',
    });
    this.hide.emit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
