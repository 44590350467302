export const months = [
	{ name: 'Enero', value: 1 },
	{ name: 'Febrero', value: 2 },
	{ name: 'Marzo', value: 3 },
	{ name: 'Abril', value: 4 },
	{ name: 'Mayo', value: 5 },
	{ name: 'Junio', value: 6 },
	{ name: 'Julio', value: 7 },
	{ name: 'Agosto', value: 8 },
	{ name: 'Septiembre', value: 9 },
	{ name: 'Octubre', value: 10 },
	{ name: 'Noviembre', value: 11 },
	{ name: 'Diciembre', value: 12 }
];

export const days = [
	1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
];
export const cuotas = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
