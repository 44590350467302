import { NgModule } from '@angular/core';
import { ResponsiveImageDirective } from './responsive-image.directive';
import { DefaultImageDirective } from './imagen.directive';
import { OnlyNumberDirective } from './only-number.directive';
import { OnlyLettersDirective } from './only-letters.directive';
import { CardNumberFormatDirective } from './credit-card.directive';

@NgModule({
  declarations: [
    DefaultImageDirective,
    ResponsiveImageDirective,
    OnlyNumberDirective,
    OnlyLettersDirective,
    CardNumberFormatDirective,
  ],
  exports: [
    DefaultImageDirective,
    ResponsiveImageDirective,
    OnlyNumberDirective,
    OnlyLettersDirective,
    CardNumberFormatDirective,
  ],
  imports: [],
  providers: [],
})
export class DirectivesModule {}
