import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive({
	selector: '[appImageResponsiveLoad]'
})
export class ImageResponsiveLoadDirective implements OnInit, OnDestroy {
	@Input() mobileImage!: string;
	@Input() desktopImage!: string;

	private breakpointSubscription!: Subscription;

	constructor(
		private breakpointObserver: BreakpointObserver,
		private hostElement: ElementRef,
		@Inject(PLATFORM_ID) private platformId: Object
	) {}

	ngOnInit() {
		if (isPlatformBrowser(this.platformId)) {
			this.breakpointSubscription = this.breakpointObserver
				.observe([Breakpoints.Small, Breakpoints.XSmall])
				.subscribe((state: BreakpointState) => {
					if (state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]) {
						this.hostElement.nativeElement.src = this.mobileImage;
					} else {
						this.hostElement.nativeElement.src = this.desktopImage;
					}
				});
		}
	}

	ngOnDestroy() {
		this.breakpointSubscription?.unsubscribe();
	}
}
