import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

interface ItemWithExpiration<T = any> {
  value: T;
  expirationDate: number;
}

/**
 * Service to manage storage in `localStorage` securely,
 * compatible with SSR (Server-Side Rendering) and with support for value expiration.
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private readonly isBrowser = isPlatformBrowser(this.platformId);

  constructor(@Inject(PLATFORM_ID) private readonly platformId: Object) {}

  /**
   * Stores a value in localStorage.
   * @param key Key to identify the stored value.
   * @param value Value to store.
   */
  setItem<T>(key: string, value: T) {
    if (!this.isBrowser) return;
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Gets a localStorage value.
   * @param key Stored value key.
   * @returns Parsed value or null if it doesn't exist.
   */
  getItem<T>(key: string): T | null {
    if (!this.isBrowser) return null;
    const item = localStorage.getItem(key);
    return item ? this.safeParse<T>(item) : null;
  }

  /**
   * Removes a localStorage value.
   * @param key Key to identify the value to eliminate.
   */
  removeItem(key: string) {
    if (this.isBrowser) localStorage.removeItem(key);
  }

  /**
   * Stores a value with an expiration date in localStorage.
   * @param key Key to identify the stored value.
   * @param value Value to store.
   * @param seconds Time in seconds before it expires.
   */
  setItemWithExpiration<T>(key: string, value: T, seconds: number) {
    if (!this.isBrowser) return;
    const expirationDate = Date.now() + seconds * 1000;
    const item: ItemWithExpiration<T> = { value, expirationDate };
    this.setItem<ItemWithExpiration<T>>(key, item);
  }

  /**
   * Gets a localStorage value that may expire.
   * Elimina el valor si ya ha expirado.
   * @param key Stored value key.
   * @returns Parsed value or null if it doesn't exist.
   */
  getItemWithExpiration<T>(key: string): T | null {
    if (!this.isBrowser) return null;
    const item = this.getItem<ItemWithExpiration<T>>(key);
    if (!item) return null;

    if (Date.now() > item.expirationDate) {
      this.removeItem(key);
      return null;
    }
    return item.value;
  }

  private safeParse<T>(item: string): T | null {
    try {
      return JSON.parse(item);
    } catch {
      return null;
    }
  }
}
