import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-lightbox',
	templateUrl: './lightbox.component.html',
	styleUrls: [ './lightbox.component.scss' ]
})
export class LightboxComponent implements OnInit {
	@Input() showLightbox = false;
	@Input() imageSrc = '';
	@Output() close = new EventEmitter<void>();

	constructor() {
	}

	ngOnInit(): void {
	}

	closeLightbox() {
		this.showLightbox = false;
		this.close.emit();
	}
}
