import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReplacePipe } from '../../pipes/replace.pipe';
import { NewcardOfferComponent } from './newcard-offer.component';
import { CustomButtonsModule } from '../custom-buttons/custom-buttons.module';

@NgModule({
	imports: [ CommonModule, CustomButtonsModule ],
	exports: [ NewcardOfferComponent ],
	declarations: [ NewcardOfferComponent, ReplacePipe ],
	providers: []
})
export class NewCardOfferModule {
}
