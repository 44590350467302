import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LightboxComponent } from './lightbox.component';



@NgModule({
	declarations: [
		LightboxComponent
	],
	exports: [
		LightboxComponent
	],
	imports: [
		CommonModule
	]
})
export class LightboxModule { }
