import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-radio-button-field',
  templateUrl: './radio-button-field.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class RadioButtonFieldComponent {
  @Input() controlName!: string;
  @Input() radioValue!: string;
  @Input() id!: string;
  @Input() required = false;
  @Input() inputCssClass = '';
  @Input() labelCssClass = '';
  @Input() containerCssClass = '';

  @Output() selectionChange = new EventEmitter<boolean>();

  onSelectionChange(event: any) {
    this.selectionChange.emit(event.target.checked);
  }
}
