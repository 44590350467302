import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[OnlyLetters]',
})
export class OnlyLettersDirective {
  @Input('OnlyLetters') attr = true;

  private readonly regex: RegExp = new RegExp(/^[A-Za-zñÑáéíóúÁÉÍÓÚ ]+$/g);
  private readonly specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'Delete',
  ];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!this.attr) return;

    // Allow navigation and edition keys
    if (this.specialKeys.includes(event.key) || event.ctrlKey || event.metaKey) return;

    // Do not use event.keycode this is deprecated.
    // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
    let current: string = this.el.nativeElement.value;
    if (current && !String(current).match(this.regex)) this.el.nativeElement.value = '';

    // We need this because the current value on the DOM element
    // is not yet updated with the value from this event
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) event.preventDefault();
  }
}
