import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { IPopularRoutes } from '../../../models/destination.models';

@Component({
	selector: 'app-popular-routes',
	templateUrl: './popular-routes.component.html',
	styleUrls: ['./popular-routes.component.scss']
})
export class PopularRoutesComponent implements OnInit {
	@Input() popularRoutes: IPopularRoutes[] = [];
	@Input() destination: string;
	constructor(
		public route: Router,
		@Inject(PLATFORM_ID) private platformId: Object
	) {}

	ngOnInit(): void {}

	onRedirect(link?: string) {
		if (!!link && isPlatformBrowser(this.platformId)) {
			if (link.includes('http')) {
				window.open(link, '_blank');
			} else {
				this.route.navigate([link]);
			}
		}
	}
}
