import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Breakpoints } from '@angular/cdk/layout';
import { CookiesService } from '../../../../services/cookies';
import { StorageService } from '../../../../services/storage';
import { BreakpointService } from '../../../../services/breakpoints';
import { StorageKeysEnum } from '../../../../shared/constants';
import { CookiesConfigOptsConstant } from '../constants/cookies-config-opts.constant';
import { CookieConfig } from '../../../../shared/models';

@Component({
  selector: 'app-cookies-modal',
  templateUrl: './cookies-modal.component.html',
  styleUrls: ['./cookies-modal.component.scss'],
})
export class CookiesModalComponent implements OnInit, OnDestroy {
  private configuration: { [key: string]: boolean } = {
    all: false,
    ads: true,
    analytics: true,
    functional: true,
    technical: true,
  };
  private subscription = new Subscription();

  configurations = CookiesConfigOptsConstant;
  isMobile = false;

  constructor(
    private readonly cookiesService: CookiesService,
    private readonly storageService: StorageService,
    private readonly activeModal: NgbActiveModal,
    private readonly breakpointService: BreakpointService
  ) {}

  ngOnInit() {
    this.subscription = this.breakpointService
      .isBreakpoint(Breakpoints.Handset)
      .subscribe((value) => (this.isMobile = value));
  }

  onToggled(key: string, value: boolean) {
    this.configuration[key] = value;
  }

  onSave() {
    const cookieConfig = this.configuration as unknown as CookieConfig;
    this.storageService.setItem<CookieConfig>(StorageKeysEnum.COOKIES_CONFIG, cookieConfig);
    this.cookiesService.configCookies(cookieConfig);
    this.activeModal.dismiss();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
