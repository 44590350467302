import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConformidadComponent } from './conformidad.component';
import { CoverageModule } from 'src/app/shared/components/moleculas/coverage/coverage.module';
import { HttpClientModule } from '@angular/common/http';
import { GeneratePayService } from 'src/app/services/generatePay/generate-pay.service';
import { RouterModule } from '@angular/router';
import { CountdownModule } from 'ngx-countdown';
import { UpdatePayService } from '../../../services/updatePay/update-pay.service';
import { CardPaymentService } from '../../../services/cardPayment/card-payment.service';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [ConformidadComponent],
  imports: [CommonModule, CoverageModule, RouterModule, HttpClientModule, CountdownModule],
  exports: [ConformidadComponent],
  providers: [CardPaymentService, GeneratePayService, UpdatePayService],
})
export class ConformidadModule {}
