import { Component, OnInit } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { HOT_SALE_DATA } from '../../constants';

@Component({
	selector: 'app-hot-sale-page',
	templateUrl: './hot-sale-page.component.html',
	styleUrls: [ './hot-sale-page.component.scss' ]
})
export class HotSalePageComponent implements OnInit {
	data = HOT_SALE_DATA;

	carouselConfig: NguCarouselConfig = {
		grid: { xs: 1, sm: 1, md: 2, lg: 3, xl: 3, all: 0 },
		speed: 500,
		point: { visible: true, hideOnSingleSlide: true },
		load: 3,
		touch: true,
		easing: 'cubic-bezier(0, 0, 0.2, 1)',
		velocity: 0.2,
		animation: 'lazy'
	};

	constructor() {
	}

	ngOnInit(): void {
	}

}
