import { Component, Input } from '@angular/core';
import { ExchangeRate } from '../../../../../../api/api-checkout/models/rq-checkout-search';
import { IDynamicPackage } from '../../models/packages-response.model';

@Component({
  selector: 'app-package-card',
  templateUrl: './package-card.component.html',
  styleUrls: ['./package-card.component.scss'],
})
export class PackageCardComponent {
  @Input() item: IDynamicPackage;

  constructor() {}

  getExchangeAmount(value: ExchangeRate | null, currentAmount?: number): number | null {
    if (value === null || !currentAmount) return null;
    return value.amount * currentAmount;
  }
}
