import { Component, Input, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { PlatformService } from '../../../../../services/platform/platform.service';
import { DEVICES } from '../../../../../shared/constants';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss'],
})
export class MobileComponent implements OnInit {
  @Input() isLogged = false;

  protected isBrowser: Boolean = false;
  hideHelp = false;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly router: Router,
    private readonly platformService: PlatformService,
    public readonly activeOffCanvas: NgbActiveOffcanvas
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
      this.hideHelp = this.platformService.getDevice() === DEVICES.IOS;
    }
  }

  clickAction() {
    this.activeOffCanvas.close({ signIn: this.isLogged });
  }
}
