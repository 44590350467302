import { Component, Input, ViewEncapsulation } from '@angular/core';
import { IIataDetail } from '../destino.models';

@Component({
	selector: 'app-destination',
	templateUrl: './destination.component.html',
	styleUrls: ['./destination.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class DestinationComponent {
	@Input() destinationContent: IIataDetail;

	constructor() {}
}
