import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NguCarouselModule } from '@ngu/carousel';
import { DirectivesModule } from '../../../shared/directives/directives.module';
import { CarouselHomeComponent } from './carousel-home.component';

@NgModule({
	declarations: [CarouselHomeComponent],
	exports: [CarouselHomeComponent],
	imports: [CommonModule, NguCarouselModule, DirectivesModule]
})
export class CarouselHomeModule {}
