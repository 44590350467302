import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountsService } from 'src/app/Services/accounts.service';
import { NotificationService } from 'src/app/Services/notification.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isPlatformBrowser } from '@angular/common';
import { ChangePasswordComponent } from '../change-password/change-password.component';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: [ './forgot-password.component.scss' ]
})
export class ForgotPasswordComponent implements OnInit {
	recoverPasswordForm: FormGroup;

	private _isBrowser = false;

	constructor(
			public activeModal: NgbActiveModal,
			@Inject(PLATFORM_ID) private _platformId: Object,
			private _formBuilder: FormBuilder,
			private _accountService: AccountsService,
			private _notification: NotificationService,
			private _modal: NgbModal
	) {
		if (isPlatformBrowser(this._platformId))
			this._isBrowser = true;
	}

	ngOnInit(): void {
		this.recoverPasswordForm = this.createRecoverPasswordForm();
	}

	createRecoverPasswordForm(): FormGroup {
		return this._formBuilder.group({
			email: [ '', [ Validators.required, Validators.minLength(6), Validators.email ] ]
		});
	}

	validateRecoverPasswordForm(field: string) {
		return this.recoverPasswordForm.controls[field].errors
				&& this.recoverPasswordForm.controls[field].touched;
	}

	openLoginModal() {
		this.activeModal.close({ success: false, openLogin: true });
	}

	get recoverPasswordEmailErrorMessage(): string {
		const errors = this.recoverPasswordForm.get('email')?.errors;

		if (errors?.required)
			return 'Ingresa tu email';
		else if (errors?.minlength)
			return `Un email válido tiene ${errors?.minlength.requiredLength} caracteres como mínimo.`;
		else if (errors?.pattern)
			return 'El valor ingresado no tiene formato de email.';

		return '';
	}

	getPassword(email: string) {
		if (this.recoverPasswordForm.invalid) {
			this.recoverPasswordForm.markAllAsTouched();
			return;
		}

		this._accountService.passwordSend(email).subscribe((response) => {
			if (response && response.IsSuccess) {
				this.toggleModalGetPass(response.Message);
				this.activeModal.close({ success: true });
			} else this._notification.showNotificacion('Error', response.Message, 10);
		}, () =>
				this._notification.showNotificacion('Error', 'Error del servidor', 10));
	}

	private toggleModalGetPass(message: string) {
		if (this._isBrowser) {
			const modalRef = this._modal.open(ChangePasswordComponent, {
				backdrop: false,
				windowClass: 'ModalChangePass'
			});
			modalRef.componentInstance.message = message ||
					'Hemos enviado un correo para la activación de su cuenta, por favor verifique su bandeja.';
		}
	}
}
