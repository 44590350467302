import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuccessDialogComponent } from './success-dialog.component';

@NgModule({
	declarations: [ SuccessDialogComponent ],
	imports: [
		CommonModule
	]
})
export class SuccessDialogModule {
}
