import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'app-card-detail',
	templateUrl: './card-detail.component.html',
	styleUrls: ['./card-detail.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class CardDetailComponent implements OnInit {
	@Input() image = '';
	@Input() flightContent = '';
	constructor() {}

	ngOnInit(): void {}
}
