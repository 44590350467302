import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ChatLauncherModule,
  CookiesConfigModule,
  FooterCheckoutModule,
  FooterModule,
  ToolbarModule,
} from './ui';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CookiesConfigModule,
    ChatLauncherModule,
    FooterModule,
    FooterCheckoutModule,
    ToolbarModule,
  ],
  exports: [
    CookiesConfigModule,
    ChatLauncherModule,
    FooterModule,
    FooterCheckoutModule,
    ToolbarModule,
  ],
})
export class CoreModule {}
