import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { combineLatest, fromEvent, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { PopupService } from './Services/pop-up/popup.service';
import { environment } from '../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { GoogleSignInUtils } from './shared/utils/google-sign-in-utils';
import { AccountsService } from './Services/accounts.service';
import { NotificationService } from './Services/notification.service';
import { ConfigCatService } from './shared/services/config-cat.service';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdvertisementModalComponent } from './shared/components/modals';
import { DeviceDetectorService, OS } from 'ngx-device-detector';
import { ToolbarService } from './shared/services';
import { NMRequest } from './Models/base/NMRequest';
import { BusinessUnitService } from './Services/businessUnit/business-unit.service';
import { MainService } from './Services/presenter/main/main.service';
import { DataPagePresenterService } from './Services/presenter/data-page-presenter.service';
import Hotjar from '@hotjar/browser';

declare var google: any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: [ './app.component.scss' ]
})
export class AppComponent implements OnInit, OnDestroy {
	protected _isBrowser: Boolean = false;
	private _ipAddress: string = 'xyx';
	private unsubscribe$ = new Subject<any>();

	title = 'NMViajes';

	statusShowToolbar: boolean = false;
	advertisementContent: string = '';
	options: string[];
	isBookingRoute: boolean;
	whatsAppNumber = '51919294628';

	constructor(
			@Inject(PLATFORM_ID) private _platformId: Object,
			private _popUpSubject: PopupService,
			private _accountService: AccountsService,
			private _notification: NotificationService,
			private configCatService: ConfigCatService,
			private router: Router,
			private modal: NgbModal,
			private deviceDetector: DeviceDetectorService,
			private toolbarService: ToolbarService,
			private businessUnitService: BusinessUnitService,
			private mainService: MainService,
			public dataPagePresenterService: DataPagePresenterService
	) {
		if (isPlatformBrowser(this._platformId)) this._isBrowser = true;

		this.configCatService.initClient();
		this.onRouteChange();
	}

	ngOnInit(): void {
		if (this._isBrowser) {
			this.loadHotjar();
			this.initGoogleLogin();
			this.getBusinessUnit();
			this.getOptions();
			this.setIpCliente();
			this.closePopups();
		}

		this.configAdvertisement();
		this.getWhatsAppTesting();
	}

	private onRouteChange() {
		this.router.events.pipe(
				filter(event => event instanceof NavigationEnd),
				takeUntil(this.unsubscribe$)
		).subscribe(event => {
			const parsed = event as NavigationEnd;
			if (this.deviceDetector.isMobile() && parsed.url.includes('resultados')) {
				this.configCatService.getAppAdFlag().then(value => {
					if (value) this.openAdModal();
				});
			}

			this.isBookingRoute = parsed.url.includes('booking');
		});
	}

	private configAdvertisement() {
		this.toolbarService.statusShowAdvertisement$.pipe(takeUntil(this.unsubscribe$)).subscribe((status) => {
			this.statusShowToolbar = status;
		});

		this.toolbarService.advertisementContent$.pipe(takeUntil(this.unsubscribe$)).subscribe((content) => {
			this.advertisementContent = content;
		});
	}

	private openAdModal() {
		let redirectionUrl: string;
		if (this.deviceDetector.os === OS.IOS) redirectionUrl = 'https://apps.apple.com/pe/app/nm-viajes/id6503088852';
		else redirectionUrl = 'https://play.google.com/store/apps/details?id=com.expertiaNmviajes.app&pcampaignid=web_share';

		const modalRef = this.modal.open(AdvertisementModalComponent, {
			centered: true,
			modalDialogClass: 'rounded-lg'
		});
		modalRef.componentInstance.bannerSrc = 'assets/ads/download-app-ad.webp';
		modalRef.componentInstance.title = 'Encuentra vuelos más baratos en nuestra App';
		modalRef.componentInstance.description = 'Sigue tu búsqueda en la App y encuentra precios más bajos';
		modalRef.componentInstance.denyText = 'Ahora no';
		modalRef.componentInstance.acceptText = 'Ir a la App';

		modalRef.closed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
			if (this._isBrowser) window.open(redirectionUrl, '_blank');
		});
	}

	private initGoogleLogin() {
		google.accounts.id.initialize({
			client_id: environment.googleAccountClientId,
			callback: (response: any) => this.handleGoogleSignIn(response)
		});

		if (!this._accountService.getUserStorage())
			google.accounts.id.prompt();
	}

	private handleGoogleSignIn(response: any) {
		const decoded = GoogleSignInUtils.decodeIdToken(response);
		this.loginWithGoogle(decoded.given_name, decoded.family_name, decoded.email, decoded.sub, decoded.picture);
	}

	private loginWithGoogle(name: string, lastname: string, email: string, sub: string, photo: string) {
		const payload: any = {
			Firstname: name,
			FatherLastname: lastname,
			MotherLastname: '',
			Email: email,
			Password: '',
			IsPerson: true,
			Ruc: '',
			BusinessName: '',
			SocialNetwork: 'G',
			IdSocialNetwork: sub
		};
		this._accountService.saveAccount(payload).subscribe({
			next: (response) => {
				if (response.Result.IsSuccess)
					this._accountService.guardarStorage(response.Result, photo, 'G');
			},
			error: () => this._notification.showNotificacion('Error', 'Error del servidor', 10)
		});
	}

	private loadHotjar() {
		const siteId = 3837481;
		const hotjarVersion = 6;
		Hotjar.init(siteId, hotjarVersion, {
			debug: !environment.production
		});
	}

	private getWhatsAppTesting() {
		this.configCatService.getWhatsAppAITestingFlag().then(flag => flag ? this.whatsAppNumber = '51908930305' :
				this.resetWhatsAppNumber()).catch(() => this.resetWhatsAppNumber());
	}

	private resetWhatsAppNumber() {
		this.whatsAppNumber = '51919294628';
	}

	private closePopups() {
		if (this._isBrowser) {
			combineLatest([ fromEvent(document, 'click'), this._popUpSubject.state() ]).pipe(
					filter(resp => resp[1].open)
			).subscribe(resp => {
				if (this._isBrowser) {
					const htmlSelected = (resp[0].target as HTMLElement);
					const popUpElement = document.getElementById(resp[1].id);

					if (htmlSelected.contains(popUpElement)) {
						this._popUpSubject.closePopUp('');
					}
				}
			});
		}
	}

	private getBusinessUnit() {
		if (localStorage.getItem('businessunit') === null) {
			let payload = new NMRequest();

			this.businessUnitService.businessUnit(payload).subscribe(
					(data) => {
						let info = data['Resultado'].filter((und: any) => und.id_unidad_negocio == environment.undidadNegocioAC);
						localStorage.setItem('businessunit', info.length > 0 ? JSON.stringify(info[0]) : '');
					},
					(err) => console.log(err)
			);
		}
	}

	private getOptions() {
		this.mainService.getMenu().subscribe({
			next: (response: any) => this.options = response,
			error: (err) => console.error(err)
		});
	}

	private setIpCliente() {
		localStorage.setItem('ipCliente', this._ipAddress);
	}

	ngOnDestroy() {
		this.closePopups();
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
