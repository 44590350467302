import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { CheckoutService } from '../../services/checkout';
import { ModalUnsavedComponent } from '../components/moleculas/modal-unsaved/modal-unsaved.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class DeactivatePaymentGuard implements CanDeactivate<unknown> {

	constructor(private _checkoutService: CheckoutService, private _ngbModal: NgbModal) {
	}

	canDeactivate(): Promise<boolean> | boolean {
		if (this._checkoutService.isSaveDataPayment)
			return true;

		const modalRef = this._ngbModal.open(ModalUnsavedComponent, {
			centered: true,
			backdrop: 'static'
		});
		return modalRef.result.then((result: string) => !result || result === 'dont-save')
				.catch(() => true);
	}
}