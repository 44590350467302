export class SignatureModel{
        IdWeb: number;
        IdLang: number;
        KeyAccess: string;
        Usuario: string;
        IdSecuencia:  number;

    constructor (){
       this.IdWeb = 0;
       this.IdLang = 0;
       this.KeyAccess = '';
       this.Usuario = '';
       this.IdSecuencia = 0

    }

}