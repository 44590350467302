import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../../atoms';

@Component({
  imports: [CommonModule, ButtonComponent],
  selector: 'app-action-buttons',
  standalone: true,
  templateUrl: './action-buttons.component.html',
})
export class ActionButtonsComponent {
  @Input() orientation: 'horizontal' | 'vertical' = 'horizontal';
  @Input() gap: { [key: string]: number } = {};
  @Input() isMobile = false;

  @Input() firstButtonLabel = '';
  @Input() firstButtonStyleType: 'filled' | 'outline' | 'link' = 'filled';
  @Input() firstButtonCss: string = '';
  @Input() disableFirstButton = false;
  @Input() firstButtonIcon: string;
  @Input() loadingFirstButton = false;
  @Input() secondButtonLabel = '';
  @Input() secondButtonStyleType: 'filled' | 'outline' | 'link' = 'filled';
  @Input() secondButtonCss: string = '';
  @Input() disableSecondButton = false;
  @Input() secondButtonIcon: string;
  @Input() loadingSecondButton = false;
  @Input() thirdButtonLabel: string;
  @Input() thirdButtonStyleType: 'filled' | 'outline' | 'link' = 'filled';
  @Input() thirdButtonCss: string = '';
  @Input() disableThirdButton = false;
  @Input() thirdButtonIcon: string;
  @Input() loadingThirdButton = false;

  @Output() firstButtonClick = new EventEmitter<MouseEvent>();
  @Output() secondButtonClick = new EventEmitter<MouseEvent>();
  @Output() thirdButtonClick = new EventEmitter<MouseEvent>();

  getContainerClass(): string[] {
    const orientationClass = this.orientation === 'horizontal' ? 'flex-row' : 'flex-column';
    const gapClass = this.getGapClasses();
    return [orientationClass, ...gapClass];
  }

  private getGapClasses(): string[] {
    const classes: string[] = [];
    for (const [breakpoint, value] of Object.entries(this.gap)) {
      if (breakpoint === 'base') classes.push(`gap-${value}`);
      else classes.push(`gap-${breakpoint}-${value}`);
    }
    return classes;
  }
}
