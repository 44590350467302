import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal, NgbOffcanvas, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { interval, Subject, Subscription } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { INotificationModel } from '../../../shared/models/notification.model';
import { AccountsService, UserStorage } from '../../../services/user-account/accounts.service';
import { StorageService } from '../../../services/storage';
import { FileService } from '../../../services/files/file.service';
import { CintilloService, ToolbarService } from '../../../services/toolbar';
import { StorageKeysEnum } from '../../../shared/constants';
import {
  ForgotPasswordComponent,
  LoginComponent,
  MobileComponent,
  NewAccountComponent,
  NotificationsComponent,
} from './components';
import { Cintillo } from '../../../shared/models';
import { ClipboardUtil } from '../../../shared/utils';
import { NavState } from './enum/nav-state.enum';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  @ViewChild('dropdown') dropdownEl: ElementRef;
  @ViewChild('notifications') notificationsEl: ElementRef;

  private readonly isBrowser = isPlatformBrowser(this.platformId);
  private readonly destroy$ = new Subject<void>();
  private cintilloExpirationCheck$?: Subscription;
  private navState: NavState = NavState.DEFAULT;

  private isResultsPage = false;
  isBookingPage = false;

  showCintillo = false;
  cintilloContent?: string;

  showsNotifications = false;
  notificationsList: INotificationModel[] = [];

  isLoggedIn = false;
  userStorage: UserStorage;
  showsProfileOptions = false;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly router: Router,
    private readonly storageService: StorageService,
    private readonly accountsService: AccountsService,
    private readonly fileService: FileService,
    private readonly modalService: NgbModal,
    private readonly bottomSheet: MatBottomSheet,
    private readonly toolbarService: ToolbarService,
    private readonly offCanvasService: NgbOffcanvas,
    private readonly cintilloService: CintilloService
  ) {}

  ngOnInit(): void {
    this.handleRouteChanges();
    this.fetchCintilloContent();

    this.loginValidation();
    this.subscribeToLoginModal();
  }

  private handleRouteChanges() {
    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((event) => {
        this.isBookingPage = event.url.includes('booking');
        this.isResultsPage = event.url.includes('resultados');

        this.updateNavState();
        this.validateRouteForCintillo(event.url);
      });
  }

  private updateNavState() {
    if (this.isBookingPage) this.navState = NavState.BOOKING;
    else if (this.isResultsPage) this.navState = NavState.RESULTS;
    else this.navState = NavState.DEFAULT;
  }

  private validateRouteForCintillo(currentRoute: string) {
    const isAllowedRoute =
      ['/', '/vuelos', '/hoteles', '/vuelohotel', '/paquetes', '/seguro-viaje'].some(
        (route) => currentRoute === route
      ) || this.isResultsPage;
    this.cintilloService.updateVisibility(isAllowedRoute);
    this.showCintillo = this.cintilloService.visible;
  }

  private fetchCintilloContent() {
    this.cintilloService
      .getData()
      .pipe(take(1))
      .subscribe({
        next: (data) => this.handleCintilloData(data),
        error: () => this.cintilloService.clearBanner(),
      });
  }

  private handleCintilloData(cintillo?: Cintillo | null) {
    if (
      !cintillo?.advertisementContent ||
      !this.isValidDateRange(cintillo.startDate, cintillo.endDate)
    ) {
      this.cintilloService.clearBanner();
      this.showCintillo = this.cintilloService.visible;
      return;
    }

    this.cintilloService.updateBanner(cintillo.advertisementContent, true);
    this.cintilloContent = this.cintilloService.content;
    this.showCintillo = this.cintilloService.visible;

    this.startExpirationCheck(cintillo.endDate);
  }

  private isValidDateRange(startDate?: string, endDate?: string): boolean {
    if (!startDate || !endDate) return false;

    const start = new Date(startDate);
    const end = new Date(endDate);
    const now = new Date();

    return !isNaN(start.getTime()) && !isNaN(end.getTime()) && now >= start && now <= end;
  }

  private startExpirationCheck(expirationDate: string) {
    if (!this.isBrowser) return;

    this.cintilloExpirationCheck$?.unsubscribe();

    const expirationTime = new Date(expirationDate).getTime();

    this.cintilloExpirationCheck$ = interval(1000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (Date.now() > expirationTime) {
          this.showCintillo = false;
          this.cintilloContent = '';
          this.cintilloExpirationCheck$?.unsubscribe();
        }
      });
  }

  private subscribeToLoginModal() {
    this.toolbarService.loginModal$.pipe(takeUntil(this.destroy$)).subscribe((opens) => {
      if (opens) this.openLoginModal();
    });
  }

  clickMobileMenu() {
    const offCanvasRef = this.offCanvasService.open(MobileComponent, {
      animation: true,
      panelClass: 'bg-white',
    });
    offCanvasRef.componentInstance.isLogged = this.isLoggedIn;
    offCanvasRef.closed.pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
      if (result?.signIn) this.logout();
      else this.openLoginModal();
    });
  }

  copyPhoneNumber(tooltip: NgbTooltip) {
    const phoneNumber = '(01) 610-9200';
    ClipboardUtil.writeText(phoneNumber).then(() => {
      tooltip.open();
      setTimeout(() => tooltip.close(true), 3000);
    });
  }

  private loginValidation() {
    this.accountsService.isLogged().subscribe((logged) => {
      this.isLoggedIn = logged;
      if (this.isBrowser && this.isLoggedIn) {
        this.userStorage = this.accountsService.getUserStorage();
        if (!this.userStorage.image) this.downloadImage(this.userStorage);
        this.userStorage = this.accountsService.getUserStorage();
      }
    });
  }

  private downloadImage(user: UserStorage) {
    this.fileService.getImage(user.id).subscribe((img) => {
      if (this.isBrowser) {
        this.userStorage.image = img;
        this.accountsService.guardarImage(img);
      }
    });
  }

  @HostListener('document:click', ['$event'])
  blurRange(event: MouseEvent) {
    if (this.isBrowser) {
      if (this.dropdownEl && !this.dropdownEl.nativeElement.contains(event.target))
        this.showsProfileOptions = false;
      if (this.notificationsEl && !this.notificationsEl.nativeElement.contains(event.target))
        this.showsNotifications = false;
    }
  }

  openLoginModal() {
    if (this.isBrowser) {
      this.offCanvasService.dismiss();
      const loginModalRef = this.modalService.open(LoginComponent, {
        backdrop: 'static',
      });
      loginModalRef.closed.pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        if (this.isBrowser) {
          history.pushState('', document.title, window.location.pathname + location.search);
          if (result && !result.isLoggedIn && result.redirect)
            if (result.redirect === 'NEW_ACCOUNT') this.openNewAccountModal();
            else if (result.redirect === 'FORGOT_PASSWORD') this.openForgotPasswordModal();
        }
      });
    }
  }

  private openNewAccountModal() {
    if (this.isBrowser) {
      const newAccountModalRef = this.modalService.open(NewAccountComponent, {
        backdrop: 'static',
        windowClass: 'new-account-modal',
      });
      newAccountModalRef.closed.pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        if (result?.openLogin) this.openLoginModal();
      });
    }
  }

  openForgotPasswordModal() {
    if (this.isBrowser) {
      const forgotPasswordModalRef = this.modalService.open(ForgotPasswordComponent, {
        windowClass: 'forgot-password-modal',
        centered: true,
      });
      forgotPasswordModalRef.closed.pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        if (result?.openLogin) this.openLoginModal();
      });
    }
  }

  openNotificationsMobile() {
    if (this.isBrowser) {
      const bottomSheetRef = this.bottomSheet.open(NotificationsComponent, {
        data: { notificationList: this.notificationsList },
      });

      bottomSheetRef
        .afterDismissed()
        .pipe(takeUntil(this.destroy$))
        .subscribe((reason: { notification: INotificationModel }) => {
          if (!reason?.notification) return;
          this.redirectFromNotification(
            reason.notification.url,
            this.notificationsList.findIndex((notification) => notification === reason.notification)
          );
        });
    }
  }

  redirectFromNotification(url: string, index: number) {
    this.removeNotificationByIndex(index);
    this.router.navigateByUrl(url).then(() => null);
  }

  private removeNotificationByIndex(index: number) {
    this.notificationsList.splice(index, 1);
    this.storageService.setItem<string>(
      StorageKeysEnum.NOTIFICATIONS,
      JSON.stringify(this.notificationsList)
    );
  }

  logout() {
    this.accountsService.signOut();
    this.showsProfileOptions = false;
    this.offCanvasService.dismiss();
    this.router.navigateByUrl('/').then(() => null);
  }

  get navClasses() {
    return {
      booking: this.navState === NavState.BOOKING,
      'd-block pt-0': this.showCintillo && this.cintilloContent,
    };
  }

  get containerClasses() {
    return { 'py-1': this.navState === NavState.BOOKING };
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.cintilloExpirationCheck$?.unsubscribe();
  }
}
