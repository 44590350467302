import { Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { DynamicPackagesService } from '../../../services';
import { IDynamicPackage } from '../../../models';
import { isPlatformBrowser } from '@angular/common';
import { finalize, take } from 'rxjs/operators';
import { ExchangeRate } from '../../../../../api/api-checkout/models/rq-checkout-search';
import { TokenService } from '../../../../../api/api-nmviajes/services/token.service';
import { Subscription } from 'rxjs';
import { ConfigCatService } from '../../../../../shared/services/config-cat.service';

@Component({
	selector: 'app-section-packages',
	templateUrl: './section-packages.component.html',
	styleUrls: [ './../../../styles/sections.scss' ]
})
export class SectionPackagesComponent implements OnInit, OnDestroy {
	protected _isBrowser = false;

	private _exchangeRate: ExchangeRate | null = null;
	private _exchangeSubscription = new Subscription();

	private _flagSubscription = new Subscription();

	private itemsPerPage = 3;
	private totalItems = 6;
	private isMobileView = false;
	private incrementCount = 3;

	isLoading = false;
	placeholderItems: number[];
	limitReached = false;

	packageList: IDynamicPackage[] = [];
	displayPackageList: IDynamicPackage[] = [];


	constructor(
			@Inject(PLATFORM_ID) private _platformId: Object,
			private _dynamicPackagesService: DynamicPackagesService,
			private _tokenService: TokenService,
			private _configCatService: ConfigCatService
	) {
		if (isPlatformBrowser(this._platformId)) this._isBrowser = true;
	}

	ngOnInit(): void {
		this.checkScreenSize();
		this.getExchangeRate();
		this.listLimitSubscription();
	}

	@HostListener('window:resize', [ '$event' ])
	onResize(_: any) {
		this.checkScreenSize();
		this.updateItemsToShow();
	}

	private checkScreenSize() {
		if (this._isBrowser) {
			this.isMobileView = window.innerWidth < 768;
			this.itemsPerPage = this.isMobileView ? 1 : 3;
			this.placeholderItems = Array(this.itemsPerPage).fill(0).map((x, i) => i);
		}
	}

	private listLimitSubscription() {
		this._flagSubscription = this._configCatService.cardListLimitFlag$.subscribe(result => {
			this.totalItems = result ? 9 : 6;
			this.getDynamicPackages();
		});
	}

	private updateItemsToShow() {
		this.displayPackageList = this.packageList.map(item => this.addExchangeRateToPackage(item))
				.slice(0, this.itemsPerPage);
		this.limitReached = false;
	}

	private getExchangeRate() {
		this._exchangeSubscription = this._tokenService.exchangeRate$.subscribe(result => {
			this._exchangeRate = result;
			this.displayPackageList.forEach(item => item.TotalPrice.CurrencyExchange = result);
		});
	}

	private getDynamicPackages() {
		this.isLoading = true;
		this._dynamicPackagesService.getPackages(0, this.totalItems).pipe(
				take(1),
				finalize(() => {
					if (this._isBrowser) setTimeout(() => this.isLoading = false, 800);
				})
		).subscribe({
			next: (response) => {
				if (response && response.Result) {
					this.packageList = response.Result.package.map(item => this.addExchangeRateToPackage(item));
					this.updateItemsToShow();
				}
			},
			error: (err) => console.error('Error retrieving packages:', err)
		});
	}

	updateItemsDisplayed() {
		if (!this.limitReached) this.showMore();
		else this.showLess();
	}

	private showMore() {
		const newCount = this.displayPackageList.length === 1 ? 3 : this.displayPackageList.length + this.incrementCount;

		if (newCount >= this.totalItems) {
			this.displayPackageList = this.packageList.map(item => this.addExchangeRateToPackage(item))
					.slice(0, this.totalItems);
			this.limitReached = true;
		} else this.displayPackageList = this.packageList.map(item => this.addExchangeRateToPackage(item))
				.slice(0, newCount);
	}

	private showLess() {
		this.updateItemsToShow();
		this.scrollReset();
	}

	private addExchangeRateToPackage(item: IDynamicPackage) {
		const result = item;
		result.TotalPrice = {
			...result.TotalPrice,
			CurrencyExchange: this._exchangeRate
		};
		return result;
	}

	private scrollReset() {
		if (this._isBrowser)
			document.querySelector('#packagesSection')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}

	ngOnDestroy() {
		this._exchangeSubscription.unsubscribe();
		this._flagSubscription.unsubscribe();
	}
}
